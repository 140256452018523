import Vue from "vue";
import VueRouter from "vue-router";
import {
	delAllStorage,
	judgeServerHostname,
} from "@/utils/common";

Vue.use(VueRouter);

const routes = [
	// 初次修改密码
	{
		path: "/web/operate/change",
		name: "operate/change",
		component: () => import("../views/login/Change.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// 忘记密码
	{
		path: "/web/ForgetPassword",
		name: "operate/ForgetPassword",
		component: () => import("../views/login/ForgetPassword.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// 注册账号
	{
		path: "/web/login/registeredAccount",
		name: "web_login_registeredAccount",
		component: () => import("../views/login/registeredAccount.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	//-郑州查看视频目录H5
	{
		path: "/web/zhengzhouH5/videoMenu",
		name: "zhengzhouH5/videoMenu",
		component: () => import("../views/zhengzhouH5/videoMeun/videoMenu.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	//答题详情-郑州查看H5页面
	{
		path: "/web/zhengzhouH5/ExaminationDetails",
		name: "zhengzhouH5/ExaminationDetails",
		component: () => import("../views/zhengzhouH5/Examinationinquiry/ExaminationDetails.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// // 查看课程详情-郑州查看H5页面
	{
		path: "/web/zhengzhouH5/detailResource",
		name: "zhengzhouH5/detailResource",
		component: () => import("../views/zhengzhouH5/DetailResource/detailResource.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// 日志
	{
		path: "/web/zhengzhouH5/logs",
		name: "zhengzhouH5/logs",
		component: () => import("../views/zhengzhouH5/logs/logs.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	{
		path: "/web/zhengzhouH5/userFaceLogs",
		name: "zhengzhouH5/userFaceLogs",
		component: () => import("../views/zhengzhouH5/logs/userFaceLogs.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	{
		path: "/web/zhengzhouH5/logs2",
		name: "zhengzhouH5/logs2",
		component: () => import("../views/zhengzhouH5/logs/logs2.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// 扫码学习记录h5
	{
		path: "/web/h5/learningRecord",
		name: "h5/learningRecord",
		component: () => import("../views/h5/learningRecord.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	{
		path: "/web/login",
		name: "login",
		component: () => import("../views/login/Login.vue"),
		meta: {
			state: false,
			level: 1,
		},
	},
	// 可视化编辑
	{
		path: "/visualization",
		name: "visualization",
		component: () => import("../views/visualization/one"),
		meta: {},
	},
	{
		path: "/web/OnePersonOneCodeTable",
		name: "OnePersonOneCodeTable",
		component: () => import("../views/OnePersonOneCode/OnePersonOneCodeTable.vue"),
		meta: {},
	},

	{
		path: "/",
		name: 'views_Menu',
		component: () => import("../views/Menu.vue"),
		redirect: {
			path: "/web/login"
		},
		children: [
			// 协议审批管理页面
			{
				path: "/web/agreementApproval",
				name: "agreementApproval",
				component: () => import("../views/agreementApproval/agreementApproval.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 协议新增或添加
			{
				path: "/web/agreementAdd",
				name: "agreementAdd",
				component: () => import("../views/agreementApproval/agreementAdd.vue"),
				meta: {
					level: 2,
				}
			},
			// 协议财务复核列表
			{
				path: "/web/agreementReview",
				name: "agreementReview",
				component: () => import("../views/agreementApproval/agreementReview.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 协议签章归档列表
			{
				path: "/web/agreementSignature",
				name: "agreementSignature",
				component: () => import("../views/agreementApproval/agreementSignature.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 协议文件下载
			{
				path: "/web/agreementToDownload",
				name: "agreementToDownload",
				component: () => import("../views/agreementApproval/agreementToDownload.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// SupervisionEnd
			// 监管端首页
			// 监管首页
			{
				path: "/web/SupervisionEnd/home",
				name: "SupervisionEnd/home",
				component: () => import("../views/SupervisionEnd/Home/home.vue"),
				meta: {
					state: false,
					// level: 1
				},
			},
			// 监管第二種首页
			{
				path: "/web/SupervisionEnd/homeJg",
				name: "SupervisionEnd/homeJg",
				component: () => import("../views/SupervisionEnd/Home/homeJg.vue"),
				meta: {
					state: false
					// level: 1
				}
			},
			// 监管班级审批
			{
				path: "/web/SupervisionEnd/classCheckList",
				name: "SupervisionEnd/classCheckList",
				component: () =>
					import("../views/SupervisionEnd/trainingMange/classCheckList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 监管班级审批-详情
			{
				path: "/web/SupervisionEnd/classCheckDetail",
				name: "SupervisionEnd/classCheckDetail",
				component: () =>
					import("../views/SupervisionEnd/trainingMange/classCheckDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 监管班级审批-详情
			{
				path: "/web/SupervisionEnd/classJgCourse",
				name: "SupervisionEnd/classJgCourse",
				component: () =>
					import("../views/SupervisionEnd/trainingMange/classJgCourse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 监管班级审批-参数修改
			{
				path: "/web/SupervisionEnd/classparametersSJZ",
				name: "SupervisionEnd/classparametersSJZ",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/classparametersSJZ.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 监管班级课程参数
			{
				path: "/web/SupervisionEnd/classSubjectParam",
				name: "SupervisionEnd/classSubjectParam",
				component: () =>
					import("../views/SupervisionEnd/trainingMange/classSubjectParam.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 新增班级课程参数
			{
				path: "/web/SupervisionEnd/addClassSubject",
				name: "SupervisionEnd/addClassSubject",
				component: () =>
					import("../views/operate/systemSet/addClassSubject.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 监管班级课程参数-查看
			{
				path: "/web/SupervisionEnd/classSubjectParamDetail",
				name: "SupervisionEnd/classSubjectParamDetail",
				component: () =>
					import(
						"../views/SupervisionEnd/trainingMange/classSubjectParamDetail.vue"
					),
			},
			// 培训管理 - 学员认证信息
			{
				path: "/web/SupervisionEnd/studentCertificationInformation",
				name: "SupervisionEnd/studentCertificationInformation",
				component: () =>
					import(
						"../views/SupervisionEnd/trainingMange/studentCertificationInformation.vue"
					),
			},
			// 模拟考试题库
			{
				path: "/web/operate/Testquestionbank",
				name: "operate/Testquestionbank",
				component: () =>
					import("../views/operate/TestQuestionBank/Testquestionbank.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 账号管理
			//注销
			{
				path: "/web/operate/accountLogout",
				name: "operate/accountLogout",
				component: () => import("../views/accountManagement/accountLogout.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 账号列表
			{
				path: "/web/operate/accountList",
				name: "operate/accountList",
				component: () => import("../views/accountManagement/accountList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 账号列表
			{
				path: "/web/operate/compAgencyAccountList",
				name: "operate/compAgencyAccountList",
				component: () => import("../views/accountManagement/compAgencyAccountList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 账号列表（人力）
			{
				path: "/web/operate/accountListHR",
				name: "operate/accountListHR",
				component: () => import("../views/accountManagement/accountListHR.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 账号编辑
			{
				path: "/web/operate/compAgencyBindList",
				name: "operate/compAgencyBindList",
				component: () => import("../views/accountManagement/compAgencyBindList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 账号编辑
			{
				path: "/web/operate/accountEdit",
				name: "accountManagement/accountEdit",
				component: () => import("../views/accountManagement/accountEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 账号编辑(人力)
			{
				path: "/web/operate/accountEditHR",
				name: "accountManagement_accountEditHR",
				component: () => import("../views/accountManagement/accountEditHR.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 角色列表
			{
				path: "/web/operate/roleList",
				name: "operate/roleList",
				component: () => import("../views/accountManagement/roleList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 创建/编辑角色
			{
				path: "/web/operate/createRoleType",
				name: "operate/createRoleType",
				component: () =>
					import("../views/accountManagement/createRoleType.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 权限管理
			{
				path: "/web/operate/rightsManagement",
				name: "operate/rightsManagement",
				component: () =>
					import("../views/accountManagement/rightsManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//
			// 课程详情
			{
				path: "/web/coursePages",
				name: "coursePages",
				component: () => import("../views/commenPages/CourseDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 课程审核
			{
				path: "/web/CourseReview",
				name: "CourseReview",
				component: () => import("../views/AuditManagement/CourseReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 课程审核-详情
			{
				path: "/web/CourseReviewDetails",
				name: "CourseReviewDetails",
				component: () =>
					import("../views/AuditManagement/CourseReviewDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 课件审核
			{
				path: "/web/CoursewareReview",
				name: "CoursewareReview",
				component: () =>
					import("../views/AuditManagement/CoursewareReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 课件审核-审核
			{
				path: "/web/CoursewareReviewDetails",
				name: "CoursewareReviewDetails",
				component: () =>
					import("../views/AuditManagement/CoursewareReviewDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 班级审核
			{
				path: "/web/ClassReview",
				name: "ClassReview",
				component: () => import("../views/AuditManagement/ClassReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 班级审核-详情
			{
				path: "/web/AuditManagement/studentDetail",
				name: "studentDetail",
				component: () => import("../views/AuditManagement/studentDetail.vue"),
				meta: {
					state: false,
				},
			},
			// 班级审核-详情 - 学员列表
			{
				path: "/web/ClassReviewDetails",
				name: "ClassReviewDetails",
				component: () =>
					import("../views/AuditManagement/ClassReviewDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 班级延期审核
			{
				path: "/web/ClassDelayReview",
				name: "ClassDelayReview",
				component: () => import("../views/AuditManagement/ClassDelayReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			/**
			 * 人员管理
			 */
			// 讲师列表
			{
				path: "/web/lecturer/list",
				name: "lecturerList",
				component: () =>
					import("../views/PersonnelManagement/lecturerList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 讲师编辑
			{
				path: "/web/addLecturer",
				name: "addLecturer",
				component: () => import("../views/PersonnelManagement/addLecturer.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 讲师列表（运营）
			{
				path: "/web/lecturerYw/list",
				name: "lecturerListYw",
				component: () =>
					import("../views/PersonnelManagement/lecturerYwList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/lecturerYwEdit",
				name: "lecturerYwEdit",
				component: () =>
					import("../views/PersonnelManagement/lecturerYwEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//日报人员列表
			{
				path: "/web/classDaily",
				name: "classDaily",
				component: () => import("../views/PersonnelManagement/classDaily.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 职工列表
			{
				path: "/web/workersList",
				name: "workersList",
				component: () => import("../views/PersonnelManagement/workersList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 职工编辑
			{
				path: "/web/addworkers",
				name: "addworkers",
				component: () => import("../views/PersonnelManagement/addworkers.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 职工编辑-学习记录
			{
				path: "/web/studyNotes",
				name: "studyNotes",
				component: () => import("../views/PersonnelManagement/studyNotes.vue"),
			},
			// 职工编辑-学习记录-详情
			{
				path: "/web/NotesDetail",
				name: "NotesDetail",
				component: () => import("../views/PersonnelManagement/NotesDetail.vue"),
			},
			// 学员列表
			{
				path: "/web/studentsList",
				name: "studentsList",
				component: () =>
					import("../views/PersonnelManagement/studentsList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 学员列表审核
			{
				path: "/web/studentsListAudit",
				name: "studentsListAudit",
				component: () =>
					import("../views/PersonnelManagement/studentsListAudit.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 学员列表审核
			{
				path: "/web/auditStudent",
				name: "auditStudent",
				component: () =>
					import("../views/PersonnelManagement/auditStudent.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 学员列表-编辑
			{
				path: "/web/editStudent",
				name: "editStudent",
				component: () => import("../views/PersonnelManagement/editStudent.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//市场人员
			{
				path: "/web/marketUser",
				name: "marketUserList",
				component: () =>
					import("../views/PersonnelManagement/marketUserList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 销售员列表
			{
				path: "/web/salespersonList",
				name: "salespersonList",
				component: () =>
					import("../views/PersonnelManagement/salespersonList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//山西学员列表
			{
				path: "/web/shanxiStudentList",
				name: "shanxiStudengList",
				component: () =>
					import("../views/PersonnelManagement/shanxiStudentList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			/**
			 * 我的资源
			 */
			// 试卷列表
			{
				path: "/web/testPaperList",
				name: "testPaperList",
				component: () => import("../views/resourse/testPaperList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 试卷编辑  （公用）
			{
				path: "/web/testPaperEdit",
				name: "resourse_testPaperEdit",
				component: () => import("../views/resourse/testPaperEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			/**
			 * 平台资源
			 */
			// 课程使用设置
			{
				path: "/web/courseUsageSettings",
				name: "courseUsageSettings",
				component: () => import("../views/platform/courseUsageSettings.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 平台课程/课件
			{
				path: "/web/courseList",
				name: "platFormCourseList",
				component: () => import("../views/platform/CouresList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 平台课程-小程序
			{
				path: "/web/miniApp/platform/platFormCourseList",
				name: "miniApp/platform/platFormCourseList",
				component: () => import("../views/miniApp/platform/CouresList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//
			// pages
			// 小程序-平台课程-课程详情
			{
				path: "/web/miniApp/platform/courseDetail",
				name: "miniApp/platform/courseDetail",
				component: () => import("../views/miniApp/platform/CourseDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 平台试卷/试题
			{
				path: "/web/paperList",
				name: "platFormPaperList",
				component: () => import("../views/platform/PaperList"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// (新增/编辑)试卷
			{
				path: "/web/addTestPaper",
				name: "resourse_testPaperCom_addTestPaper",
				component: () =>
					import("../views/resourse/testPaperCom/addTestPaper.vue"),
			},
			// 批量导入试卷
			{
				path: "/web/bulkImportPaper",
				name: "resourse_testPaperCom_bulkImportPaper",
				component: () =>
					import("../views/resourse/testPaperCom/bulkImportPaper.vue"),
			},
			// 结算列表
			{
				path: "/web/evaluate/list",
				name: "evaluateList",
				component: () => import("../views/evaluate/List.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 结算列表（财务）
			{
				path: "/web/evaluate/FinanceList",
				name: "FinanceList",
				component: () => import("../views/evaluate/FinanceList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 结算列表（财务） 编辑
			{
				path: "/web/evaluate/FinaceDetails",
				name: "FinaceDetails",
				component: () => import("../views/evaluate/FinaceDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 订单列表（财务）
			{
				path: "/web/SettlementOrderList",
				name: "SettlementOrderList",
				component: () => import("../views/evaluate/SettlementOrderList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 结算列表-课程详情
			{
				path: "/web/evaluate/evaluateDetails",
				name: "evaluate/evaluateDetails",
				component: () => import("../views/evaluate/evaluateDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 课程结算
			{
				path: "/web/evaluate/CoursesettlementList",
				name: "evaluate/CoursesettlementList",
				component: () =>
					import("../views/evaluate/Coursesettlement/CoursesettlementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//课程结算-使用详情
			{
				path: "/web/evaluate/CoursesettlementDatails",
				name: "evaluate/CoursesettlementDatails",
				component: () =>
					import(
						"../views/evaluate/Coursesettlement/CoursesettlementDatails.vue"
					),
				meta: {
					state: false,
					level: 2,
				},
			},
			/**
			 * 结算管理
			 */
			// 结算管理 - 补贴设置
			{
				path: "/web/evaluate/SubsidyStandardSetting",
				name: "evaluate/SubsidyStandardSetting",
				component: () =>
					import(
						"../views/evaluate/SubsidyStandardSetting/SubsidyStandardSetting.vue"
					),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 合同管理
			{
				path: "/web/evaluate/contractManagement",
				name: "evaluate/contractManagement",
				component: () =>
					import("../views/evaluate/contractManagement/contractManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},

			// 结算管理 - 结算单管理
			{
				path: "/web/evaluate/statementList",
				name: "evaluate/statementList",
				component: () =>
					import("../views/evaluate/statementManagement/statementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 待结算班级
			{
				path: "/web/evaluate/statementListIng",
				name: "evaluate/statementListIng",
				component: () =>
					import("../views/evaluate/statementManagement/statementListIng.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 待结算班级 -生成结算单
			{
				path: "/web/evaluate/generateStatementAccount",
				name: "evaluate/generateStatementAccount",
				component: () =>
					import(
						"../views/evaluate/statementManagement/generateStatementAccount.vue"
					),
				meta: {
					state: false,
					level: 3,
				},
			},

			// 结算单管理运维
			{
				path: "/web/evaluate/statementManagementY",
				name: "evaluate/statementManagementY",
				component: () =>
					import(
						"../views/evaluate/statementManagement/statementManagementY.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 结算管理 - 结算单管理财务
			{
				path: "/web/evaluate/statementManagementFinance",
				name: "evaluate/statementManagementFinance",
				component: () =>
					import(
						"../views/evaluate/statementManagement/statementManagementFinance.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 结算管理 - 结算单管理领导
			// 结算单管理财务-复核
			{
				path: "/web/evaluate/statementManagementFinanceToReview",
				name: "evaluate/statementManagementFinanceToReview",
				component: () =>
					import(
						"../views/evaluate/statementManagement/statementManagementFinanceToReview.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			//结算单管理领导
			{
				path: "/web/evaluate/statementManagementLeader",
				name: "evaluate/statementManagementLeader",
				component: () =>
					import(
						"../views/evaluate/statementManagement/statementManagementLeader.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端
			// 结算确认单
			{
				path: "/web/SettlementmanagementList",
				name: "SettlementmanagementList",
				component: () =>
					import(
						"../views/Settlementmanagement_jg/SettlementmanagementList.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 结算单管理
			{
				path: "/web/settlementManagement",
				name: "settlementManagement",
				component: () => import("../views/financialSettlement/settlementManagement/SettlementManagement.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 结算管理 - 结算单管理（机构代理商）
			{
				path: "/web/settlementManagementAgency",
				name: "settlementManagementAgency",
				component: () =>
					import("../views/financialSettlement/settlementManagement/settlementManagementAgency.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			/**
			 * 我的资源
			 */
			// 课程列表（联安众）
			{
				path: "/web/resourse/courseListLiananzhong",
				name: "courseListLiananzhong",
				component: () => import("../views/resourse/courseListLiananzhong.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 收藏课程/课件
			{
				path: "/web/collectresourseList",
				name: "collectresourseList",
				component: () => import("../views/resourse/collectresourseList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 创建收藏课程
			{
				path: "/web/collectresourseList/Createcourse",
				name: "collectresourseListCreatecourse",
				component: () => import("../views/resourse/collectresourseList/Createcourse.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 我的课程/课件
			{
				path: "/web/myresourseList",
				name: "myresourseList",
				component: () => import("../views/resourse/myresourseList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 我的课程-上传课件
			{
				path: "/web/AddCourseware",
				name: "AddCourseware",
				component: () => import("../views/resourse/AddCourseware.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			{
				path: "/web/elasticList",
				name: "elasticList",
				component: () => import("../views/resourse/elasticList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 我的课程-添加已有课件
			{
				path: "/web/AddExistingCourseware",
				name: "AddExistingCourseware",
				component: () => import("../views/resourse/AddExistingCourseware.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 我的课程-上传外部课件
			{
				path: "/web/AddExternalCourseware",
				name: "AddExternalCourseware",
				component: () => import("../views/resourse/AddExternalCourseware.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 创建课程
			{
				path: "/web/Createcourse",
				name: "resourse_Createcourse",
				component: () => import("../views/resourse/Createcourse.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 查看课程详情
			{
				path: "/web/detailResource",
				name: "detailResource",
				component: () => import("../views/resourse/detailResource.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 添加上传课件
			{
				path: "/web/viewKpoint",
				name: "viewKpoint",
				component: () => import("../views/resourse/viewCourseware.vue")
			},
			// 班级列表
			{
				path: "/web/classListCtr",
				name: "classListCtr",
				component: () => import("../views/classListCom/classListCtr.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 培训管理 - 班级显示设置
			{
				path: "/web/classDisplaySetList",
				name: "classDisplaySetList",
				component: () => import("../views/classListCom/classDisplaySetList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 培训管理 - 班级显示设置 - 查看日志
			{
				path: "/web/classDisplaySetLogs",
				name: "classDisplaySetLogs",
				component: () => import("../views/classListCom/classDisplaySetLogs.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 培训管理 - 班级结束提醒
			{
				path: "/web/classListCom/classEndReminder",
				name: "classListCom_classEndReminder",
				component: () => import("../views/classListCom/classEndReminder.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级管理
			{
				path: "/web/classListManage",
				name: "classListManage",
				component: () => import("../views/classListCom/classListManage.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级管理-添加培训
			{
				path: "/web/addClassManage",
				name: "addClassManage",
				component: () => import("../views/classListCom/addClassManage.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-详情、课程列表、学员管理
			{
				path: "/web/classdetailsManage",
				name: "classdetailsManage",
				component: () => import("../views/classListCom/classdetailsManage.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级列表
			{
				path: "/web/classListCtrY",
				name: "classListCtrY",
				component: () => import("../views/classListCom/classListCtrY.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级列表 --班级抽查维护
			{
				path: "/web/checkMaintenance",
				name: "checkMaintenance",
				component: () =>
					import("../views/classListCom/checkMaintenance.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级列表 --班级参数
			{
				path: "/web/classListClassparameterY",
				name: "classListClassparameterY",
				component: () =>
					import("../views/classListCom/classListClassparameterY.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级详情
			{
				path: "/web/classDetailY",
				name: "classDetailY",
				component: () => import("../views/classListCom/classDetailY.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级详情-河北
			{
				path: "/web/classDetailHB",
				name: "classDetailHB",
				component: () => import("../views/classListCom/classDetailHB.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-详情
			{
				path: "/web/ClassdetailsList",
				name: "ClassdetailsList",
				component: () => import("../views/classListCom/ClassdetailsList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 培训管理-学习轨迹清除
			{
				path: "/web/classListCom/clearLearningTrajectory",
				name: "classListCom_clearLearningTrajectory",
				component: () => import("../views/classListCom/clearLearningTrajectory.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 班级管理-详情-导入报名学员
			{
				path: "/web/classListCom/classDetailsFile/importRegisteredStudents",
				name: "classListCom_classDetailsFile_importRegisteredStudents",
				component: () => import("../views/classListCom/classDetailsFile/importRegisteredStudents.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-布置作业
			{
				path: "/web/assignHomework",
				name: "assignHomework",
				component: () => import("../views/classListCom/classDetailsFile/assignHomework.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-布置作业 -查看作业
			{
				path: "/web/assignHomeworkLook",
				name: "assignHomeworkLook",
				component: () => import("../views/classListCom/classDetailsFile/assignHomeworkLook.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-课程列表-上课学员
			{
				path: "/web/StudentsInClass",
				name: "StudentsInClass",
				component: () =>
					import("../views/classListCom/classDetailsFile/StudentsInClass.vue")
			},
			// 班级管理-详情
			{
				path: "/web/addClassCtr",
				name: "addClassCtr",
				component: () => import("../views/classListCom/addClassCtr.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 班级管理-详情
			{
				path: "/web/addCourseBox",
				name: "addCourseBox",
				component: () =>
					import("../views/classListCom/classDetailsFile/addCourseBox.vue")
			},
			// 班级管理-详情
			{
				path: "/web/addCourseBoxManage",
				name: "addCourseBoxManage",
				component: () =>
					import("../views/classListCom/classDetailsFile/addCourseBoxManage.vue")
			},
			// 班级管理-详情
			{
				path: "/web/seeClassMinsCtr",
				name: "seeClassMinsCtr",
				component: () =>
					import("../views/classListCom/classDetailsFile/seeClassMinsCtr.vue")
			},
			// 班级管理-详情-（海南）
			{
				path: "/web/seeClassMinsCtrHainan",
				name: "seeClassMinsCtrHainan",
				component: () =>
					import("../views/classListCom/classDetailsFile/seeClassMinsCtrHainan.vue")
			},
			// 班级管理-详情
			{
				path: "/web/seeClassMinsManage",
				name: "seeClassMinsManage",
				component: () =>
					import("../views/classListCom/classDetailsFile/seeClassMinsManage.vue")
			},
			// 课程详情/机构点名（石家庄）
			{
				path: "/web/classdetailtabs",
				name: "classdetailtabs",
				component: () =>
					import("../views/classListCom/classDetailsFile/classdetailtabs.vue")
			},
			// 开班统计
			{
				path: "/web/set/Openingstatistics",
				name: "set/Openingstatistics",
				component: () => import("../views/set/Openingstatistics.vue")
			},
			// 开班统计监管
			{
				path: "/web/set/OpeningstatisticsJg",
				name: "set/OpeningstatisticsJg",
				component: () => import("../views/set/OpeningstatisticsJg.vue")
			},

			// 学习情况统计
			{
				path: "/web/set/studySituation",
				name: "set/studySituation",
				component: () => import("../views/set/studySituation.vue")
			},
			// 班级档案查询
			{
				path: "/web/set/classFile",
				name: "set/classFile",
				component: () => import("../views/set/classFile.vue")
			},
			// 班级档案查询
			{
				path: "/web/set/classFile2023",
				name: "set/classFile2023",
				component: () => import("../views/set/classFile2023.vue")
			},
			// 发布课程使用
			{
				path: "/web/set/courseUser",
				name: "set/courseUser",
				component: () => import("../views/set/courseUser.vue")
			},
			// 课程结算统计
			{
				path: "/web/set/courseSettlement",
				name: "set/courseSettlement",
				component: () => import("../views/set/courseSettlement.vue")
			},
			// 发布课件使用
			{
				path: "/web/set/coursewareUser",
				name: "set/coursewareUser",
				component: () => import("../views/set/coursewareUser.vue")
			},
			// 发布课程使用详情
			{
				path: "/web/set/courseUserDetail",
				name: "set/courseUserDetail",
				component: () => import("../views/set/courseUserDetail.vue")
			},
			// 机构-学员统计
			{
				path: "/web/set/Studentstatistics",
				name: "set/StudentStatistics",
				component: () => import("../views/set/Studentstatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构-学员统计（轨迹合并）
			{
				path: "/web/set/StudentstatisticsTrackMerging",
				name: "set/StudentStatisticsTrackMerging",
				component: () => import("../views/set/StudentstatisticsTrackMerging.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计 -学习轨迹
			{
				path: "/web/set/TrajectoryList",
				name: "set/TrajectoryList",
				component: () => import("../views/set/TrajectoryList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学员统计 -学习轨迹(上海-青浦)
			{
				path: "/web/set/TrajectoryList_shqp",
				name: "set_TrajectoryList_shqp",
				component: () => import("../views/set/TrajectoryList_shqp.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 机构 - 学员统计 -学习轨迹（轨迹合并）
			{
				path: "/web/set/TrajectoryListTrackMerging",
				name: "set/TrajectoryListTrackMerging",
				component: () => import("../views/set/TrajectoryListTrackMerging.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学员统计 - 学员信息统计（无下级）
			{
				path: "/web/set/StudentstatisticsNoSubordinate",
				name: "set/StudentstatisticsNoSubordinate",
				component: () => import("../views/set/StudentstatisticsNoSubordinate.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（无下级）(上海-青浦用)
			{
				path: "/web/set/StudentstatisticsNoSubordinate_shqp",
				name: "set_StudentstatisticsNoSubordinate_shqp",
				component: () => import("../views/set/StudentstatisticsNoSubordinate_shqp.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（简化）
			{
				path: "/web/set/StudentstatisticsLess",
				name: "set/StudentstatisticsLess",
				component: () => import("../views/set/StudentstatisticsLess.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计监管 - 学员信息统计（简化）
			{
				path: "/web/set/StudentstatisticsLessJg",
				name: "set/StudentstatisticsLessJg",
				component: () => import("../views/set/StudentstatisticsLessJg.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（无下级）（学习轨迹（轨迹合并））
			{
				path: "/web/set/StudentstatisticsNoSubordinateTrackMerging",
				name: "set/StudentstatisticsNoSubordinateTrackMerging",
				component: () => import("../views/set/StudentstatisticsNoSubordinateTrackMerging.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（无下级）（学习轨迹（轨迹合并））
			{
				path: "/web/set/StudentstatisticsNoSubordinateTrackMergingList",
				name: "set/StudentstatisticsNoSubordinateTrackMergingList",
				component: () => import("../views/set/StudentstatisticsNoSubordinateTrackMergingList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学员统计 - 学员信息统计（下级单位）
			{
				path: "/web/set/StudentstatisticsSubordinateUnits",
				name: "set/StudentstatisticsSubordinateUnits",
				component: () => import("../views/set/StudentstatisticsSubordinateUnits.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（下级单位）（学习轨迹（轨迹合并））
			{
				path: "/web/set/StudentstatisticsSubordinateUnitsTrackMerging",
				name: "set/StudentstatisticsSubordinateUnitsTrackMerging",
				component: () => import("../views/set/StudentstatisticsSubordinateUnitsTrackMerging.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员统计 - 学员信息统计（下级单位）（学习轨迹（轨迹合并））
			{
				path: "/web/set/StudentstatisticsSubordinateUnitsTrackMergingList",
				name: "set/StudentstatisticsSubordinateUnitsTrackMergingList",
				component: () => import("../views/set/StudentstatisticsSubordinateUnitsTrackMergingList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学时证明统计
			{
				path: "/web/set/creditHoursStatistics",
				name: "set/creditHoursStatistics",
				component: () => import("../views/set/creditHoursStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员档案统计
			{
				path: "/web/set/StudentFileStatistics",
				name: "set/StudentFileStatistics",
				component: () => import("../views/set/StudentFileStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 单位统计
			{
				path: "/web/set/unitStatistics",
				name: "set/unitStatistics",
				component: () => import("../views/set/unitStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 单位统计-查看
			{
				path: "/web/set/UnitDetails",
				name: "set/UnitDetails",
				component: () => import("../views/set/UnitDetails.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 问卷统计
			{
				path: "/web/set/questionnaireStatistics",
				name: "set/questionnaireStatistics",
				component: () => import("../views/set/questionnaireStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 问卷统计 --每日问卷汇总
			{
				path: "/web/set/questionnaireDailysummary",
				name: "set/questionnaireDailysummary",
				component: () => import("../views/set/questionnaireDailysummary.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 问卷统计 --每日问卷汇总 --详情
			{
				path: "/web/set/questionnaireDailysummaryDetail",
				name: "set/questionnaireDailysummaryDetail",
				component: () =>
					import("../views/set/questionnaireDailysummaryDetail.vue"),
				meta: {
					state: false,
					level: 3
				}
			},
			// 问卷统计 --查看学员问卷
			{
				path: "/web/set/questionnaireStudentList",
				name: "set/questionnaireStudentList",
				component: () => import("../views/set/questionnaireStudentList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 问卷统计 --查看学员问卷
			{
				path: "/web/set/questionnaireStudentListDetail",
				name: "set/questionnaireStudentListDetail",
				component: () =>
					import("../views/set/questionnaireStudentListDetail.vue"),
				meta: {
					state: false,
					level: 3
				}
			},
			// 签到信息统计
			{
				path: "/web/set/faceStudentstatistics",
				name: "set/faceStudentstatistics",
				component: () => import("../views/set/faceStudentstatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 签到信息查询
			{
				path: "/web/set/faceStudentSearch",
				name: "set/faceStudentSearch",
				component: () => import("../views/set/faceStudentSearch.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 综合信息查询
			{
				path: "/set/ciq/ComprehensiveInformationQuery",
				name: "set_ciq_ComprehensiveInformationQuery",
				component: () => import("../views/set/ciq/ComprehensiveInformationQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 综合信息查询 -  学习记录 - 学员轨迹
			{
				path: "/set/ciq/type_3_Info",
				name: "set_ciq_type_3_Info",
				component: () => import("../views/set/ciq/type_3_Info.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 综合信息查询 监管
			{
				path: "/set/ciqJg/ComprehensiveInformationQuery",
				name: "set_ciqJg_ComprehensiveInformationQuery",
				component: () => import("../views/set/ciqJg/ComprehensiveInformationQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 综合信息查询 监管 -  学习记录 - 学员轨迹 
			{
				path: "/set/ciqJg/type_3_Info",
				name: "set_ciqJg_type_3_Info",
				component: () => import("../views/set/ciqJg/type_3_Info.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 综合信息查询(浦东新区)
			{
				path: "/set/ciqSHpudong/ComprehensiveInformationQuery",
				name: "set_ciqSHpudong_ComprehensiveInformationQuery",
				component: () => import("../views/set/ciqSHpudong/ComprehensiveInformationQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 综合信息查询(青浦)
			{
				path: "/set/ciqSHqingpu/ComprehensiveInformationQuery",
				name: "set_ciqSHqingpu_ComprehensiveInformationQuery",
				component: () => import("../views/set/ciqSHqingpu/ComprehensiveInformationQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 综合信息查询(青浦)_新
			{
				path: "/set/ciqSHqingpu_new/ComprehensiveInformationQuery",
				name: "set_ciqSHqingpu_new_ComprehensiveInformationQuery",
				component: () => import("../views/set/ciqSHqingpu_new/ComprehensiveInformationQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 人脸认证预警
			{
				path: "/web/operate/faceAuthenticationWarning",
				name: "operate/faceAuthenticationWarning",
				component: () => import("../views/operate/statistics/faceAuthenticationWarning.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 人脸认证预警 - 查看认证照片
			{
				path: "/web/operate/faceAuthenticationWarningScreen",
				name: "operate/faceAuthenticationWarningScreen",
				component: () => import("../views/operate/statistics/faceAuthenticationWarningScreen.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 系统监管 - 认证照片检查
			{
				path: "/web/inspectionOfCertifiedPhotos",
				name: "web_inspectionOfCertifiedPhotos",
				component: () => import("../views/operate/inspectionOfCertifiedPhotos/inspectionOfCertifiedPhotos.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 系统监管 - 学员证件监查
			{
				path: "/web/certificateInspection",
				name: "web_certificateInspection",
				component: () => import("../views/operate/certificateInspection/certificateInspection.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 石家庄点名认证统计
			{
				path: "/web/operate/RollCallCertificationStatistics",
				name: "operate/RollCallCertificationStatistics",
				component: () =>
					import(
						"../views/operate/RollCallCertificationStatistics/RollCallCertificationStatistics.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 石家庄点名认证统计(运营)
			{
				path: "/web/operate/RollCallCertificationStatisticsYw",
				name: "operate/RollCallCertificationStatisticsYw",
				component: () =>
					import(
						"../views/operate/RollCallCertificationStatistics/RollCallCertificationStatisticsYw.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学习记录
			{
				path: "/web/Learningrecords",
				name: "Learningrecords",
				component: () => import("../views/classListCom/Learningrecords.vue")
			},
			// 客户中心 - 客户入驻
			{
				path: "/web/userFeedback",
				name: "userFeedback",
				component: () => import("../views/userFeedback/userFeedback.vue"),
				meta: {
					level: 1
				}
			},
			// 客户中心 - 企业招工
			{
				path: "/web/userFeedback/advertisementList",
				name: "/userFeedback_advertisementList",
				component: () => import("../views/userFeedback/advertisementList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 单位列表
			{
				path: "/web/UnitList",
				name: "UnitList",
				component: () => import("../views/Unit/UnitList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 企业单位审核
			{
				path: "/web/UnitListAudit",
				name: "UnitListAudit",
				component: () => import("../views/Unit/UnitListAudit.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 单位编辑
			{
				path: "/web/UnitEdit",
				name: "UnitEdit",
				component: () => import("../views/Unit/UnitEdit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 企业单位审核 - 单位编辑
			{
				path: "/web/UnitEditAudit",
				name: "UnitEditAudit",
				component: () => import("../views/Unit/UnitEditAudit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 解除绑定
			{
				path: "/web/zUnbind",
				name: "zUnbind",
				component: () => import("../views/Unit/zUnbind.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 资源管理-平台资源
			{
				path: "/web/platformCourses",
				name: "platformCourses",
				component: () =>
					import("../views/resourceManagement/platformCourses.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 资源管理-核算设置
			{
				path: "/web/accountingIsSet",
				name: "accountingIsSet",
				component: () =>
					import("../views/resourceManagement/accountingIsSet.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 资源管理-平台资源（运营）
			{
				path: "/web/operatePlatformCourses",
				name: "operatePlatformCourses",
				component: () =>
					import("../views/resourceManagement/operatePlatformCourses.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 资源管理-平台课程查询（运营）
			{
				path: "/web/platformCourseQuery",
				name: "platformCourseQuery",
				component: () =>
					import("../views/resourceManagement/platformCourseQuery.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//资源管理-课件列表
			{
				path: "/web/operate/koniptList",
				name: "koniptList",
				component: () => import("../views/operate/KoniptList/koniptList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/operate/koniptDetails",
				name: "koniptDetails",
				component: () => import("../views/operate/KoniptList/koniptDetails"),
				meta: {
					state: false,
					level: 2
				}
			},
			//资源管理-问卷列表
			{
				path: "/web/operate/questionnaireList",
				name: "questionnaireList",
				component: () =>
					import("../views/operate/questionnaireList/questionnaireList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/operate/questionnaireEdit",
				name: "questionnaireEdit",
				component: () =>
					import("../views/operate/questionnaireList/questionnaireEdit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 消息管理-系统公告
			{
				path: "/web/systemNotication",
				name: "systemNotication",
				component: () =>
					import("../views/message/SystemNotification/SystemNotification.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 消息管理-公告详情
			{
				path: "/web/noticationDetail",
				name: "noticationDetail",
				component: () =>
					import("../views/message/SystemNotification/NotificationDetail.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 消息管理-公告推送
			{
				path: "/web/AnnouncementPush",
				name: "AnnouncementPush",
				component: () =>
					import("../views/message/announcementPush/AnnouncementPush.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 消息管理-公告推送-编辑
			{
				path: "/web/AnnouncementPushEdit",
				name: "AnnouncementPushEdit",
				component: () =>
					import("../views/message/announcementPush/AnnouncementPushEdit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 机构端   消息管理-公告推送
			{
				path: "/web/AnnouncementPushJG",
				name: "AnnouncementPushJG",
				component: () =>
					import("../views/message/announcementPush/AnnouncementPushJG.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//机构端   消息管理-公告推送-编辑
			{
				path: "/web/AnnouncementPushEditJG",
				name: "AnnouncementPushEditJG",
				component: () =>
					import(
						"../views/message/announcementPush/AnnouncementPushEditJG.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 消息管理-消息列表
			{
				path: "/web/newsList",
				name: "newsList",
				component: () => import("../views/message/news/newsList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 消息管理-消息列表-编辑
			{
				path: "/web/newsListInfo",
				name: "newsListInfo",
				component: () => import("../views/message/news/newsListInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			/* 消息管理-消息推送*/
			{
				path: "/web/BusMessagePush",
				name: "BusMessagePush",
				component: () =>
					import("../views/message/BusMessagePush/BusMessagePush.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/* 监管端 */
			// 单位统计
			{
				path: "/web/UnitStatistics",
				name: "UnitStatistics",
				component: () =>
					import("../views/SupervisionEnd/StatisticalQuery/UnitStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级培训统计
			{
				path: "/web/ClassTrainingStatistics",
				name: "SupervisionEnd_StatisticalQuery_ClassTrainingStatistics",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ClassTrainingStatistics.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 班级培训统计 -- 职业健康  学员档案
			{
				path: "/web/classTrainingRecordsSee",
				name: "classTrainingRecordsSee",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/classTrainingRecordsSee.vue"
					)
			},
			// 班级培训统计 - 监管点名
			{
				path: "/web/RegulatoryRollCall",
				name: "RegulatoryRollCall",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/RegulatoryRollCall.vue"
					)
			},
			// 班级培训统计 -  机构资料
			{
				path: "/web/InstitutionalInformation",
				name: "InstitutionalInformation",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/InstitutionalInformation.vue"
					)
			},
			// 班级培训统计-全部
			{
				path: "/web/ClassTrainingStatisticsall",
				name: "ClassTrainingStatisticsall",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ClassTrainingStatistics.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级培训统计-详情
			{
				path: "/web/ClassTraingDetails",
				name: "ClassTraingDetails",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ClassTraingDetails.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学员学习记录
			{
				path: "/web/StudentLearningRecord",
				name: "StudentLearningRecord",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/StudentLearningRecord.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			/* 个人学习轨迹 */
			{
				path: "/web/personStudyTrajectoryList",
				name: "personStudyTrajectoryList",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/personStudyTrajectoryList.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 截屏备案
			{
				path: "/web/StudentLearningScreen",
				name: "StudentLearningScreen",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/StudentLearningScreen"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 学员学习记录
			{
				path: "/web/StudentLearningRecordall",
				name: "StudentLearningRecordall",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/StudentLearningRecord.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 学员学习记录-个人学习记录
			{
				path: "/web/ViewLearningRecords",
				name: "ViewLearningRecords",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ViewLearningRecords.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 学时证明查询
			{
				path: "/web/ClassHourCertificateQuery",
				name: "ClassHourCertificateQuery",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ClassHourCertificateQuery.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 学员档案查询
			{
				path: "/web/StudentFileQuery",
				name: "StudentFileQuery",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/StudentFileQuery.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 人脸认证统计
			{
				path: "/web/FaceAuthenticationStatistics",
				name: "FaceAuthenticationStatistics",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/FaceAuthenticationStatistics.vue"
					),
				meta: {
					state: false
				}
			},
			// 截屏备案记录
			{
				path: "/web/ScreenCapture",
				name: "ScreenCapture",
				component: () =>
					import("../views/SupervisionEnd/StatisticalQuery/ScreenCapture.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 截屏备案记录 - 详情
			{
				path: "/web/ScreenCaptureInfo",
				name: "ScreenCaptureInfo",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ScreenCaptureInfo.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 机构端截屏备案记录
			{
				path: "/web/ScreenCaptureOrg",
				name: "ScreenCaptureOrg",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/ScreenCaptureOrg.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 合规自查 - 详情
			{
				path: "/web/complianceSelfInspection",
				name: "complianceSelfInspection",
				component: () =>
					import(
						"../views/SupervisionEnd/StatisticalQuery/complianceSelfInspection.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 评论管理
			{
				path: "/web/CommentManagement",
				name: "CommentManagement",
				component: () =>
					import("../views/SupervisionEnd/StatisticalQuery/CommentManagement.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 评论管理 --详情
			{
				path: "/web/CommentManagementInfo",
				name: "CommentManagementInfo",
				component: () =>
					import("../views/SupervisionEnd/StatisticalQuery/CommentManagementInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 考试查询 -- 监管
			{
				path: "/web/ExaminationJg",
				name: "ExaminationJg",
				component: () =>
					import("../views/SupervisionEnd/StatisticalQuery/ExaminationJg.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 首页
			{
				path: "/web/operate/home",
				name: "operate/home",
				component: () => import("../views/operate/home.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 首页 --鉴定机构(不知道给谁用,反正要做)
			{
				path: "/web/operate/homeJdx",
				name: "operate/homeJdx",
				component: () => import("../views/operate/homeJdx.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 首页 --鉴定机构
			{
				path: "/web/operate/homeJd",
				name: "operate/homeJd",
				component: () => import("../views/operate/homeJd.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 鉴定机构-数据中心-学员报名统计
			{
				name: "operate_stuRegSta",
				path: "/web/operate/stuRegSta",
				component: () => import("../views/operate/dataCenterJDJG/stuRegSta.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 鉴定机构-数据中心-学员缴费统计
			{
				name: "operate_dataCenterJDJG_studentPaymentStatistics",
				path: "/operate/dataCenterJDJG/studentPaymentStatistics",
				component: () => import("../views/operate/dataCenterJDJG/studentPaymentStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 鉴定机构-数据中心-人员数据汇集（吉林专用）
			{
				name: "operate_personnelDataCollectionJiLin_index",
				path: "/operate/personnelDataCollectionJiLin/index",
				component: () => import("../views/operate/personnelDataCollectionJiLin/index.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 鉴定机构-数据中心-人员数据汇集（吉林专用）- 新增页面
			{
				name: "operate_personnelDataCollectionJiLin_addData",
				path: "/operate/personnelDataCollectionJiLin/addData",
				component: () => import("../views/operate/personnelDataCollectionJiLin/addData.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 鉴定机构-数据中心-人员数据汇集（吉林专用）- 查看学员
			{
				name: "operate_personnelDataCollectionJiLin_studentList",
				path: "/operate/personnelDataCollectionJiLin/studentList",
				component: () => import("../views/operate/personnelDataCollectionJiLin/studentList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 鉴定机构-培训管理-练习题库配置
			{
				name: "operate_exerciseQuestionBankConfig",
				path: "/web/operate/exerciseQuestionBankConfig",
				component: () => import("../views/institutionalManagement/exerciseQuestionBankConfig/exerciseQuestionBankConfig.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 鉴定机构-培训管理-练习题库配置-查看列表
			{
				name: "operate_ViewList",
				path: "/web/operate/viewList",
				component: () => import("../views/institutionalManagement/exerciseQuestionBankConfig/viewList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营班级课程参数
			{
				path: "/web/operate/classParams",
				name: "operate/classParams",
				component: () => import("../views/operate/systemSet/classParams.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 查看班级课程参数
			{
				path: "/web/operate/seeClassCurriculumParameter",
				name: "operate/seeClassCurriculumParameter",
				component: () =>
					import(
						"../views/operate/seeClassCurriculumParameter/seeClassCurriculumParameter.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 新增班级课程参数 - 详情
			{
				path: "/web/operate/seeClassCurriculumParameterInfo",
				name: "SupervisionEnd/seeClassCurriculumParameterInfo",
				component: () =>
					import(
						"../views/operate/seeClassCurriculumParameter/seeClassCurriculumParameterInfo.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 推荐课程
			{
				path: "/web/operate/CourseRecommen",
				name: "operate/CourseRecommen",
				component: () =>
					import("../views/operate/systemSet/courseRecommen.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 首次弹窗配置
			{
				path: "/web/operate/firstPopUpDialog",
				name: "operate/firstPopUpDialog",
				component: () =>
					import("../views/operate/firstPopUpDialog/firstPopUpDialog.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 推广设置
			{
				path: "/web/operate/Promote",
				name: "operate/Promote",
				component: () => import("../views/operate/promote/PromoteSet.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 推广设置-新增推广
			{
				path: "/web/operate/AddPromote",
				name: "operate/AddPromote",
				component: () => import("../views/operate/promote/AddPromoteSet.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 资讯公告
			{
				path: "/web/operate/advisoryNotice",
				name: "operate/advisoryNotice",
				component: () =>
					import("../views/operate/consultingService/advisoryNotice.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 资讯公告 - 新增/编辑
			{
				path: "/web/operate/aeAdvisoryNotice",
				name: "operate/aeAdvisoryNotice",
				component: () =>
					import("../views/operate/consultingService/aeAdvisoryNotice.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 系统设置 - 实名认证管理
			{
				path: "/web/operate/realNameAuthenticationList",
				name: "operate/realNameAuthenticationList",
				component: () =>
					import(
						"../views/operate/realNameAuthentication/realNameAuthenticationList.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 系统设置 - 实名认证管理 - 添加/编辑
			{
				path: "/web/operate/realNameAuthenticationListInfo",
				name: "operate/realNameAuthenticationListInfo",
				component: () =>
					import(
						"../views/operate/realNameAuthentication/realNameAuthenticationListInfo.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 系统设置 - 鉴定机构设置
			{
				path: "/web/operate/appraisalOrganSetupList",
				name: "operate/appraisalOrganSetupList",
				component: () =>
					import(
						"../views/operate/appraisalOrgan/appraisalOrganSetupList.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 系统设置 - 鉴定机构设置 - 添加/编辑
			{
				path: "/web/operate/appraisalOrganSetupUpdate",
				name: "operate/appraisalOrganSetupUpdate",
				component: () =>
					import(
						"../views/operate/appraisalOrgan/appraisalOrganSetupUpdate.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 系统设置 - 培训大类归集
			{
				path: "/web/operate/trainCategoryCollection",
				name: "operate/trainCategoryCollection",
				component: () =>
					import(
						"../views/operate/trainCategoryCollection/trainCategoryCollection.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运维工具 - 敏感词管理
			{
				path: "/web/sensitiveWord",
				name: "sensitive_word",
				component: () =>
					import("../views/operate/sensitiveWord/sensitiveWordList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 推荐视频
			{
				path: "/web/operate/videolist",
				name: "operate/videolist",
				component: () =>
					import("../views/operate/videoReconmmendation/videolist.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 推荐视频 - 新增/修改
			{
				path: "/web/operate/videoedit",
				name: "operate/videoedit",
				component: () =>
					import("../views/operate/videoReconmmendation/videoedit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 推荐课程 - 新增/修改
			{
				path: "/web/operate/videoCourseEdit",
				name: "operate/videoCourseEdit",
				component: () =>
					import("../views/operate/videoReconmmendation/videoCourseEdit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营开班统计
			{
				path: "/web/operate/classStart",
				name: "operate/classStart",
				component: () => import("../views/operate/statistics/classStart.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营开班统计-机构代理商
			{
				path: "/web/operate/compAgencyClassStart",
				name: "operate/compAgencyClassStart",
				component: () =>
					import("../views/operate/statistics/compAgencyClassStart.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营学员统计
			{
				path: "/web/operate/studentStatistics",
				name: "operate/studentStatistics",
				component: () =>
					import("../views/operate/statistics/studentStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 班级结算统计
			{
				path: "/web/operate/classSettlementStatistics",
				name: "operate/classSettlementStatistics",
				component: () =>
					import("../views/operate/statistics/classSettlementStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 课件配课统计
			{
				path: "/web/operate/coursewareWithClass",
				name: "operate/coursewareWithClass",
				component: () =>
					import("../views/operate/coursewareWithClass/coursewareWithClass.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 鉴定报名统计
			{
				path: "/web/operate/appraisalEnrollStatistics",
				name: "operate/appraisalEnrollStatistics",
				component: () =>
					import("../views/operate/appraisalEnrollStatistics/appraisalEnrollStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构收款统计
			{
				path: "/web/operate/agenciesGatheringStatistics",
				name: "operate/agenciesGatheringStatistics",
				component: () =>
					import("../views/operate/agenciesGatheringStatistics/agenciesGatheringStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营 - 公示名单查询
			{
				path: "/web/operate/publicityQuery",
				name: "publicityQuery",
				component: () =>
					import("../views/operate/publicityQuery/publicityQuery.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 机构代理商 - 公示名单查询
			{
				path: "/web/operate/jgpublicityQuery",
				name: "jgpublicityQuery",
				component: () =>
					import("../views/operate/publicityQuery/jgpublicityQuery.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营学员统计(轨迹合并)
			{
				path: "/web/operate/studentStatisticsTrackMerging",
				name: "operate/studentStatisticsTrackMerging",
				component: () =>
					import("../views/operate/statistics/studentStatisticsTrackMerging.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营学员统计 学习详情
			{
				path: "/web/operate/learningDetails",
				name: "operate/learningDetails",
				component: () =>
					import("../views/operate/statistics/learningDetails.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营学员统计 -查看日志
			{
				path: "/web/operate/studentStatisticsJournal",
				name: "operate/studentStatisticsJournal",
				component: () => import("../views/operate/statistics/studentStatisticsJournal.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营学员统计 -查看暂停日志
			{
				path: "/web/operate/studentStatisticsJournalZT",
				name: "operate/studentStatisticsJournalZT",
				component: () => import("../views/operate/statistics/studentStatisticsJournalZT.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营人脸认证统计
			{
				path: "/web/operate/faceStudentstatistics",
				name: "operate/faceStudentstatistics",
				component: () =>
					import("../views/operate/statistics/faceStudentstatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营人脸认证统计 增加删除功能
			{
				path: "/web/operate/faceStudentstatisticsDel",
				name: "operate/faceStudentstatisticsDel",
				component: () =>
					import("../views/operate/statistics/faceStudentstatisticsDel.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营端 - 培训管理 - 班级参数筛查
			{
				path: "/web/operate/classParameterScreening",
				name: "operate_classParameterScreening",
				component: () =>
					import("../views/operate/statistics/classParameterScreening.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营端 - 培训管理 - 预付款管理（第三方）
			{
				path: "/web/operate/advancePaymentManagementThreeSquare",
				name: "operate_advancePaymentManagementThreeSquare",
				component: () =>
					import("../views/operate/statistics/advancePaymentManagementThreeSquare.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 数据统计 - 区域客服业绩统计
			{
				path: "/web/operate/statistics/kf_PerformanceStatistics",
				name: "web/operate/statistics/kf_PerformanceStatistics",
				component: () =>
					import("../views/operate/statistics/kf_PerformanceStatistics.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 销售人员业绩统计
			{
				path: "/web/operate/statistics/xs_PerformanceStatistics",
				name: "web/operate/statistics/xs_PerformanceStatistics",
				component: () =>
					import("../views/operate/statistics/xs_PerformanceStatistics.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 销售人员业绩统计 - 数据对比
			{
				path: "/web/operate/statistics/xs_PerformanceStatistics_db",
				name: "web/operate/statistics/xs_PerformanceStatistics_db",
				component: () =>
					import("../views/operate/statistics/xs_PerformanceStatistics_db.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 运营数据汇总
			{
				path: "/web/operate/statistics/yw_PerformanceStatistics",
				name: "web/operate/statistics/yw_PerformanceStatistics",
				component: () =>
					import("../views/operate/statistics/yw_PerformanceStatistics.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 运营数据汇总 - 数据对比
			{
				path: "/web/operate/statistics/yw_PerformanceStatistics_db",
				name: "web/operate/statistics/yw_PerformanceStatistics_db",
				component: () =>
					import("../views/operate/statistics/yw_PerformanceStatistics_db.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//数据统计 - 学员统计（按班统计）
			{
				path: "/web/operate/studentStatisticsByClass",
				name: "operate/studentStatisticsByClass",
				component: () =>
					import("../views/operate/statistics/studentStatisticsByClass.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 数据统计 - 学员统计（按班统计） 学习详情
			{
				path: "/web/operate/learningDetailsByClass",
				name: "operate/learningDetailsByClass",
				component: () =>
					import("../views/operate/statistics/learningDetailsByClass.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 学员统计（按班统计）-学习轨迹
			{
				path: "/web/operate/TrajectoryListbyClass",
				name: "/operate/TrajectoryListbyClass",
				component: () => import("../views/operate/statistics/TrajectoryListbyClass.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 数据统计 - 学员统计（按班统计）-课程详情
			{
				path: "/web/operate/courseListByClass",
				name: "/operate/courseListByClass",
				component: () => import("../views/operate/statistics/courseListByClass.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//数据统计 - 合格证管理
			{
				path: "/web/operate/certificateManagement",
				name: "operate/certificateManagement",
				component: () =>
					import("../views/operate/statistics/certificateManagement.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 视频录制统计
			{
				path: "/web/operate/videoRecordingStatisticsList",
				name: "operate/videoRecordingStatisticsList",
				component: () =>
					import("../views/operate/videoRecordingStatistics/videoRecordingStatisticsList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 机构统计
			{
				path: "/web/operate/InstitutionalStatistics",
				name: "operate/InstitutionalStatistics",
				component: () =>
					import("../views/operate/InstitutionalStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 补考订单列表
			{
				path: "/web/operate/makeUpOrderSystem",
				name: "operate/makeUpPOrderSystem",
				component: () =>
					import("../views/operate/statistics/makeUpOrderSystem.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 发布课程使用
			{
				path: "/web/operate/publishCourseUsage",
				name: "operate/publishCourseUsage",
				component: () =>
					import("../views/operate/statistics/publishCourseUsage.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 课程使用统计
			{
				path: "/web/operate/courseUsageStatistics",
				name: "operate/courseUsageStatistics",
				component: () =>
					import("../views/operate/statistics/courseUsageStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 课程使用统计 - 查看班级
			{
				path: "/web/operate/courseUsageStatisticsInfo",
				name: "operate/courseUsageStatisticsInfo",
				component: () =>
					import("../views/operate/statistics/courseUsageStatisticsInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//数据统计 - 登录信息统计
			{
				path: "/web/operate/statistics/signInformationStatistics",
				name: "statistics_signInformationStatistics",
				component: () =>
					import("../views/operate/statistics/signInformationStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//数据统计 - 登录信息统计(嘉定镇)
			{
				path: "/web/operate/statistics/signInformationStatisticsJDZ",
				name: "statistics_signInformationStatisticsJDZ",
				component: () =>
					import("../views/operate/statistics/signInformationStatisticsJDZ.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 考试查询
			{
				path: "/web/operate/Examination",
				name: "operate/Examination",
				component: () =>
					import("../views/operate/Examinationinquiry/Examination.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 活跃度检测
			{
				path: "/web/operate/activityDetection",
				name: "operate_activityDetection",
				component: () =>
					import("../views/operate/activityDetection/activityDetection.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 活跃度检测监管
			{
				path: "/web/operate/activityDetectionJg",
				name: "operate_activityDetectionJg",
				component: () =>
					import("../views/operate/activityDetection/activityDetectionJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 人脸活跃度检测
			{
				path: "/web/operate/facialActivityDetection/index",
				name: "operate_facialActivityDetection",
				component: () =>
					import("../views/operate/facialActivityDetection/index.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 人脸活跃度检测监管
			{
				path: "/web/operate/facialActivityDetection/indexJg",
				name: "operate_facialActivityDetectionJg",
				component: () =>
					import("../views/operate/facialActivityDetection/indexJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 考试查询
			{
				path: "/web/operate/ExaminationDetails",
				name: "operate/ExaminationDetails",
				component: () =>
					import("../views/operate/Examinationinquiry/ExaminationDetails.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 考试查询 --运营
			{
				path: "/web/operate/ExaminationYw",
				name: "operate/ExaminationYw",
				component: () =>
					import("../views/operate/Examinationinquiry/ExaminationYw.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 导出日志
			{
				path: "/web/operation/exportLog",
				name: "operation/exportLog",
				component: () => import("../views/OperationSupervision/exportLog.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/**
			 * 长春对接--运营
			 */
			// 外部班级列表
			{
				path: "/web/operate/outClassList",
				name: "operate/outClassList",
				component: () => import("../views/operate/outClass/outClassList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 学员列表
			{
				path: "/web/operate/studentDetailList",
				name: "operate/studentDetailList",
				component: () =>
					import("../views/operate/outClass/studentDetailLIst.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 讲师资质下载
			{
				path: "/web/operate/lecturerQualificationDownloadList",
				name: "operate/lecturerQualificationDownloadList",
				component: () =>
					import("../views/operate/lecturerQualificationDownload/list.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 资质类型维护
			{
				path: "/web/operate/qualificationsType",
				name: "operate/qualificationsType",
				component: () =>
					import("../views/operate/qualificationsType/qualificationsType.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 非法图片检测器
			{
				path: "/web/operate/illegalImgChecker",
				name: "operate/illegalImgChecker",
				component: () => import("../views/illegalImgChecker.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营端- 培训管理- 开班记录
			{
				path: "/web/classListCom/startClassRecord",
				name: "classListCom/startClassRecord",
				component: () => import("../views/classListCom/startClassRecord.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端- 培训管理- 开班记录
			{
				path: "/web/classListCom/startClassRecordCourseDetail",
				name: "classListCom/startClassRecordCourseDetail",
				component: () => import("../views/classListCom/startClassRecordCourseDetail.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端- 培训管理- 开班记录- 设置上传照片
			{
				path: "/web/classListCom/setUploadPhoto",
				name: "classListCom/setUploadPhoto",
				component: () => import("../views/classListCom/setUploadPhoto.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 极光推送
			{
				path: "/web/operate/pushList",
				name: "operate/pushList",
				component: () =>
					import("../views/operate/push/pushList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 极光推送 -新增
			{
				path: "/web/operate/pushAdd",
				name: "operate/pushAdd",
				component: () =>
					import("../views/operate/push/pushAdd.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端- 运行监管- 登录日志
			{
				path: "/web/OperationSupervision/loginLog",
				name: "OperationSupervision/loginLog",
				component: () => import("../views/OperationSupervision/loginLog.vue")
			},
			// 运营端- 运行监管- APP登录日志
			{
				path: "/web/OperationSupervision/loginLogAPP",
				name: "OperationSupervision/loginLogAPP",
				component: () => import("../views/OperationSupervision/loginLogAPP.vue")
			},
			// 运营端- 运行监管- APP错误日志
			{
				path: "/web/OperationSupervision/errorAPP",
				name: "OperationSupervision/errorAPP",
				component: () => import("../views/OperationSupervision/errorAPP.vue")
			},
			// 运营端- 运行监管- 班级抽查
			{
				path: "/web/operate/ClassSpotCheck",
				name: "ClassSpotCheck/ClassSpotCheck",
				component: () =>
					import("../views/operate/ClassSpotCheck/ClassSpotCheck.vue")
			},

			// 运营端 - 运行监管 - 预览视频日志
			{
				path: "/web/supervise/watchLog",
				name: "supervise/WatchLogList",
				component: () => import("../views/watchLog/WatchLogList.vue")
			},

			// 运营端 - 运行监管 - 问题分类
			{
				path: "/questionClassification",
				name: "questionClassification ",
				component: () => import("../views/questionClassification/index.vue")
			},


			// 机构端- 培训管理- 问卷调查编辑
			{
				path: "/web/questionnaire/questionnaireSurveyEdit",
				name: "questionnaire/questionnaireSurveyEdit",
				component: () =>
					import("../views/questionnaire/questionnaireSurveyEdit.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端- 培训管理- 问卷调查
			{
				path: "/web/questionnaire/questionnaireSurvey",
				name: "questionnaire/questionnaireSurvey",
				component: () =>
					import("../views/questionnaire/questionnaireSurvey.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端- 培训管理- 问卷活动管理
			{
				path: "/web/questionnaire/questionnaireActivity",
				name: "questionnaire/questionnaireActivity",
				component: () =>
					import("../views/questionnaire/questionnaireActivity.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/questionnaire/questionnaireSurveySummary",
				name: "questionnaire/questionnaireSurveySummary",
				component: () =>
					import("../views/questionnaire/questionnaireSurveySummary.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 机构端- 培训管理- 反馈记录
			{
				path: "/web/questionnaire/feedbackRecord",
				name: "questionnaire/feedbackRecord",
				component: () => import("../views/questionnaire/feedbackRecord.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端- 培训管理- 预览
			{
				path: "/web/questionnaire/preview",
				name: "questionnaire/preview",
				component: () => import("../views/questionnaire/preview.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端- 培训管理- 培训档案
			{
				path: "/web/trainingManagement/TrainingRecordsJg",
				name: "trainingManagement/TrainingRecordsJg",
				component: () =>
					import("../views/trainingManagement/TrainingRecordsJg.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营端- 培训管理- 培训档案
			{
				path: "/web/trainingManagement/TrainingRecords",
				name: "trainingManagement/TrainingRecords",
				component: () =>
					import("../views/trainingManagement/TrainingRecords.vue"),
				meta: {
					state: true,
					level: 1
				}
			},

			// 运营端- 培训管理- 培训档案预览
			{
				path: "/web/trainingManagement/TrainingRecordsSee",
				name: "trainingManagement/TrainingRecordsSee",
				component: () =>
					import("../views/trainingManagement/TrainingRecordsSee.vue")
			},
			// 运营端- 培训管理- 人脸审核
			{
				path: "/web/trainingManagement/faceCheck",
				name: "trainingManagement/faceCheck",
				component: () => import("../views/trainingManagement/faceCheck.vue")
			},
			//石家庄监管路由-班级审核
			{
				path: "/web/SupervisionEnd/sjzRegulator/classReview",
				name: "sjzRegulator/classReview",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/classReview.vue")
			},
			//石家庄监管路由-班级审核 -学员分组
			{
				path: "/web/SupervisionEnd/sjzRegulator/studentGroup",
				name: "sjzRegulator/studentGroup",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/studentGroup.vue")
			},
			//石家庄监管路由-班级审核 - 点名设置
			{
				path: "/web/SupervisionEnd/sjzRegulator/RollCallSetDetail",
				name: "sjzRegulator/RollCallSetDetail",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/RollCallSetDetail.vue")
			},
			//石家庄监管路由- 机构列表
			{
				path: "/web/SupervisionEnd/sjzRegulator/organizationList",
				name: "sjzRegulator/organizationList",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/organizationList.vue")
			},
			//石家庄监管路由- 机构列表-开班记录
			{
				path: "/web/SupervisionEnd/sjzRegulator/startClassRecord",
				name: "sjzRegulator/startClassRecord",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/startClassRecord.vue")
			},
			//石家庄监管路由- 机构列表-开班记录-学员
			{
				path: "/web/SupervisionEnd/sjzRegulator/studentList",
				name: "sjzRegulator/studentList",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/studentList.vue")
			},
			//石家庄监管路由- 机构列表-开班记录-学员- 认证统计
			{
				path: "/web/SupervisionEnd/sjzRegulator/rollStatistics",
				name: "sjzRegulator/rollStatistics",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/rollStatistics.vue")
			},
			//石家庄监管路由- 机构列表-开班记录-学员 - 学员记录
			{
				path: "/web/SupervisionEnd/sjzRegulator/studyRecord",
				name: "sjzRegulator/studyRecord",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/studyRecord.vue")
			},
			//石家庄监管路由- 机构列表-开班记录-班级档案
			{
				path: "/web/SupervisionEnd/sjzRegulator/classRecord",
				name: "sjzRegulator/classRecord",
				component: () =>
					import("../views/SupervisionEnd/sjzRegulator/classRecord.vue")
			},
			//石家庄
			{
				path: "/web/Shijiazhuang/AppeaList",
				name: "AppeaList",
				component: () => import("../views/ShijiazhuangDocuments/AppeaList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/**
			 * 石家庄机构
			 */
			// 点名设置
			{
				path: "/web/sjz/jg/RollCallSet",
				name: "sjz/jg/RollCallSet",
				component: () => import("../views/sjz_jg/RollCallSet.vue")
			},
			// 学员分组
			{
				path: "/web/sjz/jg/studentGroup",
				name: "sjz/jg/studentGroup",
				component: () => import("../views/sjz_jg/studentGroup.vue")
			},
			// 点名设置操作页
			{
				path: "/web/sjz/jg/RollCallSetDetail",
				name: "sjz/jg/RollCallSetDetail",
				component: () => import("../views/sjz_jg/RollCallSetDetail.vue")
			},
			// 点名设置预览
			{
				path: "/web/sjz/jg/rollCallView",
				name: "sjz/jg/rollCallView",
				component: () => import("../views/sjz_jg/rollCallView.vue")
			},
			//班级管理
			{
				path: "/web/sjz/jg/classList",
				name: "sjz/jg/classList",
				component: () => import("../views/sjz_jg/classList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//意见反馈
			{
				path: "/web/operate/feedback",
				name: "feedback",
				component: () => import("../views/operate/feedback/feedback.vue")
			},
			// /**
			//  * 题库
			//  */
			// // 题库订单
			{
				path: "/web/questionBankOrderList",
				name: "questionBankOrderList",
				component: () =>
					import(
						"../views/operate/questionBankOfOrder/questionBankOrderList.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营端 - 订单中心 - 分销订单
			{
				path: "/web/distributionOrder",
				name: "web_distributionOrder",
				component: () => import("../views/operate/distribution/distributionOrder.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 题库基本设置
			{
				path: "/web/questionBankOfBasicSet",
				name: "questionBankOfBasicSet",
				component: () =>
					import(
						"../views/operate/questionBankOfBasicSet/questionBankOfBasicSet.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},
			// 题库用户纠错
			{
				path: "/web/UserErrorCorrection",
				name: "UserErrorCorrection",
				component: () =>
					import(
						"../views/operate/UserErrorCorrection/UserErrorCorrection.vue"
					),
				meta: {
					state: false,
					level: 1
				}
			},

			// 运营端鉴定机构列表
			{
				path: "/web/organizationManagement",
				name: "organizationManagement",
				component: () =>
					import("../views/authenticate/organizationManagement/index.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营端鉴定考试信息
			{
				path: "/web/testInfo",
				name: "testInfo",
				component: () => import("../views/authenticate/testInfo/index.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营端鉴定机构分成
			{
				path: "/web/InstitutionalAllocation",
				name: "InstitutionalAllocation",
				component: () =>
					import(
						"../views/authenticate/InstitutionalAllocation/InstitutionalAllocation.vue"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营端鉴定机构分成 -- 导出名单
			{
				path: "/web/InstitutionalExportList",
				name: "InstitutionalExportList",
				component: () =>
					import(
						"../views/authenticate/InstitutionalAllocation/InstitutionalExportList.vue"
					),
				meta: {
					state: false,
					level: 2
				}
			},
			// 首页 --- 第二种
			{
				path: "/web/OtherHome2",
				name: "OtherHome2",
				component: () => import("../views/commenPages/home_2.vue")
			},

			// 运营端班级列表 -- 学员信息
			{
				path: "/web/studentInfo",
				name: "studentInfo",
				component: () =>
					import("../views/classListCom/classDetailsFile/studentInfo.vue"),
				meta: {
					state: true,
					level: 2
				}
			},
			// 运营端班级列表 -- 学员信息
			{
				path: "/web/studentInfoCertificationRecord",
				name: "studentInfoCertificationRecord",
				component: () =>
					import("../views/classListCom/classDetailsFile/studentInfoCertificationRecord.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端班级列表 -- 班级参数
			{
				path: "/web/classparameters",
				name: "classparameters",
				component: () =>
					import("../views/classListCom/classDetailsFile/classparameters.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端运行监管 -- 移除学员日志
			{
				path: "/web/removeStudentLogList",
				name: "removeStudentLogList",
				component: () =>
					import("../views/operate/removeStudentLog/removeStudentLogList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营端培训管理 -- app报名
			{
				path: "/web/operate/appSignUpList",
				name: "appSignUpList",
				component: () => import("../views/operate/appSignUp/list.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营端意向代理 -- 官网返回
			{
				path: "/web/operate/IntentionalAgencyList",
				name: "IntentionalAgencyList",
				component: () => import("../views/operate/IntentionalAgency/List.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 添加题库
			{
				path: "/web/Questionbank/questionbankAdd",
				name: "questionbankAdd",
				component: () => import("../views/Questionbank/questionbankAdd"),
				meta: {
					state: false,
					level: 2
				}
			},
			//搜题信息
			{
				path: "/web/SearchQuesstionInformation",
				name: "searchquesstioninformation",
				component: () =>
					import("../views/Questionbank/searchquesstioninformation.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 空页面
			{
				path: "/web/redirect",
				name: "redirect",
				component: () => import("../components/redirect.vue")
			},
			//合作方列表
			{
				path: "/web/Partner/partnerList",
				name: "Partner/partnerList",
				component: () => import("../views/Partner/partnerList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//合作方导出列表
			{
				path: "/web/Partner/partnerRecordList",
				name: "Partner/partnerRecordList",
				component: () => import("../views/Partner/partnerRecordList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//导出课程弹窗页
			{
				path: "/web/Partner/PartnerCourseTable",
				name: "Partner/PartnerCourseTable",
				component: () => import("../views/Partner/PartnerCourseTable.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			//結算列表
			{
				path: "/web/Partner/accountList",
				name: "operate/accountEdit",
				component: () => import("../views/Partner/accountList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//山西机构端 培训管理  学员档案
			{
				path: "/web/trainingManagement/shanxiTrainingRecords",
				name: "trainingManagement/shanxiTrainingRecords",
				component: () =>
					import("../views/trainingManagement/shanxiTrainingRecords.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//山西机构端 培训管理  学员档案
			{
				path: "/web/studentFile",
				name: "studentFile",
				component: () =>
					import("../views/classListCom/studentFile.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//山西机构端 培训管理  学员档案(新)
			{
				path: "/web/studentNewFile",
				name: "studentNewFile",
				component: () =>
					import("../views/classListCom/studentNewFile.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//课程方管理端
			/* 课程列表 */
			{
				path: "/web/CourseManagement/CourseList",
				name: "CourseManagement/CourseList",
				component: () => import("../views/CourseManagement/CourseList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/* 课程方  课程方列表 */
			{
				path: "/web/CourseManagement/CourseOwnList",
				name: "CourseManagement/CourseOwnList",
				component: () => import("../views/CourseManagement/CourseOwnList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/*课程方 订单列表 */
			{
				path: "/web/CourseManagement/OrderList",
				name: "CourseManagement/OrderList",
				component: () => import("../views/CourseManagement/OrderList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			/*运营端 订单列表 */
			{
				path: "/web/CourseManagement/BusOrderList",
				name: "CourseManagement/BusOrderList",
				component: () => import("../views/CourseManagement/BusOrderList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/CourseManagement/CourseListDetails",
				name: "CourseManagement/CourseListDetails",
				component: () =>
					import("../views/CourseManagement/CourseListDetails.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//课程方运维端管理端
			/* 课程列表 */
			{
				path: "/web/CourseManagementYw/CourseListYw",
				name: "CourseManagement/CourseListYw",
				component: () => import("../views/CourseManagementYw/CourseListYw.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/CourseManagementYw/CourseListDetailsYw",
				name: "CourseManagement/CourseListDetailsYw",
				component: () =>
					import("../views/CourseManagementYw/CourseListDetailsYw.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 精品课轮播图管理
			{
				path: "/web/course_jp/swipperList",
				name: "course_jp/swipperList",
				component: () => import("../views/operate/course_jp/swipperList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 精品课分类管理
			{
				path: "/web/course_jp/classificationList",
				name: "course_jp/classificationList",
				component: () =>
					import("../views/operate/course_jp/classificationList"),
				meta: {
					state: true,
					level: 1
				}
			},
			// app首页应用
			{
				path: "/web/applicationManagement",
				name: "applicationManagement",
				component: () =>
					import("../views/operate/systemSet/applicationManagement.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			//档案管理
			//一企一档
			{
				path: "/web/OneEnterpriseOneFile",
				name: "OneEnterpriseOneFile",
				component: () =>
					import("../views/fileManagement/OneEnterpriseOneFile.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			{
				path: "/web/ArchivesOfEachIssue",
				name: "ArchivesOfEachIssue",
				component: () =>
					import("../views/fileManagement/ArchivesOfEachIssue.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//档案目录
			{
				path: "/web/CatalogueOfArchives",
				name: "CatalogueOfArchives",
				component: () =>
					import("../views/fileManagement/CatalogueOfArchives.vue"),
				meta: {
					state: false,
					level: 3
				}
			},
			{
				path: "/web/OneEnterpriseArchives",
				name: "OneEnterpriseArchives",
				component: () =>
					import("../views/fileManagement/OneEnterpriseArchives.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//一期一档
			{
				path: "/web/OnePhaseOneFile",
				name: "OnePhaseOneFile",
				component: () => import("../views/fileManagement/OnePhaseOneFile.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// app首页自定义
			// { // 这期不要
			//   path: "/web/renovationList",
			//   name: "renovationList",
			//   component: () =>
			//     import("../views/operate/appRenovation/list.vue"),
			//   meta: {
			//     state: false,
			//     level: 1,
			//   },
			// },
			// app首页自定义
			{
				path: "/web/renovationEdit",
				name: "renovationEdit",
				component: () => import("../views/operate/appRenovation/edit.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			//一企一档(监管)
			{
				path: "/web/OneEnterpriseOneFileJg",
				name: "OneEnterpriseOneFileJg",
				component: () =>
					import("../views/fileManagementJg/OneEnterpriseOneFileJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			{
				path: "/web/ArchivesOfEachIssueJg",
				name: "ArchivesOfEachIssueJg",
				component: () =>
					import("../views/fileManagementJg/ArchivesOfEachIssueJg.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			{
				path: "/web/OneEnterpriseArchivesJg",
				name: "OneEnterpriseArchivesJg",
				component: () =>
					import("../views/fileManagementJg/OneEnterpriseArchivesJg.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//一期一档 list(监管)
			{
				path: "/web/OnePhaseOneFileJg",
				name: "OnePhaseOneFileJg",
				component: () =>
					import("../views/fileManagementJg/OnePhaseOneFileJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 一期一档 目录(监管)
			{
				path: "/web/OneDateOfCatalogJg",
				name: "OneDateOfCatalogJg",
				component: () =>
					import("../views/fileManagementJg/OneDateOfCatalogJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 一人一档 目录(机构)
			{
				path: "/web/OnepersonOneFile",
				name: "OnepersonOneFile",
				component: () => import("../views/fileManagement/OnepersonOneFile.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/OnepersonStudentFiles",
				name: "OnepersonStudentFiles",
				component: () =>
					import("../views/fileManagement/OnepersonStudentFiles.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 一人一档 目录(监管)
			{
				path: "/web/OnepersonOneFileJg",
				name: "OnepersonOneFileJg",
				component: () =>
					import("../views/fileManagementJg/OnepersonOneFileJg.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/OnepersonStudentFilesJg",
				name: "OnepersonStudentFilesJg",
				component: () =>
					import("../views/fileManagementJg/OnepersonStudentFilesJg.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 一人一码 目录(机构)
			{
				path: "/web/OnepersonOneCode",
				name: "OnepersonOneCode",
				component: () => import("../views/fileManagement/OnepersonOneCode.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 极光推送  (先不做)
			// list
			// {
			//   path: "/web/AuroraPushList",
			//   name: "AuroraPushList",
			//   component: () =>
			//     import("../views/operate/AuroraPush/list.vue"),
			//   meta: {
			//     state: false,
			//     level: 1,
			//   },
			// },
			// // 详情
			// {
			//   path: "/web/AuroraPushDetail",
			//   name: "AuroraPushDetail",
			//   component: () =>
			//     import("../views/operate/AuroraPush/detail.vue"),
			//   meta: {
			//     state: false,
			//     level: 1,
			//   },
			// },
			// 积分商城
			// 兑换订单
			{
				path: "/web/PointsMall/orderList",
				name: "PointsMall/orderList",
				component: () => import("../views/operate/PointsMall/orderList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 提现记录
			{
				path: "/web/WithdrawalRecordList",
				name: "WithdrawalRecordList",
				component: () =>
					import("../views/operate/PointsMall/WithdrawalRecordList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 积分设置
			{
				path: "/web/PointsSet",
				name: "PointsSet",
				component: () => import("../views/operate/PointsMall/PointsSet.vue")
			},
			// 营销活动--邀请好友赢奖励
			{
				path: "/web/InviteFriendsToWinAwardsList",
				name: "InviteFriendsToWinAwardsList",
				component: () =>
					import(
						"../views/MarketingActivities/InviteFriendsToWinAwardsList.vue"
					)
			},
			// 营销活动--邀请好友赢奖励 -- 活动明细
			{
				path: "/web/ActivityDetails",
				name: "ActivityDetails",
				component: () =>
					import("../views/MarketingActivities/ActivityDetails.vue")
			},
			// 营销活动--邀请好友赢奖励 -- 创建活动
			{
				path: "/web/CreateActivity",
				name: "CreateActivity",
				component: () =>
					import("../views/MarketingActivities/CreateActivity.vue")
			},
			{
				path: "/web/ShareFriendsToWinAwardsList",
				name: "ShareFriendsToWinAwardsList",
				component: () =>
					import("../views/MarketingActivities/ShareFriendsToWinAwardsList.vue"),
			},
			// 营销活动--分享好友赢奖励-- 活动明细
			{
				path: "/web/ShareActivityDetails",
				name: "ShareActivityDetails",
				component: () =>
					import("../views/MarketingActivities/ShareActivityDetails.vue"),
			},
			// 营销活动--分享好友赢奖励 -- 创建活动
			{
				path: "/web/CreateShareActivity",
				name: "CreateShareActivity",
				component: () =>
					import("../views/MarketingActivities/CreateShareActivity.vue"),
			},
			// 营销活动--活动页设置
			{
				path: "/web/ActivePageSettings",
				name: "ActivePageSettings",
				component: () =>
					import("../views/MarketingActivities/ActivePageSettings.vue"),
				meta: {
					state: false,
					level: 1,
				}
			},
			// 卡密 - 卡密发放列表
			{
				path: "/web/grantList",
				name: "grantList",
				component: () =>
					import("../views/cardDnse/grantList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 卡密 - 卡密发放列表 - 添加/编辑
			{
				path: "/web/grantListInfo",
				name: "grantListInfo",
				component: () =>
					import("../views/cardDnse/grantListInfo.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 卡密 - 卡密发放列表 - 明细
			{
				path: "/web/grantListSeeDetailed",
				name: "grantListSeeDetailed",
				component: () =>
					import("../views/cardDnse/grantListSeeDetailed.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 卡密 - 使用列表
			{
				path: "/web/useList",
				name: "useList",
				component: () =>
					import("../views/cardDnse/useList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 卡密 - 兑换列表
			{
				path: "/web/exchangeList",
				name: "exchangeList",
				component: () =>
					import("../views/cardDnse/exchangeList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 卡密 - 卡密设置
			{
				path: "/web/setUp",
				name: "setUp",
				component: () =>
					import("../views/cardDnse/setUp.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 代理商管理 - 账户列表
			{
				path: "/web/agentAccountList",
				name: "agentAccountList",
				component: () =>
					import("../views/agent/agentAccountList"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 代理商管理 - 列表
			{
				path: "/web/agentList",
				name: "agentList",
				component: () =>
					import("../views/agent/agentList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 代理商管理 - 绑定机构
			{
				path: "/web/bindingMechanism",
				name: "bindingMechanism",
				component: () =>
					import("../views/agent/agentBindingMechanism.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 代理商管理 - 列表-新增
			{
				path: "/web/agentAdd",
				name: "agentAdd",
				component: () =>
					import("../views/agent/agentAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			{
				path: "/web/agentSuccess",
				name: "agentSuccess",
				component: () =>
					import("../views/agent/agentSuccess.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 代理商业绩 - 列表
			{
				path: "/web/agentAchievement",
				name: "agentAchievement",
				component: () =>
					import("../views/agent/agentAchievement.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 代理商业绩统计 - 列表
			{
				path: "/web/agentPerformanceStatistics",
				name: "agentPerformanceStatistics",
				component: () =>
					import("../views/agent/agentPerformanceStatistics.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			{
				path: "/web/agentInstitution",
				name: "agentInstitution",
				component: () =>
					import("../views/agent/agentInstitution.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 代理商管理端 - 首页
			{
				path: "/web/agentHome",
				name: "agentHome",
				component: () =>
					import("../views/agent/agentHome.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 代理商管理端 - 学员列表
			{
				path: "/web/agentStudentList",
				name: "agentStudentList",
				component: () =>
					import("../views/agent/agentStudentList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 代理商管理 - 协议列表
			{
				path: "/web/agreementList",
				name: "agreementList",
				component: () =>
					import("../views/agent/agreementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 大屏展示
			{
				path: "/web/LargeScreenDisplay",
				name: "largeScreenDisplay",
				component: () =>
					import("../views/largeScreenDisplay/largeScreenDisplay.vue"),
				meta: {
					state: false,
					level: 1,
					show: false,
				},
			},
			// 大屏设置 -- 列表
			{
				path: "/web/lgScreenSetList",
				name: "lgScreenSetList",
				component: () =>
					import("../views/largeScreenDisplay/lgScreenSetList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 大屏设置 -- 参数配置
			{
				path: "/web/lgScreenSet",
				name: "lgScreenSet",
				component: () =>
					import("../views/largeScreenDisplay/lgScreenSet.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 大屏设置 -- 参数配置机构
			{
				path: "/web/lgScreenSetJg",
				name: "lgScreenSetJg",
				component: () =>
					import("../views/largeScreenDisplay/lgScreenSetJg.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 大屏 -- 学员明细
			{
				path: "/web/largeScreenstudentList",
				name: "largeScreenDisplay_studentList",
				component: () =>
					import("../views/largeScreenDisplay/studentList.vue"),
				meta: {
					state: false,
					level: 2,
					show: false,
				},
			},
			// 大屏 -- 班级明细
			{
				path: "/web/largeScreenclassDetails",
				name: "largeScreenDisplay_classDetails",
				component: () =>
					import("../views/largeScreenDisplay/classDetails.vue"),
				meta: {
					state: false,
					level: 2,
					show: false,
				},
			},
			// 大屏 -- 培训课程明细
			{
				path: "/web/largeScreentrainingCourse",
				name: "trainingCourse",
				component: () =>
					import("../views/largeScreenDisplay/trainingCourse.vue"),
				meta: {
					state: false,
					level: 2,
					show: false,
				},
			},
			// 专项考试 --- 列表
			{
				path: "/web/specialTestList",
				name: "specialTestList",
				component: () =>
					import("../views/specialTest/specialTestList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 专项考试 --- 编辑新增
			{
				path: "/web/specialTestEdit",
				name: "specialTestEdit",
				component: () =>
					import("../views/specialTest/specialTestEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//专项考试 --- 考试详情
			{
				path: "/web/specialTestDetail",
				name: "specialTestDetail",
				component: () =>
					import("../views/specialTest/specialTestDetail.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//专项考试 --- 考试记录-- 列表
			{
				path: "/web/specialTestRecordList",
				name: "specialTestRecordList",
				component: () =>
					import("../views/specialTest/specialTestRecordList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//专项考试 --- 考试记录 -- 详情
			{
				path: "/web/specialTestRecordDetail",
				name: "specialTestRecordDetail",
				component: () =>
					import("../views/specialTest/specialTestRecordDetail.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//专项考试 --- 监管 -- 列表
			{
				path: "/web/JGspecialTestList",
				name: "JGspecialTestList",
				component: () =>
					import("../views/specialTest/specialTest_JG/JGspecialTestList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//专项考试 --- 监管 -- 考试详情列表
			{
				path: "/web/JGspecialTestDetail",
				name: "JGspecialTestDetail",
				component: () =>
					import("../views/specialTest/specialTest_JG/JGspecialTestDetail.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//资源管理 --- 题库列表
			{
				path: "/web/platformOfquestionBankList",
				name: "platformOfquestionBankList",
				component: () =>
					import("../views/resourceManagement/platformOfquestionBankList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//资源管理 --- 新增题库
			{
				path: "/web/platformOfaddQuestionBank",
				name: "platformOfaddQuestionBank",
				component: () =>
					import("../views/resourceManagement/platformOfaddQuestionBank.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//资源管理 --- 编辑题库
			{
				path: "/web/platformOfeditQuestionBank",
				name: "platformOfeditQuestionBank",
				component: () =>
					import("../views/resourceManagement/platformOfeditQuestionBank.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//资源管理-专家库
			{
				path: "/web/operate/expertDatabase",
				name: "expertDatabase",
				component: () =>
					import("../views/operate/expertDatabase/expertDatabase.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			//资源管理-专家库-新增编辑
			{
				path: "/web/operate/expertDatabaseInfo",
				name: "expertDatabaseInfo",
				component: () =>
					import("../views/operate/expertDatabase/expertDatabaseInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//直播管理 -- 机构
			{
				path: "/web/liveBroadcastManagement/liveClassManagementall",
				name: "liveBroadcastManagement_liveClassManagement",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassManagement.vue"),
			},
			//直播管理 -- 机构
			{
				path: "/web/liveBroadcastManagement/liveClassManagement",
				name: "liveClassManagement",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/liveBroadcastManagement/addLiveClass",
				name: "addLiveClass",
				component: () =>
					import("../views/liveBroadcastManagement/addLiveClass.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 班级管理-详情
			{
				path: "/web/liveBroadcastManagement/liveClassDetailsList",
				name: "liveClassDetailsList",
				component: () => import("../views/liveBroadcastManagement/liveClassDetailsList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 班级管理-详情-学习记录
			{
				path: "/web/liveBroadcastManagement/liveClassStudentListLearningRecords",
				name: "liveClassStudentListLearningRecords",
				component: () => import("../views/liveBroadcastManagement/liveClassStudentListLearningRecords.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 班级管理-详情-认证记录
			{
				path: "/web/liveBroadcastManagement/liveClassStudentListCertificationrecords",
				name: "liveClassStudentListCertificationrecords",
				component: () => import("../views/liveBroadcastManagement/liveClassStudentListCertificationrecords.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//直播管理 -- 机构  -- 期次管理
			{
				path: "/web/liveBroadcastManagement/periodManagement",
				name: "periodManagement",
				component: () =>
					import("../views/liveBroadcastManagement/periodManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//直播管理 -- 机构  -- 直播课程
			{
				path: "/web/liveBroadcastManagement/liveCourse",
				name: "liveCourse",
				component: () =>
					import("../views/liveBroadcastManagement/liveCourse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 机构  -- 直播课程-查看
			{
				path: "/web/liveBroadcastManagement/liveClassCourseDetails",
				name: "liveClassCourseDetails",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassCourseDetails"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 机构  -- 直播课节
			{
				path: "/web/liveBroadcastManagement/liveClassFestival",
				name: "liveClassFestival",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassFestival.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 机构  -- 进入直播
			{
				path: "/web/liveBroadcastManagement/liveBroadcastStatus",
				name: "liveBroadcastStatus",
				component: () =>
					import("../views/liveBroadcastManagement/liveBroadcastStatus"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播班级审核
			{
				path: "/web/liveBroadcastManagement/liveClassReview",
				name: "liveClassReview",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassReview/liveClassReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//直播管理 -- 监管  -- 直播班级审核
			{
				path: "/web/liveBroadcastManagement/liveClassToBeReviewedNew",
				name: "liveClassToBeReviewedNew",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassReview/liveClassToBeReviewedNew.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},

			//直播管理 -- 运维  -- 直播档案
			{
				path: "/web/liveBroadcastManagement/liveFiles",
				name: "liveBroadcastManagement/liveFiles",
				component: () =>
					import("../views/liveBroadcastManagement/liveFiles/index.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播班级审核 - 详情
			{
				path: "/web/liveBroadcastManagement/liveClassDetails",
				name: "liveClassDetails",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassReview/liveClassDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播课程审核
			{
				path: "/web/liveBroadcastManagement/liveClassFestivalReview",
				name: "liveClassFestivalReview",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassFestivalReview/liveClassFestivalReview.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//直播管理 -- 运维  -- 直播班级列表
			{
				path: "/web/liveBroadcastManagement/liveTheClassList",
				name: "liveTheClassList",
				component: () =>
					import("../views/liveBroadcastManagement/liveTheClassList/liveTheClassList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},

			//直播管理 -- 运维  -- 直播班级列表 - tabs
			{
				path: "/web/liveBroadcastManagement/liveTabs",
				name: "liveTabs",
				component: () =>
					import("../views/liveBroadcastManagement/liveTheClassList/liveTabs.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播班级列表 - 详情
			{
				path: "/web/liveBroadcastManagement/liveDetail",
				name: "liveDetail",
				component: () =>
					import("../views/liveBroadcastManagement/liveTheClassList/liveDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播班级列表 - 学员信息
			{
				path: "/web/liveBroadcastManagement/liveStudentInfo",
				name: "liveStudentInfo",
				component: () =>
					import("../views/liveBroadcastManagement/liveTheClassList/liveStudentInfo.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//直播管理 -- 运维  -- 直播班级列表 - 班级参数
			{
				path: "/web/liveBroadcastManagement/liveClassParameter",
				name: "liveClassParameter",
				component: () =>
					import("../views/liveBroadcastManagement/liveTheClassList/liveClassParameter.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			{
				path: "/web/liveBroadcastManagement/liveClassFestivalDetails",
				name: "liveClassFestivalDetails",
				component: () =>
					import("../views/liveBroadcastManagement/liveClassFestivalReview/liveClassFestivalDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//重构
			//财务结算 --班级结算统计（财务）
			{
				path: "/web/financialSettlement/settlementList",
				name: "settlementList",
				component: () =>
					import("../views/financialSettlement/settlementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算中心 --公示名单管理
			{
				path: "/web/financialSettlement/publicity",
				name: "financialSettlement_publicity_publicity",
				component: () =>
					import("../views/financialSettlement/publicity/publicity.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算中心 --公示名单管理-查看企业名单
			{
				path: "/web/financialSettlement/checkTheList",
				name: "checkTheList",
				component: () =>
					import("../views/financialSettlement/publicity/checkTheList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//数据中心 --班级结算统计（运营）
			{
				path: "/web/financialSettlement/settlementListOperate",
				name: "settlementListOperate",
				component: () =>
					import("../views/financialSettlement/settlementListOperate.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//数据中心 --收款结算查询（运营）
			{
				path: "/web/financialSettlement/settlementPaymentQueries",
				name: "settlementPaymentQueries",
				component: () =>
					import("../views/financialSettlement/settlementPaymentQueries.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --结算列表--详情（财务）
			{
				path: "/web/financialSettlement/settlementDetails",
				name: "settlementDetails",
				component: () =>
					import("../views/financialSettlement/settlementDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务结算 --合同管理
			{
				path: "/web/financialSettlement/contractManagement",
				name: "contractManagement",
				component: () =>
					import("../views/financialSettlement/contractManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --合同管理--新增
			{
				path: "/web/financialSettlement/contractManagementAdd",
				name: "contractManagementAdd",
				component: () =>
					import("../views/financialSettlement/contractManagementAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务结算 --合同管理--查看日志
			{
				path: "/web/financialSettlement/viewLog",
				name: "viewLog",
				component: () =>
					import("../views/financialSettlement/viewLog.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务结算 --协议审批管理--查看日志
			{
				path: "/web/financialSettlement/viewLog2",
				name: "viewLog2",
				component: () =>
					import("../views/financialSettlement/viewLog2.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务结算 --合同管理(查看功能)
			{
				path: "/web/financialSettlement/contractManagementLook",
				name: "contractManagementLook",
				component: () =>
					import("../views/financialSettlement/contractManagementLook.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --合同管理(查看功能详情)
			{
				path: "/web/financialSettlement/contractManagementLookDetail",
				name: "contractManagementLookDetail",
				component: () =>
					import("../views/financialSettlement/contractManagementLookDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},

			//财务结算 --补贴标准
			{
				path: "/web/financialSettlement/SubsidyStandardSetting",
				name: "SubsidyStandardSetting",
				component: () =>
					import("../views/financialSettlement/SubsidyStandardSetting.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 申请结算（运营端）
			{
				path: "/web/financialSettlement/settlement/applySettlementoperation",
				name: "applySettlementoperation",
				component: () =>
					import("../views/financialSettlement/settlement/applySettlementoperation.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 待催收列表（运营端）
			{
				path: "/web/financialSettlement/settlement/waitCollectionList",
				name: "waitCollectionList",
				component: () =>
					import("../views/financialSettlement/settlement/waitCollectionList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 待催审核（运营端）
			{
				path: "/web/financialSettlement/settlement/ywCollectionReviewList",
				name: "ywCollectionReviewList",
				component: () =>
					import("../views/financialSettlement/settlement/ywCollectionReviewList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 催收列表（运营端）
			{
				path: "/web/financialSettlement/settlement/collectionList",
				name: "collectionList",
				component: () =>
					import("../views/financialSettlement/settlement/collectionList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 申请结算（机构端）
			{
				path: "/web/financialSettlement/settlement/applySettlementInstitution",
				name: "applySettlementInstitution",
				component: () =>
					import("../views/financialSettlement/settlement/applySettlementInstitution.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 申请结算（机构代理商）
			{
				path: "/web/financialSettlement/settlement/applySettlementAgency",
				name: "applySettlementAgency",
				component: () =>
					import("../views/financialSettlement/settlement/applySettlementAgency.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//结算管理 - 结算申请核实（运营端）
			{
				path: "/web/financialSettlement/settlement/settlementVerify",
				name: "settlementVerify",
				component: () =>
					import("../views/financialSettlement/settlement/settlementVerify.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 结算单管理
			{
				path: "/web/financialSettlement/settlement/singleAdministration",
				name: "singleAdministration",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministration.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算中心（运营） - 收款结算单 - 优惠优惠列表
			{
				path: "/web/financialSettlement/settlement/discountList",
				name: "financialSettlement_settlement_discountList",
				component: () =>
					import("../views/financialSettlement/settlement/discountList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 医院端-结算列表
			{
				path: "/web/financialSettlement/mechanismOrderList",
				name: "mechanismOrderList",
				component: () =>
					import("../views/financialSettlement/mechanismOrderList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 医院端-退款订单列表
			{
				path: "/web/financialSettlement/refundOrderList",
				name: "refundOrderList",
				component: () =>
					import("../views/financialSettlement/refundOrderList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 待结算列表
			{
				path: "/web/financialSettlement/settlement/toBeSettledList",
				name: "financialSettlement_settlement/toBeSettledList",
				component: () =>
					import("../views/financialSettlement/settlement/toBeSettledList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/financialSettlement/settlement/archivedSettlementList",
				name: "archivedSettlementList",
				component: () =>
					import("../views/financialSettlement/settlement/archivedSettlementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 生成结算单（收款）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstEdit",
				name: "singleAdministrationChildrenFirstEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenFirstEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 生成旧结算单（收款）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstOldEdit",
				name: "singleAdministrationChildrenFirstOldEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenFirstOldEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 生成作废结算单（收款）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenFirstVoidEdit",
				name: "singleAdministrationChildrenFirstVoidEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenFirstVoidEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 生成结算单（付款）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenSecondEdit",
				name: "singleAdministrationChildrenSecondEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenSecondEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 生成结算单（代理商）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenThreeEdit",
				name: "singleAdministrationChildrenThreeEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenThreeEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 生成结算单（代理商--普惠）
			{
				path: "/web/financialSettlement/settlement/singleAdministrationChildrenFourEdit",
				name: "singleAdministrationChildrenFourEdit",
				component: () =>
					import("../views/financialSettlement/settlement/singleAdministrationChildrenFourEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构代理商端 - 特殊优惠申请
			{
				path: "/web/financialSettlement/settlement/specialDiscounts",
				name: "financialSettlement_settlement_specialDiscounts",
				component: () =>
					import("../views/financialSettlement/settlement/specialDiscounts.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 财务结算 --结算单管理（财务）
			{
				path: "/web/financialSettlement/statementManagementFinance",
				name: "statementManagementFinance",
				component: () =>
					import("../views/financialSettlement/statementManagementFinance/statementManagementFinance.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --结算单管理（财务）--复核
			{
				path: "/web/financialSettlement/statementManagementFinanceToReview",
				name: "statementManagementFinanceToReview",
				component: () =>
					import("../views/financialSettlement/statementManagementFinance/statementManagementFinanceToReview.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务结算 --结算单附件
			{
				path: "/web/financialSettlement/attachmentOfStatement",
				name: "attachmentOfStatement",
				component: () =>
					import("../views/financialSettlement/attachmentOfStatement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --结算单列表
			{
				path: "/web/financialSettlement/BillStatementList",
				name: "BillStatementList",
				component: () =>
					import("../views/financialSettlement/BillStatementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务结算 --机构结算统计
			{
				path: "/web/financialSettlement/InstitutionalSettlementStatistics",
				name: "InstitutionalSettlementStatistics",
				component: () =>
					import(
						"../views/financialSettlement/InstitutionalSettlementStatistics"
					),
				meta: {
					state: true,
					level: 1
				}
			},
			// 重构 - 结算管理 - 二次结算单查询
			{
				path: "/web/financialSettlement/twoSettlementQuery",
				name: "financialSettlement_twoSettlementQuery",
				component: () =>
					import("../views/financialSettlement/twoSettlementQuery.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 开票管理
			{
				path: "/web/financialSettlement/invoicingManagement",
				name: "invoicingManagement",
				component: () =>
					import("../views/financialSettlement/invoicingManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 开票管理 - 添加/编辑
			{
				path: "/web/financialSettlement/invoicingManagementAddEdit",
				name: "invoicingManagementAddEdit",
				component: () =>
					import("../views/financialSettlement/invoicingManagementAddEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 重构 - 结算管理 - 开票单位管理
			{
				path: "/web/financialSettlement/InvoicingCompanyManagement",
				name: "InvoicingCompanyManagement",
				component: () =>
					import("../views/financialSettlement/InvoicingCompanyManagement.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 预付款列表（运营）
			{
				path: "/web/financialSettlement/advancePaymentList",
				name: "advancePaymentList",
				component: () =>
					import("../views/financialSettlement/advance/advancePaymentList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 预付款列表（运营） -- 预付款记录
			{
				path: "/web/financialSettlement/advancePaymentRecord",
				name: "advancePaymentRecord",
				component: () =>
					import("../views/financialSettlement/advance/advancePaymentRecord.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 重构 - 结算管理 - 预付款列表（运营） -- 班级详情
			{
				path: "/web/financialSettlement/advancePaymentClassDetails",
				name: "advancePaymentClassDetails",
				component: () =>
					import("../views/financialSettlement/advance/advancePaymentClassDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 重构 - 结算管理 - 预付款列表（财务）
			{
				path: "/web/financialSettlement/advancePaymentFinanceList",
				name: "advancePaymentFinanceList",
				component: () =>
					import("../views/financialSettlement/advance/advancePaymentFinanceList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 重构 - 结算管理 - 预付款列表（财务） -- 确认记录
			{
				path: "/web/financialSettlement/advancePaymentConfirmationRecord",
				name: "advancePaymentConfirmationRecord",
				component: () =>
					import("../views/financialSettlement/advance/advancePaymentConfirmationRecord.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 重构 - 结算管理 - 协议确认（财务）
			{
				path: "/web/financialSettlement/agreementSureList",
				name: "agreementSureList",
				component: () =>
					import("../views/financialSettlement/agreementSureList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务 --合同复核
			{
				path: "/web/financialSettlement/contractReviewList",
				name: "contractReviewList",
				component: () =>
					import("../views/financialSettlement/contractReview/contractReviewList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//财务 --合同复核 -- 财务复核
			{
				path: "/web/financialSettlement/financialReview",
				name: "financialReview",
				component: () =>
					import("../views/financialSettlement/contractReview/financialReview.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//财务 --合同归档
			{
				path: "/web/financialSettlement/contractFiling",
				name: "contractFiling",
				component: () =>
					import("../views/financialSettlement/contractReview/contractFiling.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//数据中心 --企业合同查询
			{
				path: "/web/financialSettlement/settlement/enterpriseContractQuery",
				name: "settlement_enterpriseContractQuery",
				component: () =>
					import("../views/financialSettlement/settlement/enterpriseContractQuery.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//广州对接业务
			{
				path: "/web/guangzhou/guangzhouCourseList",
				name: "guangzhouCourseList",
				component: () =>
					import("../views/guangzhou/guangzhouCourseList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//河北对接业务
			{
				path: "/web/hebei/hebeiCourseList",
				name: "hebeiCourseList",
				component: () =>
					import("../views/hebei/hebeiCourseList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//班级列表（河北）
			{
				path: "/web/hebei/hebeiProjectList",
				name: "hebeiProjectList",
				component: () =>
					import("../views/hebei/hebeiProjectList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//河北已推送
			{
				path: "/web/hebei/hebeiCouseiLstYts",
				name: "hebeiCouseiLstYts",
				component: () =>
					import("../views/hebei/hebeiCouseiLstYts.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//河北已推送 -推送日志
			{
				path: "/web/hebei/hebeiOperation",
				name: "hebeiOperation",
				component: () =>
					import("../views/hebei/hebeiOperation.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//吉林对接业务
			{
				path: "/web/jilin/jilinCourseList",
				name: "jilinCourseList",
				component: () =>
					import("../views/jilin/jilinCourseList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 海南班级列表
			{
				path: "/web/hainan/hainanCourseList",
				name: "hainanCourseList",
				component: () =>
					import("../views/hainan/hainanCourseList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 海南新增课程
			{
				path: "/web/addCourseHainan",
				name: "addCourseHainan",
				component: () =>
					import("../views/classListCom/classDetailsFile/addCourseHainan.vue")
			},
			//广州--数据统计-视频验证统计
			{
				path: "/web/guangzhou/videoVerificationStatistics",
				name: "videoVerificationStatistics",
				component: () =>
					import("../views/guangzhou/videoVerificationStatistics.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 直播参数
			{
				path: "/web/operate/liveBroadcastParametersList",
				name: "operate/liveBroadcastParametersList",
				component: () =>
					import("../views/operate/liveBroadcastParameters/liveBroadcastParametersList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 直播参数 --编辑
			{
				path: "/web/operate/liveBroadcastParametersEdit",
				name: "operate/liveBroadcastParametersEdit",
				component: () =>
					import("../views/operate/liveBroadcastParameters/liveBroadcastParametersEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 工单管理
			{
				path: "/web/workOrder/workOrderList",
				name: "workOrder/workOrderList",
				component: () =>
					import("../views/workOrder/workOrderList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 工单审核 --运营研发
			{
				path: "/web/workOrder/workOrderAudit",
				name: "workOrder/workOrderAudit",
				component: () =>
					import("../views/workOrder/workOrderAudit.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 工单审核 --运营领导
			{
				path: "/web/workOrder/workOrderLeaderAudit",
				name: "workOrder/workOrderLeaderAudit",
				component: () =>
					import("../views/workOrder/workOrderLeaderAudit.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 工单管理--机构
			{
				path: "/web/workOrder/workOrderListJg",
				name: "workOrder/workOrderListJg",
				component: () =>
					import("../views/workOrder/workOrderListJg.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 工单管理 --新增修改
			{
				path: "/web/workOrder/workOrderEdit",
				name: "workOrder/workOrderEdit",
				component: () =>
					import("../views/workOrder/workOrderEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 工单管理 --机构新增修改
			{
				path: "/web/workOrder/workOrderEditJg",
				name: "workOrder/workOrderEditJg",
				component: () =>
					import("../views/workOrder/workOrderEditJg.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 课程评价管理
			{
				path: "/web/workOrder/courseEvaluationList",
				name: "workOrder/courseEvaluationList",
				component: () =>
					import("../views/workOrder/courseEvaluationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 弹幕管理
			{
				path: "/web/workOrder/courseBarrageList",
				name: "workOrder/courseBarrageList",
				component: () =>
					import("../views/workOrder/courseBarrageList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 评论管理
			{
				path: "/web/workOrder/commentManagement",
				name: "workOrder/commentManagement",
				component: () =>
					import("../views/workOrder/commentManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 评论管理 - 详情
			{
				path: "/web/workOrder/commentManagementInfo",
				name: "workOrder/commentManagementInfo",
				component: () =>
					import("../views/workOrder/commentManagementInfo.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 评论管理 - 评论列表
			{
				path: "/web/workOrder/commentManagementList",
				name: "workOrder/commentManagementList",
				component: () =>
					import("../views/workOrder/commentManagementList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 文件盖章 - 列表
			{
				path: "/web/workOrder/documentSeal",
				name: "workOrder/documentSeal",
				component: () =>
					import("../views/workOrder/documentSeal.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 文件盖章 - 循环章列表
			{
				path: "/web/workOrder/circularseal",
				name: "workOrder/circularseal",
				component: () =>
					import("../views/workOrder/circularseal.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 班级提醒
			{
				path: "/web/workOrder/classReminder",
				name: "workOrder/classReminder",
				component: () =>
					import("../views/workOrder/classReminder.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 班级归属管理
			{
				path: "/web/workOrder/classOwnership",
				name: "workOrder/classOwnership",
				component: () =>
					import("../views/workOrder/classOwnership.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 短信验证
			{
				path: "/web/workOrder/smsVerification",
				name: "workOrder/smsVerification",
				component: () =>
					import("../views/workOrder/smsVerification.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 班级价格管理
			{
				path: "/web/workOrder/classPriceManagement",
				name: "workOrder/classPriceManagement",
				component: () =>
					import("../views/workOrder/classPriceManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 订单列表（京训钉）
			{
				path: "/web/workOrder/JxdOrderList",
				name: "workOrder/JxdOrderList",
				component: () =>
					import("../views/workOrder/JxdOrderList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 订单列表（京训钉）-- 详情
			{
				path: "/web/workOrder/JxdOrderListDetail",
				name: "workOrder/JxdOrderListDetail",
				component: () =>
					import("../views/workOrder/JxdOrderListDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 -- 订单退款列表（京训钉）
			{
				path: "/web/workOrder/JxdRefundList",
				name: "workOrder/JxdRefundList",
				component: () =>
					import("../views/workOrder/JxdRefundList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 -- 订单退款列表（京训钉）-- 详情
			{
				path: "/web/workOrder/JxdRefundListDetail",
				name: "workOrder/JxdRefundListDetail",
				component: () =>
					import("../views/workOrder/JxdRefundListDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 --答题红包活动列表
			{
				path: "/web/workOrder/activeConfigurationList",
				name: "workOrder/activeConfigurationList",
				component: () =>
					import("../views/workOrder/activeConfigurationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 --答题红包活动列表-- 详情
			{
				path: "/web/workOrder/activeConfigurationAdd",
				name: "workOrder/activeConfigurationAdd",
				component: () =>
					import("../views/workOrder/activeConfigurationAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 --答题红包活动列表-- 查看领取情况
			{
				path: "/web/workOrder/activeCheckTheCollection",
				name: "workOrder/activeCheckTheCollection",
				component: () =>
					import("../views/workOrder/activeCheckTheCollection.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 --赠送题库精品课
			{
				path: "/web/workOrder/giveQuestionBankCourse",
				name: "workOrder/giveQuestionBankCourse",
				component: () =>
					import("../views/workOrder/giveQuestionBankCourse.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 --资料管理中心
			{
				path: "/web/workOrder/dataManagementCenter",
				name: "workOrder/dataManagementCenter",
				component: () =>
					import("../views/workOrder/dataManagementCenter.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 --客户中心
			{
				path: "/web/workOrder/customerCenter",
				name: "workOrder/customerCenter",
				component: () =>
					import("../views/workOrder/customerCenter.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营工具 --招工采集H5
			{
				path: "/web/workOrder/recruitmentCollectionH5",
				name: "workOrder/recruitmentCollectionH5",
				component: () =>
					import("../views/workOrder/recruitmentCollectionH5.vue"),
				meta: {
					state: true,
					level: 2,
				},
			},
			// 运营工具 --招工采集H5新增编辑
			{
				path: "/web/workOrder/createRecruitmentCollectionH5",
				name: "createRecruitmentCollectionH5",
				component: () => import("../views/workOrder/createRecruitmentCollectionH5"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 - 数据点击统计
			{
				path: "/web/workOrder/dataClickStatistics",
				name: "dataClickStatistics",
				component: () => import("../views/workOrder/dataClickStatistics"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营工具 - 标签内容管理
			{
				path: "/web/workOrder/labelContentList",
				name: "labelContentList",
				component: () => import("../views/workOrder/labelContentList"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 运营工具 - 广告内容配置
			{
				path: "/web/workOrder/adInfoConfig",
				name: "adInfoConfig",
				component: () => import("../views/workOrder/adInfoConfig.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 运营工具 - 广告内容配置 - 新增编辑
			{
				path: "/web/workOrder/adInfoUpdate",
				name: "adInfoUpdate",
				component: () => import("../views/workOrder/adInfoUpdate.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营工具 - 优秀企业推荐
			{
				path: "/web/workOrder/excellentEnterpriserEcommend",
				name: "workOrder_excellentEnterpriserEcommend",
				component: () => import("../views/workOrder/excellentEnterpriserEcommend.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营工具 - 优秀企业推荐 - 详情
			{
				path: "/web/workOrder/excellentEnterpriserEcommendInfo",
				name: "workOrder_excellentEnterpriserEcommendInfo",
				component: () => import("../views/workOrder/excellentEnterpriserEcommendInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营工具 - 实习岗位管理
			{
				path: "/web/workOrder/internshipPositionManagement",
				name: "workOrder_internshipPositionManagement",
				component: () => import("../views/workOrder/internshipPositionManagement.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营工具 - 实习岗位管理 - 详情
			{
				path: "/web/workOrder/internshipPositionManagementInfo",
				name: "workOrder_internshipPositionManagementInfo",
				component: () => import("../views/workOrder/internshipPositionManagementInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 运营工具 - 职前必学课程
			{
				path: "/web/workOrder/compulsoryCourses",
				name: "compulsoryCourses",
				component: () => import("../views/workOrder/compulsoryCourses.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营工具 - 线上考试统计
			{
				path: "/web/workOrder/onlineExamStatistics",
				name: "workOrder_onlineExamStatistics",
				component: () => import("../views/workOrder/onlineExamStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 运营工具 - 线上考试统计 - 查看学员
			{
				path: "/web/workOrder/onlineExamStatistics_seeStudent",
				name: "workOrder_onlineExamStatistics_seeStudent",
				component: () => import("../views/workOrder/onlineExamStatistics_seeStudent.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			//  自营小程序相关
			//  课程分类
			{
				path: "/web/mini/courseType",
				name: "mini/courseType",
				component: () => import("../views/miniApp/course/courseType.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 店铺装修
			{
				path: "/web/shop/decorate",
				name: "shop/decorate",
				component: () => import("../views/miniApp/shopManagement/shopDecorate.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 店铺信息
			{
				path: "/web/shop/Info",
				name: "shop/Info",
				component: () => import("../views/miniApp/shopManagement/shopInfo.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  备考资料
			{
				path: "/web/miniApp/testDataList",
				name: "testDataList",
				component: () => import("../views/miniApp/studyTools/testDataList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  备考资料 -编辑 新增
			{
				path: "/web/miniApp/testDataAdd",
				name: "testDataAdd",
				component: () => import("../views/miniApp/studyTools/testDataAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  积分管理（小程序）
			{
				path: "/web/miniApp/pointsMall/PointsSet",
				name: "miniApp/pointsMall/PointsSet",
				component: () => import("../views/miniApp/PointsMall/PointsSet.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  用户管理（小程序）
			// 用户列表
			{
				path: "/web/miniApp/userList",
				name: "miniUserList",
				component: () => import("../views/miniApp/userManagement/userList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  老师管理（小程序）
			// 老师管理列表
			{
				path: "/web/miniApp/teacherList",
				name: "teacherList",
				component: () => import("../views/miniApp/teacherManagement/teacherList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/miniApp/teacherEdit",
				name: "teacherEdit",
				component: () => import("../views/miniApp/teacherManagement/teacherEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  我的资源（小程序）
			// 我的课程列表
			{
				path: "/web/miniApp/myResourcesList",
				name: "myResourcesList",
				component: () => import("../views/miniApp/myResources/myResourcesList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/miniApp/createMiniCouerse",
				name: "createMiniCouerse",
				component: () => import("../views/miniApp/myResources/createMiniCouerse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//学员列表
			{
				path: "/web/miniApp/myResourceStudentList",
				name: "myResourceStudentList",
				component: () => import("../views/miniApp/myResources/myResourceStudentList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			//  精品课程资源（小程序）
			// 精品课程列表
			{
				path: "/web/miniApp/excellentCourses",
				name: "excellentCourses",
				component: () => import("../views/miniApp/excellentCourses/excellentCourses.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			{
				path: "/web/miniApp/createplatformCouerse",
				name: "createplatformCouerse",
				component: () => import("../views/miniApp/excellentCourses/createplatformCouerse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 精品课程推荐
			{
				path: "/web/miniApp/excellentCoursesRecommend",
				name: "excellentCoursesRecommend",
				component: () => import("../views/miniApp/excellentCourses/excellentCoursesRecommend.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// {
			// 	path: "/web/miniApp/createMiniCouerse",
			// 	name: "createMiniCouerse",
			// 	component: () => import("../views/miniApp/myResources/createMiniCouerse.vue"),
			// 	meta: {
			// 		state: false,
			// 		level: 2,
			// 	},
			// },
			// 平台课程列表（小程序）
			{
				path: "/web/miniApp/platformList",
				name: "platformList",
				component: () => import("../views/miniApp/myResources/platformList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},

			// 报名咨询列表（小程序）
			{
				path: "/web/miniApp/registrationlist",
				name: "registrationlist",
				component: () => import("../views/miniApp/registrationlist/registrationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 课程报名列表（小程序）
			{
				path: "/web/miniApp/miniappcourseapplylist",
				name: "miniappcourseapplylist",
				component: () => import("../views/miniApp/registrationlist/miniappcourseapplylist.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 机构端 - 专题报名
			{
				path: "/web/miniApp/miniappspecialsubjectlist",
				name: "miniappspecialsubjectlist",
				component: () => import("../views/miniApp/registrationlist/miniappspecialsubjectlist.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 报名咨询（小程序）
			{
				path: "/web/miniApp/registrationConsultationList",
				name: "registrationConsultationList",
				component: () => import("../views/miniApp/registrationConsultation/registrationConsultationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 报名咨询（小程序）
			{
				path: "/web/miniApp/registrationConsultationEdit",
				name: "registrationConsultationEdit",
				component: () => import("../views/miniApp/registrationConsultation/registrationConsultationEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销管理
			// 分销商列表
			{
				path: "/web/miniApp/distributorList",
				name: "distributorList",
				component: () => import("../views/miniApp/retailManagement/distributorList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销用户订单
			{
				path: "/web/miniApp/retailOrderList",
				name: "retailOrderList",
				component: () => import("../views/miniApp/retailManagement/retailOrderList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销用机构订单
			{
				path: "/web/miniApp/distributorOrders",
				name: "distributorOrders",
				component: () => import("../views/miniApp/retailManagement/distributorOrders.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销提现列表
			{
				path: "/web/miniApp/WithdrawalList",
				name: "WithdrawalList",
				component: () => import("../views/miniApp/retailManagement/WithdrawalList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销设置
			{
				path: "/web/miniApp/retailSet",
				name: "retailSet",
				component: () => import("../views/miniApp/retailManagement/retailSet.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 答疑
			{
				path: "/web/miniApp/answerQuestion",
				name: "answerQuestion",
				component: () => import("../views/miniApp/studyTools/answerQuestion.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 分销商城
			{
				path: "/web/miniApp/mallDistribution",
				name: "mallDistribution",
				component: () => import("../views/miniApp/retailManagement/mallDistribution.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构 - 分销管理 - 分销商城
			{
				path: "/web/jgDistribution/distributionShoppingMall",
				name: "distributionShoppingMall",
				component: () => import("../views/jgDistribution/distributionShoppingMall.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构 - 分销管理 - 我的分销
			{
				path: "/web/jgDistribution/myDistribution",
				name: "myDistribution",
				component: () => import("../views/jgDistribution/myDistribution.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构 - 分销管理 - 我的分销 - 用户列表
			{
				path: "/web/jgDistribution/myDistribution_1_detail",
				name: "myDistribution_1_detail",
				component: () => import("../views/jgDistribution/myDistribution_1_detail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构 - 分销管理 - 我的分销 - 查看卡密
			{
				path: "/web/jgDistribution/carmichaelInfo",
				name: "carmichaelInfo",
				component: () => import("../views/jgDistribution/carmichaelInfo.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营 - 分销管理 - 分销机构订单
			{
				path: "/web/jgDistribution/paySuccess",
				name: "paySuccess",
				component: () => import("../views/jgDistribution/paySuccess.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营 - 分销管理 - 个人分销提现
			{
				path: "/web/personalDistributionWithdrawal",
				name: "web_personalDistributionWithdrawal",
				component: () => import("../views/miniApp/retailManagement/personalDistributionWithdrawal.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 运营 - 分销管理 - 个人分销设置
			{
				path: "/web/personalDistributionSetup",
				name: "web_personalDistributionSetup",
				component: () => import("../views/miniApp/retailManagement/personalDistributionSetup.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 小程序 - 预览课程
			{
				path: "/web/miniApp/previewCourse",
				name: "miniAppPreviewCourse",
				component: () => import("../views/miniApp/previewCourse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 小程序 - 申请列表
			{
				path: "/web/miniApp/apply/list",
				name: "miniAppApplyList",
				component: () => import("../views/miniApp/apply/list.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// //  职场充电 资讯列表
			// {
			// 	path: "/web/Workplace/newsList",
			// 	name: "Workplace/newsList",
			// 	component: () => import("../views/miniApp/Workplace/news.vue"),
			// 	meta: {
			// 		state: false,
			// 		level: 2,
			// 	},
			// },
			// // //  职场充电 资讯列表 新增
			// {
			// 	path: "/web/Workplace/newsAdd",
			// 	name: "Workplace/newsAdd",
			// 	component: () => import("../views/miniApp/Workplace/newsAdd.vue"),
			// 	meta: {
			// 		state: false,
			// 		level: 2,
			// 	},
			// },
			/* 山西监管端 */
			// 山西监管首页
			{
				path: "/web/shanxiSupervisionEnd/shanxiSupervisionHome",
				name: "shanxiSupervisionEnd/shanxiSupervisionHome",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisionHome/shanxiSupervisionHome.vue"),
				meta: {
					state: false,
					// level: 1
				},
			},
			//山西监管数据统计 -- 机构统计（代理商统计）
			{
				path: "/web/shanxiSupervisionEnd/InstitutionalStatistics",
				name: "shanxiSupervisionEnd/shanxiSupervisonInstitutionalStatistics",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiSupervisonInstitutionalStatistics.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			//山西监管数据统计 -- 学员统计
			{
				path: "/web/shanxiSupervisionEnd/StudentlStatistics",
				name: "shanxiSupervisionEnd/shanxiSupervisonStudentlStatistics",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiSupervisonStudentlStatistics.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			//山西监管数据统计 -- 学员统计
			{
				path: "/web/shanxiSupervisionEnd/StudentLearnRecord",
				name: "shanxiSupervisionEnd/shanxiSupervisonStudentLearnRecord",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiSupervisonStudentLearnRecord.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//山西监管数据统计 -- 培训信息监察
			{
				path: "/web/shanxiSupervisionEnd/shanxiTrainingInformationSupervision",
				name: "shanxiSupervisionEnd/shanxiTrainingInformationSupervision",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervision.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			//山西监管数据统计 -- 培训信息监察 --详情
			{
				path: "/web/shanxiSupervisionEnd/shanxiTrainingInformationSupervisionTabBox",
				name: "shanxiSupervisionEnd/shanxiTrainingInformationSupervisionTabBox",
				component: () => import("../views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingInformationSupervisionTabBox.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//档案设置
			{
				path: "/web/operate/fileSetupList",
				name: "fileSetup_fileSetupList",
				component: () => import("../views/operate/fileSetup/fileSetupList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			//档案设置 -- 新增&&编辑
			{
				path: "/web/operate/fileSetupEdit",
				name: "fileSetup_fileSetupEdit",
				component: () => import("../views/operate/fileSetup/fileSetupEdit.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 运营 - 机构列表
			{
				path: "/web/miniApp/mechanismList",
				name: "miniApp/mechanismList",
				component: () => import("../views/miniApp/mechanism/mechanismList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 小程序 - 运营 - APP报名列表
			{
				path: "/web/miniApp/appCourseApplyList",
				name: "miniApp/appCourseApplyList",
				component: () => import("../views/miniApp/mechanism/appCourseApplyList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 小程序 - 运营 - 课程审核
			{
				path: "/web/miniApp/curriculumToExamine",
				name: "miniApp/curriculumToExamine",
				component: () => import("../views/miniApp/mechanism/curriculumToExamine.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			{
				path: "/web/miniApp/registrationConsultationChDetail",
				name: "miniApp/registrationConsultationChDetail",
				component: () => import("../views/miniApp/mechanism/registrationConsultationChDetail.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//机构预览限制列表
			{
				path: "/web/operate/limitCompList",
				name: "limitCompConfig",
				component: () => import("../views/operate/limitCompany/limitCompConfig.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//小程序 - 学员管理题库(小程序)
			{
				path: "/web/miniApp/studentQuestion",
				name: "studentQuestion",
				component: () => import("../views/miniApp/studentQuestion/studentQuestionList.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//小程序 - 学员管理题库(小程序) - 详情
			{
				path: "/web/miniApp/studentQuestionListInfo",
				name: "studentQuestionListInfo",
				component: () => import("../views/miniApp/studentQuestion/studentQuestionListInfo.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//小程序 - 学员管理题库(小程序) - 详情
			{
				path: "/web/miniApp/studentQuestionLisWrongTopic",
				name: "studentQuestionLisWrongTopic",
				component: () => import("../views/miniApp/studentQuestion/studentQuestionLisWrongTopic.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			//小程序 - 直播 - 主播成员管理列表
			{
				path: "/web/miniApp/liveTeacherManager",
				name: "memberManager",
				component: () => import("../views/miniApp/liveManager/liveTeacherManager.vue"),
				meta: {
					state: false,
					level: 1
				},
			},

			// 小程序 - 运营 - 直播间列表
			{
				path: "/web/miniApp/livelist",
				name: "miniApp/liveList",
				component: () => import("../views/miniApp/liveManager/liveList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			//小程序 - 直播 - 直播间列表 --观看列表
			{
				path: "/web/miniApp/liveRoomManager",
				name: "liveRoomManager",
				component: () => import("../views/miniApp/liveManager/liveRoomManager.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 小程序 - 运营 - 直播审核
			{
				path: "/web/miniApp/liveReview",
				name: "miniApp/liveReview",
				component: () => import("../views/miniApp/liveReview/liveReview.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 直播审核-详情
			{
				path: "/web/miniApp/liveReviewDetails",
				name: "miniApp/liveReviewDetails",
				component: () => import("../views/miniApp/liveReview/liveReviewDetails.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 运营 - 题库管理
			{
				path: "/web/miniApp/thematicList",
				name: "miniApp/thematicList",
				component: () => import("../views/miniApp/thematicManagement/thematicList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 题库管理 --新增（编辑）
			{
				path: "/web/miniApp/thematicEdit",
				name: "miniApp/thematicEdit",
				component: () => import("../views/miniApp/thematicManagement/thematicEdit.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 机构 - 专题管理
			{
				path: "/web/miniApp/thematicListJg",
				name: "miniApp/thematicListJg",
				component: () => import("../views/miniApp/thematicManagement/thematicListJg.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 机构 - 专题管理 --新增（编辑）
			{
				path: "/web/miniApp/thematicEditJg",
				name: "miniApp/thematicEditJg",
				component: () => import("../views/miniApp/thematicManagement/thematicEditJg.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 机构 - 专题报名列表
			{
				path: "/web/miniApp/specialSubjectRegistrationBox",
				name: "miniApp/specialSubjectRegistrationBox",
				component: () => import("../views/miniApp/thematicManagement/specialSubjectRegistrationBox.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 个人专栏
			{
				path: "/web/miniApp/thematicGrList",
				name: "miniApp/thematicGrList",
				component: () => import("../views/miniApp/thematicManagement/thematicGrList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 小程序 - 运营 - 个人专栏 --新增（编辑）
			{
				path: "/web/miniApp/thematicGrEdit",
				name: "miniApp/thematicGrEdit",
				component: () => import("../views/miniApp/thematicManagement/thematicGrEdit.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 运营 - 类别管理
			{
				path: "/web/miniApp/categoryManage",
				name: "miniApp/categoryManage",
				component: () => import("../views/miniApp/categoryManagement/category.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 工种类别设置
			{
				path: "/web/miniApp/categorySetting",
				name: "miniApp/categorySetting",
				component: () => import("../views/miniApp/categoryManagement/categorySetting.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 工种类别设置(内页)
			{
				path: "/web/miniApp/categorySettingEdit",
				name: "miniApp/categorySettingEdit",
				component: () => import("../views/miniApp/categoryManagement/categorySettingEdit.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 小程序 - 运营 - 大纲管理
			{
				path: "/web/miniApp/outlineManage",
				name: "miniApp/outlineManage",
				component: () => import("../views/miniApp/outlineManage/outlineManage.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 - 试题管理
			{
				path: "/web/miniApp/questionManage",
				name: "miniApp/questionManage",
				component: () => import("../views/miniApp/questionManage/questionManage.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 小程序 - 运营 招生管理（小程序）
			{
				path: "/web/miniApp/enrollmentManagementList",
				name: "enrollmentManagementList",
				component: () => import("../views/miniApp/enrollmentManagement/enrollmentManagementList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 小程序 - 运营 招生管理（小程序）
			{
				path: "/web/miniApp/enrollmentManagementAdd",
				name: "enrollmentManagementAdd",
				component: () => import("../views/miniApp/enrollmentManagement/enrollmentManagementAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			/* 鉴定机构路由管理 */
			// 鉴定机构 - 证书管理 - 外部证书管理
			{
				path: "/web/certificateManage/outCertificateList",
				name: "certificateManage/outCertificateList",
				component: () => import("../views/certificateManage/outCertificateList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 鉴定机构 - 证书管理 - 发证机构管理
			{
				path: "/web/certificateManage/issuingAuthorityList",
				name: "certificateManage/issuingAuthorityList",
				component: () => import("../views/certificateManage/issuingAuthorityList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 证书管理 - 发证机构管理 - 新增编辑
			{
				path: "/web/certificateManage/issuingAuthorityListAddEdit",
				name: "certificateManage_issuingAuthorityListAddEdit",
				component: () => import("../views/certificateManage/issuingAuthorityListAddEdit.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 证书管理 - 制证任务管理
			{
				path: "/web/certificateManage/voucherPreparationTaskList",
				name: "certificateManage/voucherPreparationTaskList",
				component: () => import("../views/certificateManage/voucherPreparationTaskList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 证书管理 - 制证任务管理 - 学员管理
			{
				path: "/web/certificateManage/certificateIssuingStudentList",
				name: "certificateManage/certificateIssuingStudentList",
				component: () => import("../views/certificateManage/certificateIssuingStudentList.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 证书管理 - 证书预警处理
			{
				path: "/web/certificateManage/certificateAlert",
				name: "certificateManage_certificateAlert",
				component: () => import("../views/certificateManage/certificateAlert.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 - 考试列表
			{
				path: "/web/appraisalInstitution/examList",
				name: "appraisalInstitution/examList",
				component: () => import("../views/appraisalInstitution/exam/examList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 - 新增编辑考试
			{
				path: "/web/appraisalInstitution/stuExam",
				name: "appraisalInstitution/stuExam",
				component: () => import("../views/appraisalInstitution/exam/stuExam.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 专属技能鉴定报名码
			{
				path: "/web/QRcode",
				name: "QRcode",
				component: () => import("../views/appraisalInstitution/QRcode/index.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 机构信息
			{
				path: "/web/appraisalInstitution/InstitutionalInformation",
				name: "appraisalInstitution/InstitutionalInformation",
				component: () => import("../views/appraisalInstitution/InstitutionalInformation/InstitutionalInformation.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 题库管理
			{
				path: "/web/appraisalInstitution/appraisalQuestionBankList",
				name: "appraisalInstitution/appraisalQuestionBankList",
				component: () => import("../views/appraisalInstitution/AppraisalQuestionBank/appraisalQuestionBankList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 题库管理 - 新增（编辑）
			{
				path: "/web/appraisalInstitution/appraisalQuestionBankAdd",
				name: "appraisalInstitution/appraisalQuestionBankAdd",
				component: () => import("../views/appraisalInstitution/AppraisalQuestionBank/appraisalQuestionBankAdd.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 题库试卷管理
			{
				path: "/web/appraisalInstitution/questionBankExamPaperList",
				name: "questionBankExamPaperList",
				component: () => import("../views/appraisalInstitution/AppraisalQuestionBank/questionBankExamPaperList.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 鉴定机构 - 考试计划
			{
				path: "/web/appraisalInstitution/AppraisalExaminationPlanList",
				name: "appraisalInstitution/AppraisalExaminationPlanList",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/AppraisalExaminationPlanList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考试计划
			{
				path: "/web/appraisalInstitution/appraisalExaminationBox",
				name: "appraisalInstitution/appraisalExaminationBox",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisaExaminationBox.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考试计划
			{
				path: "/web/appraisalInstitution/appraisalNoBindCourseList",
				name: "appraisalInstitution/appraisalNoBindCourseList",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisalNoBindCourseList.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考试计划 --未绑定题库列表
			{
				path: "/web/appraisalInstitution/appraisalNoBindQuestionBankList",
				name: "appraisalInstitution/appraisalNoBindQuestionBankList",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisalNoBindQuestionBankList.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考试计划 --未绑定题库列表 --详情
			{
				path: "/web/appraisalInstitution/appraisaQuestionBankDetail",
				name: "appraisalInstitution/appraisaQuestionBankDetail",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisaQuestionBankDetail.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考试计划 --课程详情
			{
				path: "/web/appraisalInstitution/appraisalCourseDetail",
				name: "appraisalInstitution/appraisalCourseDetail",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisalCourseDetail.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考试计划 --添加学员
			{
				path: "/web/appraisalInstitution/appraisalAddStudent",
				name: "appraisalInstitution/appraisalAddStudent",
				component: () => import("../views/appraisalInstitution/AppraisalExamination/appraisalAddStudent.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 评价工种
			{
				path: "/web/appraisalInstitution/evaluationWork",
				name: "appraisalInstitution/evaluationWork",
				component: () => import("../views/appraisalInstitution/evaluationWork/evaluationWork.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务安排
			{
				path: "/web/appraisalInstitution/examinationArrangement",
				name: "appraisalInstitution/examinationArrangement",
				component: () => import("../views/appraisalInstitution/examinationArrangement/examinationArrangement.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务安排
			{
				path: "/web/appraisalInstitution/examinationArrangementAdd",
				name: "appraisalInstitution/examinationArrangementAdd",
				component: () => import("../views/appraisalInstitution/examinationArrangement/examinationArrangementAdd.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 考试中心端 - 考试监管
			{
				path: "/web/appraisalInstitution/examinationSupervision",
				name: "appraisalInstitution/examinationSupervision",
				component: () => import("../views/appraisalInstitution/ExaminationSupervision/examinationSupervision.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考试监管
			{
				path: "/web/appraisalInstitution/examinationAuthenticate",
				name: "appraisalInstitution/examinationAuthenticate",
				component: () => import("../views/appraisalInstitution/ExaminationSupervision/examinationAuthenticate.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 试卷列表
			{
				path: "/web/appraisalInstitution/testPaperList",
				name: "appraisalInstitution_testPaperList_testPaperList",
				component: () => import("../views/appraisalInstitution/testPaperList/testPaperList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 鉴定机构 - 试卷列表 - 新增试卷
			{
				path: "/web/appraisalInstitution/addTestPaper",
				name: "appraisalInstitution_testPaperList_addTestPaper",
				component: () => import("../views/appraisalInstitution/testPaperList/addTestPaper.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 鉴定机构 - 试卷列表 - 查看试卷
			{
				path: "/web/appraisalInstitution/testPaperEdit",
				name: "appraisalInstitution_testPaperList_testPaperEdit",
				component: () => import("../views/appraisalInstitution/testPaperList/testPaperEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 鉴定机构 - 老师管理
			{
				path: "/web/appraisalInstitution/teacherManage",
				name: "appraisalInstitution/teacherManage",
				component: () => import("../views/appraisalInstitution/teacherManage/teacherManage.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 -  考试材料下载
			{
				path: "/web/appraisalInstitution/testDataDownload/testDataDownloadList",
				name: "testDataDownloadList",
				component: () => import("../views/appraisalInstitution/testDataDownload/testDataDownloadList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 -  学员考场查询
			{
				path: "/web/appraisalInstitution/studentExaminationRoomQuery/studentExaminationRoomQueryList",
				name: "studentExaminationRoomQueryList",
				component: () => import("../views/appraisalInstitution/studentExaminationRoomQuery/studentExaminationRoomQueryList.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 - 考试管理 - 选择学员
			{
				path: "/web/appraisalInstitution/exam/chooseStudentList",
				name: "chooseStudentList",
				component: () => import("../views/appraisalInstitution/exam/chooseStudentList.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考务管理 - 考场安排
			{
				path: "/web/appraisalInstitution/examinationRoomArrangement/arrange",
				name: "examinationRoomArrangement_arrange",
				component: () => import("../views/appraisalInstitution//examinationRoomArrangement/arrange.vue"),
				meta: {
					state: true,
					level: 1
				},
			},
			// 鉴定机构 - 考务管理 - 考场安排 - 手动分配考场
			{
				path: "/web/appraisalInstitution/examinationRoomArrangement/manualAssignmentExaminationRoom",
				name: "examinationRoomArrangement_manualAssignmentExaminationRoom",
				component: () => import("../views/appraisalInstitution//examinationRoomArrangement/manualAssignmentExaminationRoom.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 鉴定机构 - 考务管理 - 考场安排 - 手动分配考场 - 新增编辑
			{
				path: "/web/appraisalInstitution/examinationRoomArrangement/manualAssignmentUpdate",
				name: "examinationRoomArrangement_manualAssignmentUpdate",
				component: () => import("../views/appraisalInstitution//examinationRoomArrangement/manualAssignmentUpdate.vue"),
				meta: {
					state: false,
					level: 2
				},
			},

			// 鉴定机构 - 考务管理 - 查看考场
			{
				path: "/web/appraisalInstitution/examinationRoomArrangement/seeExaminationRoom",
				name: "examinationRoomArrangement_seeExaminationRoom",
				component: () => import("../views/appraisalInstitution/examinationRoomArrangement/seeExaminationRoom.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 运维 - 模板 - 模板列表
			{
				path: "/web/yw_visualization_list",
				name: "yw_visualization_list",
				component: () => import("../views/visualization/yw_visualization_listt.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 运维 - 模板 - 机构使用模板统计
			{
				path: "/web/yw_visualization_useStatistics",
				name: "yw_visualization_useStatistics",
				component: () => import("../views/visualization/yw_visualization_useStatistics.vue"),
				meta: {
					state: false,
					level: 1
				},
			},
			// 运维 - 模板 - 使用模板统计
			{
				path: "/web/yw_visualization_rateStatistics",
				name: "yw_visualization_rateStatistics",
				component: () => import("../views/visualization/yw_visualization_rateStatistics.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 机构 - 模板 - 模板列表
			{
				path: "/web/jg_visualization_rateStatistics",
				name: "jg_visualization_rateStatistics",
				component: () => import("../views/visualization/jg_visualization_rateStatistics.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 机构 - 模板 - 我的模板
			{
				path: "/web/jg_visualization_myStatistics",
				name: "jg_visualization_myStatistics",
				component: () => import("../views/visualization/jg_visualization_myStatistics.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			// 机构 - 模板 - 已收藏模板
			{
				path: "/web/jg_visualization_collectionStatistics",
				name: "jg_visualization_collectionStatistics",
				component: () => import("../views/visualization/jg_visualization_collectionStatistics.vue"),
				meta: {
					state: false,
					level: 2
				},
			},
			/* 题库管理 */
			// 题库列表
			{
				path: "/web/Questionbank/questionbankList",
				name: "Questionbank_questionbankList",
				component: () => import("../views/Questionbank/questionbankList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 查看视频记录
			{
				path: "/web/classListCom/classDetailsFile/videoInfo",
				name: "videoInfo",
				component: () => import("../views/classListCom/classDetailsFile/videoInfo.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 查看点名记录
			{
				path: "/web/classListCom/classDetailsFile/rollCall",
				name: "rollCall",
				component: () => import("../views/classListCom/classDetailsFile/rollCall.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 监管 - 数据统计 - 点名认证统计
			{
				path: "/web/operate/superviseRcas/superviseRcasList",
				name: "superviseRcasList",
				component: () => import("../views/operate/superviseRcas/superviseRcasList.vue"),
				meta: {
					state: true,
				}
			},
			// 机构 - 我的资源 - 我的二维码
			{
				path: "/web/resourse/mechanismQRcode",
				name: "mechanismQRcode",
				component: () => import("../views/resourse/mechanismQRcode.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 班级管理-快速开班
			{
				path: "/web/quickAddClassCtr",
				name: "quickAddClassCtr",
				component: () => import("../views/classListCom/quickAddClassCtr.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			{
				path: "/web/quickAddCourseBox",
				name: "quickAddCourseBox",
				component: () =>
					import("../views/classListCom/classDetailsFile/quickAddCourseBox.vue")
			},
			// 北京晟融 -- 班级列表
			{
				path: "/web/classListBj",
				name: "classListBj",
				component: () => import("../views/BjSunreal/classListBj.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 北京晟融 -- 班级列表 -- 新增班级
			{
				path: "/web/addClassBj",
				name: "addClassBj",
				component: () => import("../views/BjSunreal/addClassBj.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 北京晟融 -- 班级列表 --详情
			{
				path: "/web/ClassdetailsListBj",
				name: "ClassdetailsListBj",
				component: () => import("../views/BjSunreal/classdetailsListBj.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 北京晟融 -- 班级管理 - 新增课程
			{
				path: "/web/addCourseBoxBj",
				name: "addCourseBoxBj",
				component: () =>
					import("../views/BjSunreal/addCourseBoxBj.vue")
			},
			// 北京晟融 -- 培训管理 - 订单管理
			{
				path: "/web/BjSunreal/orderManagement",
				name: "BjSunreal_orderManagement",
				component: () =>
					import("../views/BjSunreal/orderManagement.vue")
			},
			// 北京晟融 -- 培训管理 - 退款审核列表
			{
				path: "/web/BjSunreal/refunReviewList",
				name: "BjSunreal_refunReviewList",
				component: () =>
					import("../views/BjSunreal/refunReviewList.vue")
			},
			// 医院端 -- 订单管理 -- 我购买的课程
			{
				path: "/web/coursesIbought",
				name: "coursesIbought",
				component: () => import("../views/hospital/coursesIbought.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 我购买的课程 -- 课程详情
			{
				path: "/web/coursesIboughtInfo",
				name: "coursesIboughtInfo",
				component: () => import("../views/hospital/coursesIboughtInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 医院端 -- 订单管理 -- 我购买的课程 -- 学员列表
			{
				path: "/web/studentList",
				name: "studentList",
				component: () => import("../views/hospital/studentList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 医院端 -- 订单管理 -- 我购买的课程 -- 学员列表 - 学习记录
			{
				path: "/web/studentList/Learningrecords",
				name: "studentList/Learningrecords",
				component: () => import("../views/hospital/learningrecords/Learningrecords.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 课程中心
			{
				path: "/web/courseCenter",
				name: "/web/courseCenter",
				component: () => import("../views/hospital/courseCenter.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 课程中心 - 详情
			{
				path: "/web/courseCenterInfo",
				name: "/web/courseCenterInfo",
				component: () => import("../views/hospital/courseCenterInfo.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 课程中心 - 详情 - 购买成功
			{
				path: "/web/courseCenterInfo/paySuccess",
				name: "/web/courseCenterInfo/paySuccess",
				component: () => import("../views/hospital/paySuccess.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 订单列表
			{
				path: "/web/courseCenterInfo/OrderList",
				name: "/web/courseCenterInfo/OrderLlist",
				component: () => import("../views/hospital/OrderList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 退款申请列表
			{
				path: "/web/courseCenterInfo/refundApplicationList",
				name: "courseCenterInfo_refundApplicationList",
				component: () => import("../views/hospital/refundApplicationList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 医院端 -- 订单管理 -- 开票管理列表
			{
				path: "/web/invoicingManagementList",
				name: "/web/invoicingManagementList",
				component: () => import("../views/hospital/invoicingManagementList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},

			// 医院端 -- 订单管理 -- 考试信息
			{
				path: "/web/examinationInformation",
				name: "examinationInformation",
				component: () => import("../views/hospital/examinationInformation.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 医院端 -- 机构信息
			{
				path: "/web/hospital/InstitutionalInformation",
				name: "hospital/InstitutionalInformation",
				component: () => import("../views/hospital/InstitutionalInformation.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 临时包 -- 监管 - 数据统计 - 班级培训统计
			{
				path: "/web/temporary/ClassTrainingStatistics",
				name: "temporary/ClassTrainingStatistics",
				component: () => import("../views/temporary/ClassTrainingStatistics.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 机构端 - 首页
			{
				path: "/web/home_3",
				name: "home_3",
				component: () => import("../views/commenPages/home_3.vue")
			},
			// 机构端 - 培训答疑
			{
				path: "/web/trainingQusetionAnswer",
				name: "trainingQusetionAnswer",
				component: () => import("../views/classListCom/trainingQusetionAnswer.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			{
				path: "/web/trainingQusetionAnswerInfo",
				name: "trainingQusetionAnswerInfo",
				component: () => import("../views/classListCom/trainingQusetionAnswerInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 安知课堂 - 证书管理 - 证书简介
			{
				path: "/web/certificateIntroductionList",
				name: "certificateIntroductionList",
				component: () => import("../views/miniApp/certificate/certificateIntroductionList.vue")
			},
			// 安知课堂 - 证书管理证书列表
			{
				path: "/web/certificateList",
				name: "certificateList",
				component: () => import("../views/miniApp/certificate/certificateList.vue")
			},
			// 安知课堂 - 证书管理证书 --学员列表
			{
				path: "/web/certificateStudentList",
				name: "certificateStudentList",
				component: () => import("../views/miniApp/certificate/certificateStudentList.vue")
			},
			// 安知课堂 - 考证分类
			{
				path: "/web/textualResearchClassification",
				name: "textualResearchClassification",
				component: () => import("../views/miniApp/textualResearchClassification/textualResearchClassification.vue")
			},
			// 安知课堂 - 限时优惠
			{
				path: "/web/limitedTimeOfferList",
				name: "limitedTimeOfferList",
				component: () => import("../views/miniApp/limitedTimeOffer/limitedTimeOfferList.vue")
			},
			// 安知课堂 - 推荐课程主题
			{
				path: "/web/recommendCourses",
				name: "recommendCourses",
				component: () => import("../views/miniApp/recommendCourses/recommendCourses.vue")
			},
			//合作课程库
			{
				path: "/web/miniApp/cooperativeCourseLibrary",
				name: "cooperativeCourseLibrary",
				component: () => import("../views/miniApp/cooperativeCourse/cooperativeCourseLibrary.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			//合作课订单
			{
				path: "/web/miniApp/cooperativeClassOrder",
				name: "cooperativeClassOrder",
				component: () => import("../views/miniApp/cooperativeCourse/cooperativeClassOrder.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 直播落地页面（小程序）
			{
				path: "/web/miniApp/liveBroadcastLanding",
				name: "liveBroadcastLanding",
				component: () => import("../views/miniApp/liveBroadcastLanding/liveBroadcastLanding.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 直播落地页面 编辑页面（小程序）
			{
				path: "/web/miniApp/liveBroadcastLandingPageEditing",
				name: "liveBroadcastLandingPageEditing",
				component: () => import("../views/miniApp/liveBroadcastLanding/liveBroadcastLandingPageEditing.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 业务干预 -- 退款管理
			{
				path: "/web/businessIntervention/refundManagement",
				name: "businessIntervention/refundManagement",
				component: () =>
					import("../views/businessIntervention/refundManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 业务干预 -- 退款记录
			{
				path: "/web/businessIntervention/refundRecord",
				name: "businessIntervention/refundRecord",
				component: () =>
					import("../views/businessIntervention/refundRecord.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训
			// 企业内训 -- 企业内训首页
			{
				path: "/web/CustomPrograms/home",
				name: "CustomPrograms_home",
				component: () =>
					import("../views/CustomPrograms/home.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 企业内训 -- 企业内训订单列表
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingOrder",
				name: "CustomPrograms/enterpriseInternalTrainingOrder",
				component: () =>
					import("../views/CustomPrograms/enterpriseInternalTrainingOrder.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 企业内训 -- 企业内训订单列表 --详情
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingOrderView",
				name: "CustomPrograms/enterpriseInternalTrainingOrderView",
				component: () =>
					import("../views/CustomPrograms/enterpriseInternalTrainingOrderView.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训订单内容管理
			{
				path: "/web/CustomPrograms/distributeContent",
				name: "CustomPrograms/distributeContent",
				component: () =>
					import("../views/CustomPrograms/distributeContent.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 我的课程/课件
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
				name: "CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
				component: () => import("../views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingMyCourseCourseWare.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 企业内训 -- 我的课程/课件 --上传新课件
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingUploadCourseWare",
				name: "CustomPrograms/enterpriseInternalTrainingUploadCourseWare",
				component: () => import("../views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingUploadCourseWare.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 企业内训 -- 我的课程/课件 --创建课程
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingUploadMyCourse",
				name: "CustomPrograms/enterpriseInternalTrainingUploadMyCourse",
				component: () => import("../views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingUploadMyCourse.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 企业内训 -- 我的课程/课件 --查看课程
			{
				path: "/web/CustomPrograms/enterpriseInternalTrainingLookMyCourse",
				name: "CustomPrograms/enterpriseInternalTrainingLookMyCourse",
				component: () => import("../views/CustomPrograms/myCourseCourseWare/enterpriseInternalTrainingLookMyCourse.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 企业内训 -- 企业内训 - 培训任务列表
			{
				path: "/web/CustomPrograms/trainingList/classListCtr",
				name: "CustomPrograms/classListCtr",
				component: () =>
					import("../views/CustomPrograms/trainingList/classListCtr.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 企业内训 -- 企业内训 - 培训任务 课程学员列表
			{
				path: "/web/CustomPrograms/trainingList/ClassdetailsList",
				name: "CustomPrograms/ClassdetailsList",
				component: () =>
					import("../views/CustomPrograms/trainingList/ClassdetailsList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训 - 培训任务 关联课程
			{
				path: "/web/CustomPrograms/trainingList/addQyCourse",
				name: "CustomPrograms/addQyCourse",
				component: () =>
					import("../views/CustomPrograms/trainingList/addQyCourse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训 - 培训任务 查看课程
			{
				path: "/web/CustomPrograms/trainingList/seeClassMinsCtr",
				name: "CustomPrograms/seeClassMinsCtr",
				component: () =>
					import("../views/CustomPrograms/trainingList/seeClassMinsCtr.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训 - 学习数据列表
			{
				path: "/web/CustomPrograms/study/studyList",
				name: "CustomPrograms/studyList",
				component: () =>
					import("../views/CustomPrograms/study/studyList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 企业内训 -- 企业内训 - 学习数据详情
			{
				path: "/web/CustomPrograms/study/studyDetails",
				name: "CustomPrograms/studyDetails",
				component: () =>
					import("../views/CustomPrograms/study/studyDetails.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训 - 我的试卷
			{
				path: "/web/CustomPrograms/testPaperList",
				name: "CustomPrograms/testPaperList",
				component: () =>
					import("../views/CustomPrograms/myTest/testPaperList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 企业内训 -- 企业内训 - 我的试卷 -新增试卷
			{
				path: "/web/CustomPrograms/testPaperAdd",
				name: "CustomPrograms/testPaperAdd",
				component: () =>
					import("../views/CustomPrograms/myTest/testPaperAdd.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 企业内训 - 我的试卷 -查看试卷
			{
				path: "/web/CustomPrograms/testPaperEdit",
				name: "CustomPrograms/testPaperEdit",
				component: () =>
					import("../views/CustomPrograms/myTest/testPaperEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 企业内训 -- 资讯管理
			{
				path: "/web/CustomPrograms/InformationManagement",
				name: "CustomPrograms/InformationManagement",
				component: () => import("../views/CustomPrograms/InformationManagement/InformationManagement.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 企业内训 -- 资讯管理 --新增编辑
			{
				path: "/web/CustomPrograms/InformationManagementAddEdit",
				name: "CustomPrograms/InformationManagementAddEdit",
				component: () => import("../views/CustomPrograms/InformationManagement/InformationManagementAddEdit.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 企业内训 -- 店铺装修
			{
				path: "/web/CustomPrograms/shopDecoration",
				name: "CustomPrograms/shopDecoration",
				component: () => import("../views/CustomPrograms/shopDecoration/shopDecoration.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 监管端 -- 机构培训学员统计（内蒙专用）
			{
				path: "/web/set/institutionalTraineeStatistics",
				name: "set/institutionalTraineeStatistics",
				component: () =>
					import("../views/set/institutionalTraineeStatistics.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营端 -- 安知课堂 - 安知公益
			{
				path: "/web/miniApp/publicWelfarePromotion/publicWelfare",
				name: "miniApp/publicWelfarePromotion/publicWelfare",
				component: () =>
					import("../views/miniApp/publicWelfarePromotion/publicWelfare.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 运营端 -- 安知课堂 - 就业报名单
			{
				path: "/web/miniApp/publicWelfarePromotion/employmentRegistration",
				name: "miniApp/publicWelfarePromotion/employmentRegistration",
				component: () =>
					import("../views/miniApp/publicWelfarePromotion/employmentRegistration.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 运营端 -- 安知课堂 - 就业管理
			{
				path: "/web/miniApp/publicWelfarePromotion/employmentAdministration",
				name: "miniApp/publicWelfarePromotion/employmentAdministration",
				component: () =>
					import("../views/miniApp/publicWelfarePromotion/employmentAdministration.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 运营端 -- 安知课堂 - 测评管理
			{
				path: "/web/miniApp/evaluation/evaluationList",
				name: "miniApp/evaluation/evaluationList",
				component: () =>
					import("../views/miniApp/evaluation/evaluationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营端 -- 安知课堂 - 选项组管理
			{
				path: "/web/miniApp/evaluation/optionGroupList",
				name: "miniApp/evaluation/optionGroupList",
				component: () =>
					import("../views/miniApp/evaluation/optionGroupList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 运营端 -- 安知课堂 - 测评管理 - 题目设置
			{
				path: "/web/miniApp/evaluation/itemBankSetting",
				name: "miniApp/evaluation/itemBankSetting",
				component: () =>
					import("../views/miniApp/evaluation/itemBankSetting.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营端 -- 安知课堂 - 测评管理 - 结果设置
			{
				path: "/web/miniApp/evaluation/structureSectting",
				name: "miniApp/evaluation/structureSectting",
				component: () =>
					import("../views/miniApp/evaluation/structureSectting.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 运营端 -- 安知课堂 - 政策快递管理
			{
				path: "/web/miniApp/policyExpress/policyExpress",
				name: "miniApp/policyExpress/policyExpress",
				component: () =>
					import("../views/miniApp/policyExpress/policyExpressList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			/* 机构管理 */
			// 机构管理 -- 首页
			{
				path: "/web/institutionalManagement/home",
				name: "institutionalManagement/home",
				component: () =>
					import("../views/institutionalManagement/home.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 机构管理 -- 基础信息 - 培训类型维护
			{
				path: "/web/institutionalManagement/basicInfo/trainingTypeMaintenance",
				name: "institutionalManagement/basicInfo/trainingTypeMaintenance",
				component: () =>
					import("../views/institutionalManagement/basicInfo/trainingTypeMaintenance.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 机构管理 -- 基础信息 - 评价工种维护
			{
				path: "/web/institutionalManagement/basicInfo/evaluationMaintenance",
				name: "institutionalManagement/basicInfo/evaluationMaintenance",
				component: () => import("../views/institutionalManagement/basicInfo/evaluationMaintenance.vue"),
				meta: {
					level: 1,
					state: true,
				}
			},
			// 机构管理 -- 基础信息 - 评价工种维护 - 设置申请表
			{
				path: "/web/institutionalManagement/basicInfo/setUpApplicationForm",
				name: "institutionalManagement/basicInfo/setUpApplicationForm",
				component: () => import("../views/institutionalManagement/basicInfo/setUpApplicationForm.vue"),
				meta: {
					level: 2,
					state: false,
				}
			},
			// 机构管理 -- 基础信息 - 评价工种维护新增编辑
			{
				path: "/web/institutionalManagement/basicInfo/evaluationAdd",
				name: "institutionalManagement/basicInfo/evaluationAdd",
				component: () => import("../views/institutionalManagement/basicInfo/evaluationAdd.vue"),
				meta: {
					level: 2,
					state: false,
				}
			},
			// 机构管理 -- 基础信息 - 可用属性维护
			{
				path: "/web/institutionalManagement/basicInfo/compAttribute",
				name: "institutionalManagement/basicInfo/compAttribute",
				component: () => import("../views/institutionalManagement/basicInfo/compAttribute.vue"),
				meta: {
					level: 1
				}
			},
			// 机构管理 -- 运营工具 - 店铺装修
			{
				path: "/web/institutionalManagement/operationalTools/storeConstruction",
				name: "institutionalManagement_operationalTools_storeConstruction",
				component: () =>
					import("../views/institutionalManagement/operationalTools/storeConstruction.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 机构管理 -- 运营工具 - banner
			{
				path: "/web/institutionalManagement/banner/bannerList",
				name: "institutionalManagement/banner/bannerList",
				component: () =>
					import("../views/institutionalManagement/banner/bannerList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// 机构管理 -- 档案管理 - 报名资料下载
			{
				path: "/web/institutionalManagement/fileManagement/downloadInfo",
				name: "institutionalManagement/fileManagement/downloadInfo",
				component: () =>
					import("../views/institutionalManagement/fileManagement/downloadInfo.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 机构管理 -- 档案管理 - 报名资料下载 - 下载档案
			{
				path: "/web/institutionalManagement/downloadFile",
				name: "downloadFile",
				component: () =>
					import("../views/institutionalManagement/popup/downloadFile.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构管理 -- 渠道管理 - 渠道管理
			{
				path: "/web/institutionalManagement/channelManagement/channelManagement",
				name: "institutionalManagement/channelManagement/channelManagement",
				component: () =>
					import("../views/institutionalManagement/channelManagement/channelManagement.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 机构管理 -- 渠道管理 - 渠道管理 > 查看招生情况
			{
				path: "/web/institutionalManagement/channelManagement/checkAdmissions",
				name: "institutionalManagement/channelManagement/checkAdmissions",
				component: () =>
					import("../views/institutionalManagement/channelManagement/checkAdmissions.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 机构管理 -- 渠道管理 -- 学员管理 -- 学员列表
			{
				path: "/web/institutionalManagement/channelManagement/studentList",
				name: "institutionalManagement/channelManagement/studentList",
				component: () => import("../views/institutionalManagement/channelManagement/studentList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 机构管理 -- 渠道管理 -- 学员管理
			{
				path: "/web/institutionalManagement/channelManagement/studentManagement",
				name: "institutionalManagement/channelManagement/studentManagement",
				component: () => import("../views/institutionalManagement/channelManagement/studentManagement.vue"),
				meta: {
					level: 1
				}
			},
			// 机构管理 -- 渠道管理 -- 报名列表
			{
				path: "/web/institutionalManagement/channelManagement/channelRegistrationList",
				name: "institutionalManagement/channelManagement/channelRegistrationList",
				component: () => import("../views/institutionalManagement/channelManagement/channelRegistrationList.vue"),
				meta: {
					level: 1
				}
			},
			// 机构管理 -- 报名管理 -- 报名列表
			{
				path: "/web/customProgramsRegistrationList",
				name: "customProgramsRegistrationList",
				component: () => import("../views/institutionalManagement/registrationManagement/customProgramsRegistrationList.vue"),
				meta: {
					state: true,
					level: 1
				}

			},
			// 机构管理 -- 报名管理 -- 报名列表 > 学员管理
			{
				path: "/web/studentManagement",
				name: "studentManagement",
				component: () => import("../views/institutionalManagement/registrationManagement/studentManagement.vue"),
				meta: {
					state: true,
					level: 2
				}
			},
			// 机构管理 -- 报名管理 -- 报名列表 > 学员管理 > 新增编辑学员信息
			{
				path: "/web/studentUpdate",
				name: "studentUpdate",
				component: () => import("../views/institutionalManagement/registrationManagement/studentUpdate.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 机构管理 -- 报名管理 -- 报名列表 > 学员管理 > 学员信息
			{
				path: "/web/studentDetails",
				name: "studentDetails",
				component: () => import("../views/institutionalManagement/registrationManagement/studentDetails.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 机构管理 - 报名管理 - 报考进度管理
			{
				path: "/institutionalManagement/registrationManagement/applicationProgress",
				name: "institutionalManagement_registrationManagement_applicationProgress",
				component: () => import("../views/institutionalManagement/registrationManagement/applicationProgress.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 客户中心 - 企业招工 - 我要招聘
			{
				path: "/web/accountManagement/advertisementInfo",
				name: "accountManagement_advertisementInfo",
				component: () => import("../views/accountManagement/advertisementInfo.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 客户中心 - 企业招工 - 查看联系人
			{
				path: "/web/accountManagement/viewContact",
				name: "accountManagement_viewContact",
				component: () => import("../views/accountManagement/viewContact.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 阿里云视频预览列表
			{
				path: "/web/checkVideo/videoList",
				name: "checkVideo_videoList",
				component: () => import("../views/checkVideo/videoList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 无人学习视频列表
			{
				path: "/web/checkVideo/noBodyLearningVideoList",
				name: "checkVideo_noBodyLearningVideoList",
				component: () => import("../views/checkVideo/noBodyLearningVideoList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 有人学习视频列表
			{
				path: "/web/checkVideo/learningVideoList",
				name: "checkVideo_learningVideoList",
				component: () => import("../views/checkVideo/learningVideoList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 人才库列表
			{
				path: "/hr/recruitModule/personnelLibrary/personnelList",
				name: "hr_recruitModule_personnelLibrary_personnelList",
				component: () => import("../views/recruitModule/personnelLibrary/personnelList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 新增 || 编辑 - 人才
			{
				path: "/hr/recruitModule/personnelLibrary/addPersonnel",
				name: "hr_recruitModule_personnelLibrary_addPersonnel",
				component: () => import("../views/recruitModule/personnelLibrary/addPersonnel.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 推荐设置
			{
				path: "/hr/recruitModule/personnelLibrary/recommendedSettings",
				name: "hr_recruitModule_personnelLibrary_recommendedSettings",
				component: () => import("../views/recruitModule/personnelLibrary/recommendedSettings.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 备注设置
			{
				path: "/hr/recruitModule/personnelLibrary/notesSettings",
				name: "hr_recruitModule_personnelLibrary_notesSettings",
				component: () => import("../views/recruitModule/personnelLibrary/notesSettings.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 项目经历
			{
				path: "/hr/recruitModule/personnelLibrary/projectExperience",
				name: "hr_recruitModule_personnelLibrary_projectExperience",
				component: () => import("../views/recruitModule/personnelLibrary/projectExperience.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 工作经历
			{
				path: "/hr/recruitModule/personnelLibrary/workExperience",
				name: "hr_recruitModule_personnelLibrary_workExperience",
				component: () => import("../views/recruitModule/personnelLibrary/workExperience.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 求职意向
			{
				path: "/hr/recruitModule/personnelLibrary/jobHuntingIntention",
				name: "hr_recruitModule_personnelLibrary_jobHuntingIntention",
				component: () => import("../views/recruitModule/personnelLibrary/jobHuntingIntention.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 教育经历
			{
				path: "/hr/recruitModule/personnelLibrary/educationalExperience",
				name: "hr_recruitModule_personnelLibrary_educationalExperience",
				component: () => import("../views/recruitModule/personnelLibrary/educationalExperience.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 人才库 - 职业技能
			{
				path: "/hr/recruitModule/personnelLibrary/vocationalSkills",
				name: "hr_recruitModule_personnelLibrary_vocationalSkills",
				component: () => import("../views/recruitModule/personnelLibrary/vocationalSkills.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 简历库 - 简历库列表
			{
				path: "/hr/recruitModule/resumeLibrary/resumeList",
				name: "hr_recruitModule_resumeLibrary_resumeList",
				component: () => import("../views/recruitModule/resumeLibrary/resumeList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 简历库 - 简历库详情
			{
				path: "/hr/recruitModule/resumeLibrary/resumeDetail",
				name: "hr_recruitModule_resumeLibrary_resumeDetail",
				component: () => import("../views/recruitModule/resumeLibrary/resumeDetail.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 招聘模块 - 简历库 - 沟通记录
			{
				path: "/hr/recruitModule/resumeLibrary/communicationRecord",
				name: "hr_recruitModule_resumeLibrary_communicationRecord",
				component: () => import("../views/recruitModule/resumeLibrary/communicationRecord.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			//安知人力资源服务平台-个人认证
			{
				path: "/azhr/humanResources/personalCertification",
				name: "personalCertification",
				component: () => import("../views/humanResources/personalCertification.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			//安知人力资源服务平台-企业认证
			{
				path: "/azhr/humanResources/enterpriseCertification",
				name: "enterpriseCertification",
				component: () => import("../views/humanResources/enterpriseCertification.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 简历库 - 简历库列表
			{
				path: "/azhr/humanResources/resumeLibrary/resumeList",
				name: "azhr_resumeList",
				component: () => import("../views/humanResources/resumeLibrary/resumeList.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 简历库 - 简历库详情
			{
				path: "/azhr/humanResources/resumeLibrary/resumeDetail",
				name: "azhr_resumeDetail",
				component: () => import("../views/humanResources/resumeLibrary/resumeDetail.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 简历库 - 沟通记录
			{
				path: "/azhr/humanResources/resumeLibrary/communicationRecord",
				name: "azhr_communicationRecord",
				component: () => import("../views/humanResources/resumeLibrary/communicationRecord.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 岗位登记
			{
				path: "/azhr/humanResources/postRegistration/advertisementList",
				name: "azhr_advertisementList",
				component: () => import("../views/humanResources/postRegistration/advertisementList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 安知人力资源服务平台 - 岗位登记 - 新增、编辑、查看
			{
				path: "/azhr/humanResources/postRegistration/advertisementInfo",
				name: "azhr_advertisementInfo",
				component: () => import("../views/humanResources/postRegistration/advertisementInfo.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 安知人力资源服务平台 - 查看联系人(旧)
			// {
			// 	path:"/azhr/humanResources/postRegistration/viewContact",
			// 	name:"azhr_viewContact",
			// 	component:()=> import("../views/humanResources/postRegistration/viewContact.vue"),
			// 	meta:{
			// 		level:2,
			// 		state:false
			// 	}
			// },
			// 安知人力资源服务平台 - 查看联系人(人力资源用)
			{
				path: "/azhr/humanResources/postRegistration/resumeList",
				name: "azhr_viewContact",
				component: () => import("../views/humanResources/postRegistration/resumeList.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 安知人力资源服务平台 - 简历库 - 简历库详情
			{
				path: "/azhr/humanResources/postRegistration/resumeDetail",
				name: "azhr_postRegistration_resumeDetail",
				component: () => import("../views/humanResources/postRegistration/resumeDetail.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 简历库 - 沟通记录
			{
				path: "/azhr/humanResources/postRegistration/communicationRecord",
				name: "azhr_postRegistratio_communicationRecord",
				component: () => import("../views/humanResources/postRegistration/communicationRecord.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 - 培训管理
			{
				path: "/azhr/humanResources/trainingManagement/classListCtr",
				name: "azhr_classListCtr",
				component: () => import("../views/humanResources/trainingManagement/classListCtr.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 安知人力资源服务平台 -- 培训管理 - 课程学员列表
			{
				path: "/azhr/humanResources/trainingManagement/ClassdetailsList",
				name: "azhr_classListCtr/ClassdetailsList",
				component: () =>
					import("../views/humanResources/trainingManagement/ClassdetailsList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 安知人力资源服务平台 -- 培训管理 - 课程列表 - 查看课程
			{
				path: "/azhr/humanResources/trainingManagement/seeClassMinsCtr",
				name: "azhr_classListCtr/ClassdetailsList/seeClassMinsCtr",
				component: () =>
					import("../views/humanResources/trainingManagement/seeClassMinsCtr.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 安知人力资源服务平台 -- 培训管理 - 课程列表 - 关联课程
			{
				path: "/azhr/humanResources/trainingManagement/addQyCourse",
				name: "azhr_classListCtr/ClassdetailsList/addQyCourse",
				component: () =>
					import("../views/humanResources/trainingManagement/addQyCourse.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 安知人力资源平台 - 首页
			{
				path: "/azhr/home",
				name: "azhr_home",
				component: () => import("../views/humanResources/HRHome.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-简历推送统计
			{
				path: "/web/resumePushStatistics",
				name: "web_resumePushStatistics",
				component: () => import("../views/set/resumePushStatistics/resumePushStatistics.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 数据中心-简历推送情况
			{
				path: "/web/resumePushDetail",
				name: "web_resumePushDetail",
				component: () => import("../views/set/resumePushStatistics/resumePushDetail.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-查看简历状态
			{
				path: "/web/resumeState",
				name: "web_resumeState",
				component: () => import("../views/set/resumePushStatistics/resumeState.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-查看沟通记录
			{
				path: "/web/communicationRecord",
				name: "web_communicationRecord",
				component: () => import("../views/set/resumePushStatistics/communicationRecord.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-每日签到数据统计
			{
				path: "/web/resumePushStatistics/dailySignInStatistics",
				name: "web_resumePushStatistics_dailySignInStatistics",
				component: () => import("../views/set/resumePushStatistics/dailySignInStatistics.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-学员用券查询（山西）
			{
				path: "/web/voucherInquiry",
				name: "web_voucherInquiry",
				component: () => import("../views/set/voucherInquiry.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 数据中心-自检异常数据
			{
				path: "/web/set/selfCheckingAbnormal",
				name: "set/selfCheckingAbnormal",
				component: () => import("../views/set/selfCheckingAbnormal.vue")
			},
			// 2.0 - 题库管理（升级版）-题库管理
			{
				path: "/inkQuestionBank/QuestionBankManage/QuestionBankManage",
				name: "inkQuestionBank_QuestionBankManage_QuestionBankManage",
				component: () => import("../views/inkQuestionBank/QuestionBankManage/QuestionBankManage.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 2.0 - 题库管理（升级版）-题目列表
			{
				path: "/inkQuestionBank/QuestionBankManage/QuestionList",
				name: "inkQuestionBank_QuestionBankManage_QuestionList",
				component: () => import("../views/inkQuestionBank/QuestionBankManage/QuestionList.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// 2.0 - 题库管理（升级版）- 新增编辑题目
			{
				path: "/inkQuestionBank/QuestionBankManage/QuestionAddOrEdit",
				name: "inkQuestionBank_QuestionBankManage_QuestionAddOrEdit",
				component: () => import("../views/inkQuestionBank/QuestionBankManage/QuestionAddOrEdit.vue"),
			},

			// 2.0 - 题库管理（升级版）- 知识点分类
			{
				path: "/inkQuestionBank/KnowledgePoints/KnowledgePointsList",
				name: "inkQuestionBank_KnowledgePoints_KnowledgePointsList",
				component: () => import("../views/inkQuestionBank/KnowledgePoints/KnowledgePointsList.vue"),
				meta: {},
			},
			// 2.0 - 题库管理（升级版）- 试卷管理
			{
				path: "/inkQuestionBank/examinationPaperManage/tactics",
				name: "inkQuestionBank_examinationPaperManage_tactics",
				component: () => import("../views/inkQuestionBank/examinationPaperManage/tactics.vue"),
				meta: {
					level: 1,
					state: false
				},
			},
			// 2.0 - 题库管理（升级版）- 试卷管理 - 试卷列表
			{
				path: "/inkQuestionBank/examinationPaperManage/tacticsList",
				name: "inkQuestionBank_examinationPaperManage_tacticsList",
				component: () => import("../views/inkQuestionBank/examinationPaperManage/tacticsList.vue"),
				meta: {
					level: 2,
					state: false
				},
			},
			// 2.0 - 题库管理（升级版）- 试卷管理 - 试卷列表 - 试卷预览编辑
			{
				path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
				name: "inkQuestionBank_examinationPaperManage_tacticsUpdate",
				component: () => import("../views/inkQuestionBank/examinationPaperManage/tacticsUpdate.vue"),
				meta: {
					level: 2,
					state: false
				},
			},
			// 2.0 - 题库管理（升级版）- 试卷管理 - 试卷列表 - 试卷预览编辑 - 新增编辑试题
			{
				path: "/inkQuestionBank/examinationPaperManage/QuestionAddOrEdit",
				name: "inkQuestionBank_examinationPaperManage_QuestionAddOrEdit",
				component: () => import("../views/inkQuestionBank/examinationPaperManage/QuestionAddOrEdit.vue"),
				meta: {
					level: 2,
					state: false
				},
			},
			// 2.0 - 题库管理（升级版）- 试卷管理 - 试卷规则
			{
				path: "/inkQuestionBank/examinationPaperManage/tacticsRule",
				name: "inkQuestionBank_examinationPaperManage_tacticsRule",
				component: () => import("../views/inkQuestionBank/examinationPaperManage/tacticsRule.vue"),
				meta: {
					level: 2,
					state: false
				},
			},
			// 2.0 - 题库管理（升级版）-练习题库配置
			{
				path: "/inkQuestionBank/exerciseQBConfig/exerciseQBConfig",
				name: "inkQuestionBank_exerciseQBConfig_exerciseQBConfig",
				component: () => import("../views/inkQuestionBank/exerciseQBConfig/exerciseQBConfig.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 2.0 - 2.0 - 题库管理（升级版）-练习题库配置-查看列表
			{
				path: "/inkQuestionBank/exerciseQBConfig/viewList",
				name: "inkQuestionBank_exerciseQBConfig_viewList",
				component: () => import("../views/inkQuestionBank/exerciseQBConfig/viewList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 2.0 - 题库管理（升级版）-模拟考试配置
			{
				path: "/inkQuestionBank/SimulatedExamConfig/SimulatedExamConfig",
				name: "inkQuestionBank_SimulatedExamConfig_SimulatedExamConfig",
				component: () => import("../views/inkQuestionBank/SimulatedExamConfig/SimulatedExamConfig.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 2.0 - 题库管理（升级版）-模拟考试配置 - 新增、编辑
			{
				path: "/inkQuestionBank/SimulatedExamConfig/updateTask",
				name: "inkQuestionBank_SimulatedExamConfig_updateTask",
				component: () => import("../views/inkQuestionBank/SimulatedExamConfig/updateTask.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 2.0 - 题库管理（升级版）-每日打卡
			{
				path: "/inkQuestionBank/dailyClockIn/dailyClockInList",
				name: "inkQuestionBank_dailyClockIn_dailyClockInList",
				component: () => import("../views/inkQuestionBank/dailyClockIn/dailyClockInList.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 2.0 - 题库管理（升级版）-每日打卡 - 详情
			{
				path: "/inkQuestionBank/dailyClockIn/dailyClockInInfo",
				name: "inkQuestionBank_dailyClockIn_dailyClockInInfo",
				component: () => import("../views/inkQuestionBank/dailyClockIn/dailyClockInInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 2.0 - 题库管理（升级版）-模拟考成绩查询
			{
				path: "/inkQuestionBank/modelExamScoreQuery/modelExamScoreQueryList",
				name: "inkQuestionBank_modelExamScoreQuery_modelExamScoreQueryList",
				component: () => import("../views/inkQuestionBank/modelExamScoreQuery/modelExamScoreQueryList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 2.0 - 题库管理（升级版）-模拟考成绩查询 - 查看名单
			{
				path: "/inkQuestionBank/modelExamScoreQuery/modelExamScoreQueryListInfo",
				name: "inkQuestionBank_modelExamScoreQuery_modelExamScoreQueryListInfo",
				component: () => import("../views/inkQuestionBank/modelExamScoreQuery/modelExamScoreQueryListInfo.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// evaluation-h5 - 渠道管理
			{
				path: "/evaluationManagement/channelManage",
				name: "evaluationManagement_channelManage",
				component: () => import("../views/evaluationManagement/channelManage.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// evaluation-h5 - 测评管理 - 机构端
			{
				path: "/web/evaluationManagement/jgevaluationList",
				name: "evaluationManagement_jgevaluationList",
				component: () =>
					import("../views/evaluationManagement/jgevaluationList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// evaluation-h5 - 测评管理 - 测评审核 - 运营
			{
				path: "/web/evaluationManagement/evaluationPriceExamine",
				name: "evaluationManagement_evaluationPriceExamine",
				component: () =>
					import("../views/evaluationManagement/evaluationPriceExamine.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// evaluation-h5 - 测评管理 - 优惠券管理
			{
				path: "/web/evaluationManagement/couponManagement",
				name: "evaluationManagement_couponManagement",
				component: () =>
					import("../views/evaluationManagement/couponManagement.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// evaluation-h5 - 测评管理 - 订单中心 - 运营 & 机构
			{
				path: "/web/evaluationManagement/orderList",
				name: "evaluationManagement_orderList",
				component: () =>
					import("../views/evaluationManagement/orderList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			// evaluation-h5 - 测评管理 - 测评结果统计 - 机构
			{
				path: "/web/evaluationManagement/evaluationResults",
				name: "evaluationManagement_evaluationResults",
				component: () =>
					import("../views/evaluationManagement/evaluationResults.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// evaluation-h5 - 测评管理 - 测评结果统计 - 结果汇总 - 机构
			{
				path: "/web/evaluationManagement/evaluationResultsSummary",
				name: "evaluationManagement_evaluationResultsSummary",
				component: () =>
					import("../views/evaluationManagement/evaluationResultsSummary.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 阅卷 - 阅卷列表
			{
				path: "/web/examination/reviewPaperList",
				name: "examination_reviewPaperList",
				component: () =>
					import("../views/examination/reviewPaper/reviewPaperList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 阅卷 - 阅卷详情
			{
				path: "/web/examination/reviewPaperDetail",
				name: "examination_reviewPaperDetail",
				component: () =>
					import("../views/examination/reviewPaper/reviewPaperDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 阅卷 - 查看阅卷学员
			{
				path: "/web/examination/examineStudent",
				name: "examination_examineStudent",
				component: () =>
					import("../views/examination/reviewPaper/examineStudent.vue"),
				meta: {
					state: true,
					level: 2,
				},
			},
			// 考试系统 - 复核 - 考试成绩核实
			{
				path: "/web/examination/recheckList",
				name: "examination_recheckList",
				component: () =>
					import("../views/examination/reviewPaper/recheckList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 复核 - 查看复核学员
			{
				path: "/web/examination/recheckStudent",
				name: "examination_recheckStudent",
				component: () =>
					import("../views/examination/reviewPaper/recheckStudent.vue"),
				meta: {
					state: true,
					level: 2,
				},
			},

			// 考试系统 - 线上考试管理列表
			{
				path: "/web/examination/onlineTest/onlineTestList",
				name: "examination_onlineTest_onlineTestList",
				component: () =>
					import("../views/examination/onlineTest/onlineTestList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 考试档案
			{
				path: "/web/examination/examinationArchives/examinationArchives",
				name: "examination_examinationArchives_examinationArchives",
				component: () =>
					import("../views/examination/examinationArchives/examinationArchives.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 线上考试管理列表 - 新增编辑
			{
				path: "/web/examination/onlineTest/onlineTestListAddEdit",
				name: "examination_onlineTest_onlineTestListAddEdit",
				component: () =>
					import("../views/examination/onlineTest/onlineTestListAddEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 从考务处选择
			{
				path: "/web/examination/onlineTest/chooseFromOrganization",
				name: "examination_onlineTest_chooseFromOrganization",
				component: () =>
					import("../views/examination/onlineTest/chooseFromOrganization.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 试卷列表
			{
				path: "/web/examination/onlineTest/examinationPaperList",
				name: "examination_onlineTest_examinationPaperList",
				component: () =>
					import("../views/examination/onlineTest/examinationPaperList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 试卷列表 - 选择试卷
			{
				path: "/web/examination/onlineTest/queryExaminationPaperList",
				name: "examination_onlineTest_queryExaminationPaperList",
				component: () =>
					import("../views/examination/onlineTest/queryExaminationPaperList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 监考老师设置
			{
				path: "/web/examination/onlineTest/invigilator",
				name: "examination_onlineTest_invigilator",
				component: () =>
					import("../views/examination/onlineTest/invigilator.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 考试配置
			{
				path: "/web/examination/onlineTest/examinationConfig",
				name: "examination_onlineTest_examinationConfig",
				component: () =>
					import("../views/examination/onlineTest/examinationConfig.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},

			// 考试系统 - 线上考试管理列表 - 阅卷配置
			{
				path: "/web/examination/onlineTest/reviewPaperConfig",
				name: "examination_onlineTest_reviewPaperConfig",
				component: () =>
					import("../views/examination/onlineTest/reviewPaperConfig.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},

			// 考试系统 - 账号列表 - 老师管理
			{
				path: "/web/examination/teacherSetting/teacherSetting",
				name: "examination_teacherSetting_teacherSetting",
				component: () =>
					import("../views/examination/teacherSetting/teacherSetting.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 线上考试管理列表 - 学员列表
			{
				path: "/web/examination/onlineTest/studentList",
				name: "examination_onlineTest_studentList",
				component: () =>
					import("../views/examination/onlineTest/studentList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 学员列表 - 选择学员
			{
				path: "/web/examination/onlineTest/studentsChoose",
				name: "examination_onlineTest_studentsChoose",
				component: () =>
					import("../views/examination/onlineTest/studentsChoose.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 线上考试管理列表 - 学员成绩查询
			{
				path: "/web/examination/scoreInquiry",
				name: "examination_scoreInquiry",
				component: () =>
					import("../views/examination/scoreInquiry/scoreInquiry.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// 考试系统 - 考试公告管理
			{
				path: "/web/examination/onlineTest/announcementOfManagement",
				name: "examination_onlineTest_announcementOfManagement",
				component: () =>
					import("../views/examination/onlineTest/announcementOfManagement.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 考试公告管理 - 新增&编辑
			{
				path: "/web/examination/onlineTest/announcementOfManagementAddEdit",
				name: "examination_onlineTest_announcementOfManagementAddEdit",
				component: () =>
					import("../views/examination/onlineTest/announcementOfManagementAddEdit.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 考试公告管理 - 监考中心列表
			{
				path: "/web/examination/onlineTest/aroundCenterList",
				name: "examination_onlineTest_aroundCenterList",
				component: () =>
					import("../views/examination/onlineTest/aroundCenterList.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 考试系统 - 考试公告管理 - 监考中心列表 - 监考详情
			{
				path: "/web/examination/onlineTest/aroundCenterInfo",
				name: "examination_onlineTest_aroundCenterInfo",
				component: () =>
					import("../views/examination/onlineTest/aroundCenterInfo.vue"),
				meta: {
					state: false,
					level: 1,
					show: false
				},
			},
			// ［运营端］客户中心－学校列表
			{
				path: "/web/internship/customerCenter/schoolList",
				name: "internship_customerCenter_schoolList",
				component: () =>
					import("../views/internship/customerCenter/schoolList.vue"),
				meta: {
					state: true,
					level: 1,
				},
			},
			// ［运营端］客户中心－学校列表－学校详情
			{
				path: "/web/internship/customerCenter/schoolDetail",
				name: "internship_customerCenter_schoolDetail",
				component: () =>
					import("../views/internship/customerCenter/schoolDetail.vue"),
				meta: {
					state: false,
					level: 2,
				},
			},
			// 鉴定机构端 - 财务管理 - 学员缴费管理
			{
				path: "/financialManagement/studentPayment",
				name: "financialManagement_studentPayment",
				component: () =>
					import("../views/financialManagement/studentPayment.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//[运营端] 工伤预防班级管理列表
			{
				path: "/gsyfProject/projectList",
				name: "gsyfProject_projectList",
				component: () =>
					import("../views/gsyfProject/projectList.vue"),
				meta: {
					state: false,
					level: 1,
				},
			},
			//[运营端] 安知业绩统计相关界面路由
			// 培训数据统计
			{
				path: "/performance/trainingStatistics",
				name: "performance_trainingStatistics",
				component: () => import("../views/performance/trainingStatistics.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 业绩数据统计
			{
				path: "/performance/incomeStatistics",
				name: "performance_incomeStatistics",
				component: () => import("../views/performance/incomeStatistics.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 业务培训统计
			{
				path: "/performance/salesmanTrainingStatistics",
				name: "performance_salesmanTrainingStatistics",
				component: () => import("../views/performance/salesmanTrainingStatistics.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// 业务收入统计
			{
				path: "/performance/salesmanIncomeStatistics",
				name: "performance_salesmanIncomeStatistics",
				component: () => import("../views/performance/salesmanIncomeStatistics.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// [机构端+监管端]数据中心：学员信息查询（安全生产）
			{
				path: "/set/studentInformationQueryAQSC",
				name: "set_studentInformationQueryAQSC",
				component: () => import("../views/set/studentInformationQueryAQSC.vue"),
				meta: {
					level: 1,
					state: true
				}
			},
			// [机构端+监管端]数据中心：学员信息查询（安全生产）- 学习记录
			{
				path: "/set/studentInformationQueryAQSCInfo",
				name: "set_studentInformationQueryAQSCInfo",
				component: () => import("../views/set/studentInformationQueryAQSCInfo.vue"),
				meta: {
					level: 2,
					state: false
				}
			},
			// Vr720
			{
				path: "/wrongAuthenticatePage/VRfictitious",
				name: "wrongAuthenticatePage_VRfictitious",
				component: () => import("../views/wrongAuthenticatePage/VRfictitious.vue"),
				meta: {
					level: 1,
					state: false
				}
			},
			// 人力法宝 - 法律咨询在线回复模块 - 律所律师用
			{
				path: "/web/consult/consultListJg",
				name: "manpower_consult_consultListJg",
				component: () => import("../views/manpower/consult/consultListJg.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 人力法宝 - 法律咨询在线回复模块 - 运维用
			{
				path: "/web/consult/consultList",
				name: "manpower_consult_consultList",
				component: () => import("../views/manpower/consult/consultList.vue"),
				meta: {
					state: true,
					level: 1
				}
			},
			// 人力法宝 - 法律咨询在线回复模块 - 指派律师
			{
				path: "/web/manpower/consult/assign",
				name: "manpower_consult_assign",
				component: () => import("../views/manpower/consult/assign.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 人力法宝 - AI咨询管理模块
			{
				path: "/web/manpower/csp/consultAiList",
				name: "manpower_consult_consultAiList",
				component: () => import("../views/manpower/csp/consultAiList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 人力法宝 - 订单列表 (订单管理)
			{
				path: "/web/manpower/order/orderList",
				name: "manpower_order_orderList",
				component: () => import("../views/manpower/order/orderList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 人力法宝 - 订单列表 (订单管理) [仅查询]
			{
				path: "/web/manpower/order/orderListShow",
				name: "manpower_order_orderListShow",
				component: () => import("../views/manpower/order/orderListShow.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 人力法宝 - 退单列表 (订单管理)
			{
				path: "/web/manpower/order/refundList",
				name: "manpower_order_refundList",
				component: () => import("../views/manpower/order/refundList.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 特种作业实操考核系统 - 首页
			{
				path: "/practicalAssessmentSystem/home",
				name: "systemManage_home",
				component: () => import("../views/practicalAssessmentSystem/home.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考点管理
			{
				path: "/practicalAssessmentSystem/systemManage/examCenter",
				name: "systemManage_examCenter",
				component: () => import("../views/practicalAssessmentSystem/systemManage/examCenter.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考点管理 - 新增&编辑
			{
				path: "/practicalAssessmentSystem/systemManage/examCenter_addAndedit",
				name: "systemManage_examCenter_addAndedit",
				component: () => import("../views/practicalAssessmentSystem/systemManage/examCenter_addAndedit.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考评员管理
			{
				path: "/practicalAssessmentSystem/systemManage/examinerManage",
				name: "systemManage_examinerManage",
				component: () => import("../views/practicalAssessmentSystem/systemManage/examinerManage.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考评员管理 - 新增&编辑
			{
				path: "/practicalAssessmentSystem/systemManage/examinerManage_addAndedit",
				name: "systemManage_examinerManage_addAndedit",
				component: () => import("../views/practicalAssessmentSystem/systemManage/examinerManage_addAndedit.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考官管理
			{
				path: "/practicalAssessmentSystem/systemManage/supervise",
				name: "systemManage_supervise",
				component: () => import("../views/practicalAssessmentSystem/systemManage/supervise.vue.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理 - 考官管理 - 新增&编辑
			{
				path: "/practicalAssessmentSystem/systemManage/supervise_addAndedit",
				name: "systemManage_supervise_addAndedit",
				component: () => import("../views/practicalAssessmentSystem/systemManage/supervise_addAndedit.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 考试计划
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/examPlan",
				name: "examinationAffairsManage_examPlan",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/examPlan.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 考试计划 - 新增&编辑
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/examPlan_info",
				name: "examinationAffairsManage_examPlan_info",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 考试计划(审核)
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/set_examPlan",
				name: "examinationAffairsManage_set_examPlan",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/set_examPlan.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 题库管理
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank",
				name: "examinationAffairsManage_questionBank",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/questionBank.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 题库管理 - 题库内容
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank_info",
				name: "examinationAffairsManage_questionBank_info",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 题库管理 - 题库内容 - 题目列表
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list",
				name: "examinationAffairsManage_questionBank_info_list",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list.vue"),
				meta: {
					state: false,
					level: 2
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 题库管理 - 题库内容 - 题目列表 - 新增编辑试题
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set",
				name: "examinationAffairsManage_questionBank_info_list_set",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 考试管理 - 成绩查询
			{
				path: "/practicalAssessmentSystem/examinationAffairsManage/queryScore",
				name: "examinationAffairsManage_queryScore",
				component: () => import("../views/practicalAssessmentSystem/examinationAffairsManage/queryScore.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
			// 特种作业实操考核系统 - 系统管理-考点管理-激活码管理
			{
				path: "/practicalAssessmentSystem/systemManage/examCenter_activationCode",
				name: "systemManage_examCenter_activationCode",
				component: () => import("../views/practicalAssessmentSystem/systemManage/examCenter_activationCode.vue"),
				meta: {
					state: false,
					level: 1
				}
			},
		],
	},
	// 人力资源平台-用户注册
	{
		path: "/azhr/humanResources/register",
		name: "azhr_humanResources_register",
		component: () => import("../views/humanResources/register.vue"),
		meta: {
			level: 1,
			state: false
		}
	},
	// 人力资源平台-企业信息
	{
		name: "azhr_humanResources_enterpriseInfo",
		path: "/azhr/humanResources/enterpriseInfo",
		component: () => import("../views/humanResources/enterpriseInfo.vue"),
		meta: {
			level: 1,
			state: false
		}
	},
	// 新 - 登录页 - 关于晟融安知
	{
		path: "/wrongAuthenticatePage/aboutShengrongAn",
		name: "wrongAuthenticatePage_aboutShengrongAn",
		component: () => import("../views/wrongAuthenticatePage/aboutShengrongAn.vue"),
		meta: {},
	},
	// 新 - 登录页 - 课程中心
	{
		path: "/wrongAuthenticatePage/courseCenter",
		name: "wrongAuthenticatePage_courseCenter",
		component: () => import("../views/wrongAuthenticatePage/courseCenter.vue"),
		meta: {},
	},
	// 新 - 登录页 - 课程中心
	{
		path: "/wrongAuthenticatePage/courseDetail",
		name: "wrongAuthenticatePage_courseDetail",
		component: () => import("../views/wrongAuthenticatePage/courseDetail.vue"),
		meta: {},
	},
	// 新 - 登录页 - 数字服务
	{
		path: "/wrongAuthenticatePage/digitalServices",
		name: "wrongAuthenticatePage_digitalServices",
		component: () => import("../views/wrongAuthenticatePage/digitalServices.vue"),
		meta: {},
	},
	// 新 - 登录页 - 商务合作
	{
		path: "/wrongAuthenticatePage/businessCooperation",
		name: "wrongAuthenticatePage_businessCooperation",
		component: () => import("../views/wrongAuthenticatePage/businessCooperation.vue"),
		meta: {},
	},
	// 新 - 登录页 - 关于
	{
		path: "/wrongAuthenticatePage/aboutUs",
		name: "wrongAuthenticatePage_aboutUs",
		component: () => import("../views/wrongAuthenticatePage/aboutUs/index.vue"),
		meta: {},
	},
	// 新 - 登录页 - 关于晟融
	{
		path: "/wrongAuthenticatePage/aboutUsNew",
		name: "wrongAuthenticatePage_aboutUsNew",
		component: () => import("../views/wrongAuthenticatePage/aboutUsNew.vue"),
		meta: {},
	},
	// 新 - 登录页 - 学员登录 - 忘记密码
	{
		path: "/wrongAuthenticatePage/forgetPassChange",
		name: "wrongAuthenticatePage_forgetPassChange",
		component: () => import("../views/wrongAuthenticatePage/forgetPassChange.vue"),
		meta: {},
	},

	// 新 - 登录页 - 制作基地
	{
		path: "/wrongAuthenticatePage/productionBase",
		name: "wrongAuthenticatePage_productionBase",
		component: () => import("../views/wrongAuthenticatePage/productionBase/index.vue"),
		meta: {},
	},
	// 新 - 登录页 - 三级等保
	{
		path: "/wrongAuthenticatePage/threeLevel",
		name: "wrongAuthenticatePage_threeLevel",
		component: () => import("../views/wrongAuthenticatePage/threelevel/threeLevel.vue"),
	},
	// 新 - 登录页 - 整合营销
	{
		path: "/wrongAuthenticatePage/integratedMarketing",
		name: "wrongAuthenticatePage_integratedMarketing",
		component: () => import("../views/wrongAuthenticatePage/integratedMarketing.vue"),
		meta: {},
	},
	// 新 - 登录页 - 慕课合作
	{
		path: "/wrongAuthenticatePage/mukeCooperation",
		name: "wrongAuthenticatePage_mukeCooperation",
		component: () => import("../views/wrongAuthenticatePage/mukeCooperation.vue"),
		meta: {},
	},
	// 新 - 登录页 - 证书服务
	{
		path: "/wrongAuthenticatePage/certificateServices",
		name: "wrongAuthenticatePage_certificateServices",
		component: () => import("../views/wrongAuthenticatePage/certificateServices.vue"),
		meta: {}
	},
	// 新 - 登录页 - 加入我们
	// {
	// 	path: "/wrongAuthenticatePage/joinUs",
	// 	name: "wrongAuthenticatePage_joinUs",
	// 	component: () => import("../views/wrongAuthenticatePage/joinUs/joinUs.vue"),
	// 	meta: {},
	// },
	// 新 - 登录页 - 人力资源
	{
		path: "/wrongAuthenticatePage/humanResources",
		name: "wrongAuthenticatePage_humanResources",
		component: () => import("../views/wrongAuthenticatePage/humanResources/humanResources.vue"),
		meta: {},
	},
	// 登录页 - 工伤预防
	{
		path: "/wrongAuthenticatePage/inductrialInjuryPrevention",
		name: "wrongAuthenticatePage_inductrialInjuryPrevention",
		component: () => import("../views/wrongAuthenticatePage/inductrialInjuryPrevention.vue"),
		meta: {},
	},
	// 扫学时证明的二维码 - 显示H5页面数据
	{
		path: "/web/workOrder/ProofOfClassHours",
		name: "ProofOfClassHours",
		component: () => import("../views/workOrder/ProofOfClassHours.vue"),
		meta: {
			state: true,
			level: 1
		}
	},
	// other
	// {
	// 	path: "/web/*",
	// 	redirect: {
	// 		name: "login"
	// 	},
	// },
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
});

// 防止在当前页面跳转时报：vue-router报错Uncaught (in promise)；在升级了Vue-Router版本到到3.1.0及以上之后，页面在跳转路由控制台会报Uncaught (in promise)的问题，
// 在3.1.0版本里面新增功能：push和replace方法会返回一个promise, 你可能在控制台看到未捕获的异常。
// 对Router原型链上的push、replace方法进行重写，这样就不用每次调用方法都要加上catch
// 在router.js加入以下内容：
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

// 无需token的页面
let routerWhiteList = [
	"zhengzhouH5/ExaminationDetails",
	"zhengzhouH5/logs",
	"zhengzhouH5/logs2",
	"zhengzhouH5/userFaceLogs",
	"zhengzhouH5/detailResource",
	"zhengzhouH5/videoMenu",
	"operate/ForgetPassword",
	"wrongAuthenticatePage_carousel",
	"wrongAuthenticatePage_bottomPage",
	"wrongAuthenticatePage_aboutUs",
	"wrongAuthenticatePage_aboutUsNew",
	"wrongAuthenticatePage_headPage",
	"wrongAuthenticatePage_certificateServices",
	"wrongAuthenticatePage_productionBase",
	"wrongAuthenticatePage_businessCooperation",
	"wrongAuthenticatePage_aboutShengrongAn",
	"wrongAuthenticatePage_courseCenter",
	"wrongAuthenticatePage_digitalServices",
	"wrongAuthenticatePage_threeLevel",
	"wrongAuthenticatePage_integratedMarketing",
	"wrongAuthenticatePage_mukeCooperation",
	"wrongAuthenticatePage_joinUs",
	"OnePersonOneCodeTable",
	"wrongAuthenticatePage_courseDetail",
	"wrongAuthenticatePage_humanResources",
	"H5_Exhibition_classList",
	"H5_Exhibition_classListVideo",
	"azhr_humanResources_register",
	"azhr_humanResources_enterpriseInfo",
	"web_login_registeredAccount",
	"wrongAuthenticatePage_forgetPassChange",
	'examination_onlineTest_aroundCenterInfo',
	"wrongAuthenticatePage_inductrialInjuryPrevention",
	"ProofOfClassHours",
	"h5/learningRecord",
];
const result = judgeServerHostname();
if (result === 1) {
	document.querySelector("link[rel*='icon']").href = "./logoMongolia.ico";
} else {
	document.querySelector("link[rel*='icon']").href = "./favicon.ico";
}

router.beforeEach((to, from, next) => {
	const token = sessionStorage.getItem("token") || "";
	if (to.name === "login") {
		delAllStorage();
		next();
	} else if (!token) {
		if (routerWhiteList.includes(to.name)) {
			next();
		} else {
			next({
				path: '/web/login'
			});
		}
	} else if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
		if (to.name != "operate/change") {
			next({
				path: '/web/login'
			});
			location.reload()
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;