import Vue from "vue";
import axios from "axios";
import store from "../store/index";
// import qs from "qs";
import {
  Message,
  Loading,
  MessageBox
} from 'element-ui';
import router from '../router';
import AES from '../utils/aes';

// 响应时间
// axios.defaults.timeout = 15 * 1000;
// 配置请求头
axios.defaults.headers.post["Content-Type"] = "application/json";
// 静态资源
Vue.prototype.$static = "";

// Vue.prototype.$baseUrl = baseURL;
Vue.prototype.$imgUrl = "http://kfqapp.qetdz.com/img?id=";
Vue.prototype.$picUrl = "http://kfqcdn.qetdz.com/pic";

// 配置接口地址
// axios.defaults.baseURL = baseURL;

// 配置 - 请求大于15秒的接口
let interfaceTimeoutWhiteList = [
  '/biz/new/bill/generateStatement',
  '/run/project/projectCertList',
  '/biz/ding/order/exportCoursePageList',
  '/documentcenter/getLearnStatistic',
  '/biz/new/bill/listExport',
  '/biz/new/bill/pay/exportPaymentStatement',
  '/run/project/user/statistics',
  '/run/project/authentication/user/statistics',
  '/run/project/user/complete/statistics',
  '/run/project/user/graduation/statistics',
  '/sys/upload',
  '/sys/upload/watermark',
  "/sys/file/upload",
  '/sys/file/async/upload',
  '/sys/upload/uploadAttribute',
]

// 配置 - 无需token的接口
let thereIsNoToken = [
  '/eighteenEnterprise/userCode/getH5StudyInfo',
  '/biz/heNan/queryH5/getH5CourseInfo',
  '/biz/heNan/queryH5/queryH5QuestionList',
  '/biz/heNan/queryH5/getH5PaperInfo',
  '/biz/heNan/queryH5/H5VideoPlayAuth',
  '/biz/heNan/queryH5/queryH5PaperUserQuestion',
  '/biz/heNan/queryH5/queryLearningTrajectory',
  '/sys/admin/comp/register',
  '/biz/heNan/queryH5/queryUserInfo',
  '/biz/heNan/queryH5/queryImageList',
  '/biz/heNan/queryH5/queryTrackList',
  '/biz/heNan/queryH5/queryUserFaceAuthentication',
  '/run/course/displayCourse',
  '/sys/video/videoPlayAuthNoAuth',
  '/sys/company/isExist',
  '/sys/dictionary/key',
  '/sys/captcha/get',
  '/sys/sms/login/captcha',
  '/sys/admin/validation',
  '/sms/login',
  '/login',
  '/logout',
  '/sys/admin/forgetPassword',
  '/biz/homepage/recommend/courseCategory',
  '/biz/homepage/recommend/course',
  '/biz/homepage/recommend/banner',
  '/biz/homepage/recommend/course/all',
  '/sys/area/tree',
  '/biz/cooperate/apply/insert',
  '/sys/upload',
  '/sys/upload/cooperateApply',
  '/sys/upload/uploadAttribute',
  '/sys/face/ocrIdCard',
  '/homepage/courseCategory',
  '/homepage/course',
  '/homepage/course/all',
  '/homepage/detail',
  '/homepage/courseRelevantRecommend',
  '/run/rotation/queryRotationListNew',
  '/biz/join/teacher/insert',
  '/biz/join/company/insert',
  '/biz/join/teacher/insert',
  '/biz/cooperate/consulting/insert',
  '/biz/human/resources/insert',
  '/biz/v1/buried/point/collect',
  '/H5_Exhibition/classList',
  "/hr/open/pc/banner/list",
  "/hr/sms/login",
  "/hr/registry",
  "/sys/sms/register/captcha",
  "/sys/sms/captcha",
  "/auth/sms",
  "/auth/password",
  "/sys/user/open/get-recovery-token",
  "/sys/user/open/recover-password",
  "/biz/collect/gather",
  "/homepage/gsyf/courseCategory",
  '/homepage/gsyf/course/all',
  '/biz/projectCourseOutline/getProjectCourseInfo',
  '/project/user/retraces/info',
  '/project/user/retraces/study/info',
]

// 配置 - 加密格式 AES.encrypt(config.data)
let encryptionFormat_1 = [
  '/login',
  '/sys/admin/validation',
  '/sms/login',
  "/hr/sms/login"
]

// 配置 - 加密格式 json: AES.encrypt(config.data)
let encryptionFormat_2 = [
  "/sys/sms/captcha",
  "/auth/sms",
  "/auth/password",
  "/sys/user/open/get-recovery-token",
  "/sys/user/open/recover-password",
]

// 配置 - 无需处理上传参数的接口
let dontdealwithUrl = [
  '/sys/file/upload',
  '/sys/file/upload/url',
  '/sys/upload',
  '/sys/upload/watermark',
  '/sys/upload/image/compress',
  '/sys/file/async/upload',
  '/sys/upload/cooperateApply',
  '/sys/upload/uploadAttribute'
]

// Loading防止多次调用
let loadingCount = 0;

// Loading
let loadingInstance;

// 登录失效防止多次弹出
let loginOnce = true;

// 加载提示
function showLoadings() {
  loadingCount++
  loadingInstance = Loading.service({
    lock: true,
    text: "数据加载中，请稍后...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)"
  });
}

// POST传参序列化(添加请求拦截器)
function interceptorsRequest(fetchd, status = true, Requesttype) {
  fetchd.interceptors.request.use(
    config => {
      let prod = 'https://anzhi.bjsrxx.com'; // 生产环境
      let test = ''; // 测试环境
      let dev = ''; // 本地环境
      let urlA = '/server-spark'; // 安知项目服务
      let urlB = '/question-lib'; // 安知题库服务;
      let urlC = '/anzhi-live-server'; // 安知直播服务;
      let urlD = '/anzhi-pay'; // 支付;
      let urlE = '/anzhi-app-api'; // 数据对接;
      let urlF = '/anzhi-exam-server'; // 鉴定考试服务;
      let urlG = '/intern-server'; // 鉴定考试服务;
      let urlH = '/anzhi-wechat-server'; // wechat服务;
      let urlI = '/anzhi-digital-server';  // 安知数字人力服务
      let urlJ = '/practical-server';  // 特种作业实操考核系统

      if (Requesttype == 2) {
        prod += urlB;
        test = urlB;
        // dev = "http://192.168.100.219:8080/question-lib"; // 张义
        // dev = "http://192.168.100.82:8080/question-lib";  // 贾琦
        // dev = "http://192.168.100.92:8091/question-lib"; // 李晓鹏
        dev = "http://192.168.3.112:9000/question-lib"; // 测试服务
        // dev = "http://questionlibserver.sunreal.io/question-lib";   // 测试服务
      } else if (Requesttype == 3) {
        prod += urlC;
        test = urlC;
        // dev = "http://192.168.100.219:8080/anzhi-live-server"; // 张义
        // dev = "http://192.168.100.22:8080/anzhi-live-server";  // 张子航
        // dev = "http://192.168.100.82:8080/anzhi-live-server";  // 贾琦
        dev = "http://192.168.3.112:9000/anzhi-live-server"; // 测试服务
        // dev = "http://test.sunreal.io/anzhi-live-server" // 测试
        // dev = "http://questionlibserver.sunreal.io/anzhi-live-server" // 测试
      } else if (Requesttype == 4) {
        prod += urlD;
        test = urlD;
        // dev = "http://192.168.100.219:8080/server-spark"; // 张义
        // dev = "http://192.168.100.22:8080/server-spark";  // 张子航
        // dev = "http://192.168.100.82:8080/server-spark";  // 贾琦
        // dev = "http://192.168.100.92:8081/anzhi-pay"; // 鹏哥
        dev = "http://192.168.3.112:9000/anzhi-pay";   // 测试服务
        // dev = "http://test.sunreal.io/server-spark" // 测试
        // dev = "http://questionlibserver.sunreal.io/server-spark" // 测试
      } else if (Requesttype == 5) {
        prod += urlE
        test = urlE
        dev = "http://192.168.3.112:9000/anzhi-app-api";   // 测试服务
      } else if (Requesttype == 6) {
        prod += urlF
        test = urlF
        dev = "http://192.168.3.112:9000/anzhi-exam-server";   // 测试服务
        // dev = "http://192.168.100.22:8084/anzhi-exam-server";   // 张子航
        // dev = "http://192.168.100.219:8080/anzhi-exam-server";   // 张义
        // dev = "http://192.168.100.92:8092/anzhi-exam-server"; // 鹏哥
        // dev = "http://192.168.100.82:8080/anzhi-exam-server";   //  贾琦
      } else if (Requesttype == 7) {
        prod += urlG
        test = urlG
        dev = "http://192.168.3.112:9000/intern-server";   // 测试服务
        // dev = "http://192.168.100.22:8080/intern-server";   // 张子航
      } else if (Requesttype == 8) {
        prod += urlH
        test = urlH
        dev = "http://192.168.3.112:9000/anzhi-wechat-server";   // 测试服务
        // dev = "https://anzhi.bjsrxx.com/anzhi-wechat-server"; // 正式服务
        // dev = "http://192.168.100.92:8092/anzhi-wechat-server"; // 鹏哥
      } else if (Requesttype == 9) {
        prod += urlI;
        test = urlI;
        dev = "http://192.168.3.112:9000/anzhi-digital-server";   // 测试服务
        // dev = "http://192.168.100.219:8080/anzhi-digital-server";   // 张义
      } else if (Requesttype == 10) {
        prod += urlJ;
        test = urlJ;
        dev = "http://192.168.3.112:9000/practical-server";   // 测试服务
        // dev = "http://192.168.100.92:8099/practical-server";   // 鹏
        // dev = "http://192.168.100.22:8081/practical-server";   // 航
        // dev = "http://192.168.100.219:8080/practical-server";   // 义
        // dev = "http://192.168.100.238:8081/practical-server";   // 正
      } else {
        prod += urlA;
        test = urlA;
        // dev = "http://192.168.100.219:8080/server-spark"; // 张义
        // dev = "http://192.168.100.22:8082/server-spark";  // 张子航
        //  dev = "http://192.168.100.82:8080/server-spark";  // 贾琦
        // dev = "http://192.168.100.92:8090/server-spark"; // 鹏哥
        dev = "http://192.168.3.112:9000/server-spark";   // 测试服务
      }
      const baseURL = process.env.VUE_APP_URL === "development" ?
        test :
        process.env.NODE_ENV === "development" ?
          dev :
          prod;
      config.baseURL = baseURL;
      // 是否校验token
      if (!sessionStorage.getItem("token") && !thereIsNoToken.includes(config.url)) {
        MessageBox.alert('您的身份信息已失效，请重新登录获取身份信息', '提示', {
          confirmButtonText: '重新登录',
        }).then(() => {
          loginOnce = true;
          router.replace({
            name: 'login'
          });
        }).catch(() => {
          loginOnce = true;
        });
      } else {
        if (status) showLoadings();
        // 无需处理上传参数
        if (dontdealwithUrl.includes(config.url)) {
          return config;
        }
        // post请求转JSON
        if (config.method == "post") {
          config.data = JSON.stringify(config.data);
        }
        // 加密格式 AES.encrypt(config.data)
        if (encryptionFormat_1.includes(config.url)) {
          config.data = AES.encrypt(config.data)
        }
        // 加密格式 json: AES.encrypt(config.data)
        if (encryptionFormat_2.includes(config.url)) {
          config.data = { json: AES.encrypt(config.data) }
        }
        // 鉴定考试服务(urlF=/anzhi-exam-server) - 统一处理加密方式
        if (!(config.baseURL.indexOf('192.168.100') != -1)) {
          requestEncryption(config, [urlF, urlG, urlI, urlJ])
        }
        return config;
      }
    },
    err => {
      loadingInstance.close();
      if (status) {
        Message.error("请求失败，请稍后再试");
        return Promise.reject(err);
      }
    }
  );
}

// 返回状态判断(添加响应拦截器)
function interceptorsResponse(fetchd, status = true, timeout = 3000, fetchParams = {}) {
  fetchd.interceptors.response.use(
    res => {
      // 防止多次弹出提示
      if (status&&loadingInstance) {
        if (loadingCount > 0) {
          loadingCount--
        }
        if (loadingCount == 0) {
          loadingInstance.close();
        }
      }
      loadingInstance.close();
      if (res.status == 200) {
        const retData = res.data || {};
        if (res.config.baseURL && !(res.config.baseURL.indexOf('192.168.100') != -1)) {
          responseEncryption(res, retData, ['/anzhi-exam-server', '/intern-server', '/anzhi-digital-server','/practical-server'])
        }
        if (retData.status == "0" || retData.status == '1') {
          return retData;
        } else if (retData.status == '99' && loginOnce) {
          loginOnce = false;
          MessageBox.alert(retData.msg || '您的身份信息已失效，请重新登录获取身份信息', '提示', {
            confirmButtonText: '重新登录',
          }).then(() => {
            loginOnce = true;
            router.replace({
              name: 'login'
            });
            return Promise.reject(res);
          }).catch(() => {
            loginOnce = true;
          });
          // 重新登录
        } else {
          if (loginOnce && retData.message) {
            Message.error({
              message: retData.message,
              duration: timeout,
              dangerouslyUseHTMLString: true,
            });
            return Promise.reject(res);
          }
        }
        loadingInstance.close();
      } else {
        loadingInstance.close();
        Message.error("请求访问失败，code：" + res.status);
        return Promise.reject(res);
      }
    },
    err => {
      if (loadingInstance) {
        if (loadingCount > 0) {
          loadingCount--
        }
        if (loadingCount == 0) {
          loadingInstance.close();
        }
      }
      Message.error("服务访问失败，请稍后再试");
      return Promise.reject(err);
    }
  );
}

// GET
export function fetchGet(url, params, status = true) {
  return fetchInit(url, params, status, 3000, {
    type: "get"
  });
}
// POST
export function fetchPost(url, params, timeout = 3000, status = true, Requesttype = 1) {
  return fetchInit(url, params, status, timeout, {
    type: "post",
  }, Requesttype);
}
// PUT
export function fetchPut(url, params, status = true) {
  return fetchInit(url, params, status, 3000, {
    type: "put"
  });
}
// DELETE
export function fetchDelete(url, params, status = true) {
  return fetchInit(url, params, status, 3000, {
    type: "delete"
  });
}
// POST - FormData
export function fetchPostformat(url, params, status = true, timeout = 3000, Requesttype = 1,options={}) {
  showLoadings();
  return fetchInit(url, params, status, timeout, { type: "post",onUploadProgress:options.onUploadProgress||null }, Requesttype);
}

const fetchInit = (url, params, status = true, timeout = 3000, fetchPs = {}, Requesttype = 1) => {
  let onUploadProgress = fetchPs.onUploadProgress?fetchPs.onUploadProgress:null
  let timeouts;
  if (interfaceTimeoutWhiteList.includes(url)) {
    timeouts = 0
  } else {
    timeouts = 1000 * 15
  }
  const fetchd = axios.create({
    timeout: timeouts,
    headers: {
      common: {
        Authorization: store.state.token || ""
      }
    },
    onUploadProgress,
  });
  const request = fetchPs.request || true;
  if (request === true) {
    interceptorsRequest(fetchd, status, Requesttype);
  }
  interceptorsResponse(fetchd, status, timeout);
  return fetchCreate(fetchd, url, params, fetchPs.type)
};

const fetchCreate = (fetchd, url, params, type = "post") => {
  return new Promise((resolve, reject) => {
    if (type === "get" || type === "delete") {
      params = {
        params
      };
    }
    fetchd[type](url, params)
      .then(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      )
      .catch(err => {
        // console.log(err);
        reject(err.data);
      });
  });
};

/******* 
 * @description: 请求拦截器加密
 * @param {object} config 请求配置对象
 * @param {Array} urlArr 需要加密的服务
 * @return {*} 
 */
const requestEncryption = (config, urlArr) => {
  if (!urlArr || urlArr.length == 0) return
  urlArr.forEach(e => {
    if (config.baseURL.indexOf(e) != '-1') {
      config.data = { 'json': AES.encrypt(config.data) }
    }
  })
}

/******* 
 * @description: 相应拦截器解密
 * @param {object} res 请求配置对象
 * @param {object} retData
 * @param {Array} urlArr 需要加密的服务
 * @return {*}
 */
const responseEncryption = (res, retData, urlArr) => {
  if (!urlArr || urlArr.length == 0) return
  urlArr.forEach(e => {
    if (res.config.baseURL.indexOf(e) != '-1') {
      if (res.data.data && res.data.data.length) {
        retData.data = AES.decrypt(retData.data) || "";
      } else {
        return retData.data
      }
    }
  })
}


// 埋点采集接口
export function gatherInfo(data = {}) {
  return new Promise((resolve, reject) => {
    // contentId 内容的系统id
    // contentName 内容名称(或概述)
    // moduleCode 模块标识
    // positionCode 位置标识
    // terminalCode 终端标识
    // topNum 顺序编号, 从左到右 | 从上到下
    data.terminalCode = 'PC_HR'

    fetchPost("/biz/collect/gather", data, 3000, false, 6);
    /* eg：
    this.$gatherInfo({
      contentId: '',// 内容的系统id
      contentName: '',// 内容名称
      moduleCode: 'HOME',
      // 模块标识

        // HOME - 首页,
        // COURSE - 课程推广页,
        // DIGITAL_SERVICE - 数字服务,
        // BUSINESS_COOPERATION - 商务合作


      positionCode: '',
       // 位置标识
        // 【PC_HR - HOME】
          // NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
          // INTELLIGENT_TOOLS - 智能化工具
          // HR_PATTERN  - 人力资源新模式]

        // 【PC_HR - COURSE】
          // CATEGORY_LEVEL1 - 一级分类
          // CATEGORY_LEVEL2 - 二级分类
          // COURSE - 课程

      topNum: ''// 顺序编号
    })
    */
  })
}

Vue.prototype.$get = fetchGet;
Vue.prototype.$post = fetchPost;
Vue.prototype.$put = fetchPut;
Vue.prototype.$delete = fetchDelete;
Vue.prototype.$Postformat = fetchPostformat;
Vue.prototype.$gatherInfo = gatherInfo;